<template>
    <DictDetailsViewTemplate :acceptGeobject="acceptField" :getGeobjectData="getFieldById"
                             :createGeobjectFunction="createField" :updateGeobjectFunction="updateField"
                             name="field" biernik="powierzchnię" dopelniacz="powierzchni"/>
</template>

<script>
    import {
        acceptField,
        createField,
        getFieldById,
        updateField,
    } from "@/swagger/vue-api-client";
    import DictDetailsViewTemplate from "@/views/dict/DictDetailsViewTemplate.vue";

    export default {
        name: "PointDetailsView",

        components: {
            DictDetailsViewTemplate,
        },

        methods: {
            acceptField,
            updateField,
            createField,
            getFieldById,
        },
    };
</script>
