<template>
    <div class="mammals-table">
        <DataTable :value="mammals" v-model:editingRows="editingRows" editMode="row" dataKey="id"
                   @row-edit-save="onRowEditSave" class="navigate-table" @click="arrowsNavigateTable">
            <template #header>
                <AddMammals v-model="mammals" :index="index" class="ml-6" :error="error"/>
            </template>
            <Column header="Lp." field="ordinal" class="w-3rem"/>
            <Column header="Kod" field="mammal" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.mammal.code }}
                </template>
                <template #editor="{data,field}">
                    <CustomAutocompleteMammalSpecies label="Kod" v-model="data[field]" searchParam="code"
                                                     name="mammalCode" :emptyValue="false"/>
                </template>
            </Column>
            <Column header="Gatunek" field="mammal" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.mammal.name }}
                </template>
                <template #editor="{data,field}">
                    <CustomAutocompleteMammalSpecies label="Gatunek" v-model="data[field]" searchParam="name"
                                                     name="mammalName" :emptyValue="false"/>
                </template>
            </Column>
            <Column header="Kategoria odległości" field="distance" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.distance.label }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" itemLabel="code" :items="dicts.distance"
                                     name="distance" />
                </template>
            </Column>
            <Column header="L. osobników" field="count" class="w-2">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="count" :min="1" />
                </template>
            </Column>
            <Column class="w-2"/>
            <Column v-if="editing" :rowEditor="true" style="width: 120px; text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 60px; text-align: center;" class="w-1">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteMammalObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddMammals from "@/components/formMammals/AddMammals.vue";
    import CustomAutocompleteMammalSpecies
        from "@/components/form/CustomAutocompleteMammalSpecies.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "MammalsTable",

        components: {
            CustomAutocompleteMammalSpecies,
            AddMammals,
            CustomButton,
            CustomInputNumber,
            CustomSelectOne,
            DataTable,
            Column,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            index: null,
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        emits: ["update:modelValue"],

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.mammals[index] = newData;
            },
            deleteMammalObservation(item) {
                const index = this.mammals.indexOf(item);
                this.mammals.splice(index, 1);
            },
        },

        computed: {
            mammals: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            mammals: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
