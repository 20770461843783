<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row" dataKey="ordinal"
               @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
               class="navigate-table" @click="arrowsNavigateTable">
        <template #header>
            <AddBirdsMZPW v-model="internalBirds" :error="showError()" />
        </template>
        <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
        <Column header="Kod" class="w-1" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.code }}
            </template>
            <template #editor="{data,field}">
                <CustomAutocompleteBirdSpecies label="Kod" name="species" v-model="data[field]"
                                               searchParam="code" @update:modelValue="completeSpecies(data)" />
            </template>
        </Column>
        <Column header="Gatunek" class="w-1" field="species2">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomAutocompleteBirdSpecies label="Gatunek" name="species" v-model="data[field]"
                                               searchParam="name" @update:modelValue="completeSpecies2(data)" />
            </template>
        </Column>
        <Column header="L. osobników" class="w-1" field="count">
            <template #editor="{data,field}">
                <CustomInputNumber label="L. osobników" name="count" v-model="data[field]"
                                   :min="1" />
            </template>
        </Column>
        <Column header="W locie" class="w-1" field="inFlight">
            <template #body="slotProps">
                <div :class="slotProps.data.inFlight ? 'true-style' : 'false-style'">
                    {{ computedBoolean(slotProps.data.inFlight) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomCheckbox label="W locie" name="inFlight" v-model="data[field]" />
            </template>
        </Column>
        <Column header="Płeć" class="w-2" field="sex">
            <template #body="slotProps">
                {{ slotProps.data.sex?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Płeć" name="sex" v-model="data[field]"
                                 :items="dicts.sex" :disabled="!data.species.sex"/>
            </template>
        </Column>
        <Column header="Wiek" class="w-2" field="age">
            <template #body="slotProps">
                {{ slotProps.data.age?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Wiek" name="age" v-model="data[field]"
                                 :items="dicts.age" :disabled="!data.species.age"/>
            </template>
        </Column>
        <Column class="w-2"/>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import AddBirdsMZPW from "@/views/formMZPW/components/AddBirdsMZPW.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMZPW",

        components: {
            AddBirdsMZPW,
            DataTable,
            Column,
            CustomAutocompleteBirdSpecies,
            CustomSelectOne,
            CustomInputNumber,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        emits: ["update:noBirds", "update:birds"],

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.birds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            completeSpecies(ctx) {
                if (ctx && ctx.species?.code) ctx.species2 = ctx.species;
            },
            completeSpecies2(ctx) {
                if (ctx && ctx.species2?.code) ctx.species = ctx.species2;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
