<template>
    <CustomAutocomplete :name="name" :completeFunction="getUsers" :empty-value="emptyValue"
                        v-model="internalValue" :label="label" :dropdown="dropdown" class="mb-0"/>
</template>

<script>
    import CustomAutocomplete from "./inner/CustomAutocomplete";
    import {getUsers} from "@/swagger/vue-api-client";

    export default {
        name: "CustomAutocompleteUser",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            dropdown: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
        },

        emits: ["update:modelValue", "onSelectUserFromList"],

        methods: {getUsers},

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    if (value != null && value.value != null) {
                        this.$emit("onSelectUserFromList", value);
                    } else {
                        this.$emit("update:modelValue", value);
                    }
                },
            },
        },

        data() {
            return {
                items: [],
                selectedValue: null,
            };
        },

        components: {CustomAutocomplete},
    };
</script>

<style scoped>

</style>
