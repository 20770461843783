<template>
    <DictListViewTemplate name="field" liczbaMnoga="Powierzchnie" liczbaPojedyncza="Powierzchnia" biernik="powierzchnię"
                          :searchGeobjects="searchFields" :searchGeobjectsCount="searchFieldsCount"
                          :acceptGeobject="acceptField"/>
</template>

<script>
    import DictListViewTemplate from "@/views/dict/DictListViewTemplate.vue";
    import {
        acceptField,
        searchFields,
        searchFieldsCount,
    } from "@/swagger/vue-api-client";

    export default {

        name: "TransectDictView",

        components: {
            DictListViewTemplate,
        },

        methods: {
            acceptField,
            searchFieldsCount,
            searchFields,
        },
    };
</script>

<style lang="scss"></style>
