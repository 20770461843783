<template>
    <div class="p-3">
        <MammalsDictSearchCriteria v-model="searchCriteria" @update:modelValue="search" />
        <MammalsDictTable ref="mammalsDictTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import MammalsDictTable from "./MammalsDictTable.vue";
    import MammalsDictSearchCriteria from "./MammalsDictSearchCriteria.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "MammalsDictView",

        components: {
            MammalsDictTable,
            MammalsDictSearchCriteria,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    code: "",
                    namePl: "",
                    nameEn: "",
                    nameLat: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            search() {
                this.$refs.mammalsDictTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
