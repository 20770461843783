<template>
    <DataTable :value="regionalCoordinators" :totalRecords="totalRecords"
               dataKey="id" paginator v-model:rows="page.limit" v-model:first="page.offset"
               @page="onPage" @sort="onPage" removableSort
               v-model:editingRows="editingRows" editMode="row" @row-edit-save="onRowEditSave"
               :rowsPerPageOptions="[10,25,50,100,10000]" size="small">
        <template v-if="editCast" #header>
            <AddRegionalCoordinator @newRegionalCoordinator="addRegCoordinator" :availableFields="availableFields"/>
        </template>
        <Column header="Imię i nazwisko" style="width: 30%;"
                filterField="observerName" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #body="slotProps">
                {{ slotProps.data.coordinator
                    ? slotProps.data.coordinator.name
                    : slotProps.data.regionalCoordinator.name }}
            </template>
            <template #filter="{filterModel}">
                <CustomInputText v-model="filterModel.value" label="Koordynator regionalny"
                                 class="flex-1 w-0" name="programObserverName" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
        </Column>
        <Column field="fields" header="Powierzchnie">
            <template #body="slotProps">
                <div>
                    {{ showMore
                        ? join(slotProps.data.fields)
                        : textTruncate(join(slotProps.data.fields)) }}
                </div>
                <span v-if="(join(slotProps.data.fields)).length > 30" @click="showMore = !showMore"
                      class="cursor-pointer uppercase">
                    {{ showMore ? "Pokaż mniej" : "Pokaż więcej" }}
                </span>
            </template>
            <template #editor="{data,field,index}">
                <CustomMultiSelect :items="computedFieldsList(index)" itemLabel="name" virtual-scrolling
                                   name="fields" v-model="data[field]" />
            </template>
        </Column>
        <Column v-if="editCast" :rowEditor="true" style="width: 120px; text-align: center;" />
        <Column v-if="editCast" style="width: 60px; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteRegCoordinator(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        editRegionalCoordinatorCast,
        deleteProgramRegionalCoordinatorFromCast,
        listFields,
    } from "@/swagger/vue-api-client";
    import CustomMultiSelect from "../../../../components/form/inner/CustomMultiSelect.vue";
    import CustomButton from "../../../../components/form/CustomButton.vue";
    import ListUtils from "@/utils/ListUtils";
    import AddRegionalCoordinator from "./AddRegionalCoordinator";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "RegionalCoordinatorsTable",

        components: {
            CustomInputText,
            FilterClear,
            FilterApply,
            DataTable,
            Column,
            CustomMultiSelect,
            CustomButton,
            AddRegionalCoordinator,
        },

        props: {
            editCast: {
                type: Boolean,
                default: false,
            },
            modelValue: {
                type: Array,
            },
        },

        data() {
            return {
                editingRows: ref([]),
                totalRecords: 0,
                searchCriteria: {
                    coordinator: {name: ""},
                    observerName: "",
                    fieldName: "",
                },
                page: SearchCriteria.getClearPage(),
                newRegionalCoordinator: null,
                tableFieldList: [],
                availableFields: [],
                tableItems: [],
                fields: [],
                loading: false,
                showMore: false,
            };
        },

        emits: ["update:modelValue", "reloadProgramState", "update:regionalCoordinators"],

        beforeMount() {
            listFields({programEditionId: this.$route.params.id})
                .then((response) => {
                    this.fields = response.data;
                    this.updateAvailableFields();
                    this.loadFields = true;
                })
                .catch((error) => { console.log(error); });
            this.totalRecords = this.regionalCoordinators.length;
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[regionalCoordinators]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[regionalCoordinators]`);
            }
            this.search();
        },

        methods: {
            updateAvailableFields() {
                const usedFieldIds = new Set();
                this.regionalCoordinators.forEach((rc) => {
                    rc.fields.forEach((field) => { usedFieldIds.add(field.id); });
                });
                this.availableFields = [];
                this.fields.forEach((field) => {
                    if (!usedFieldIds.has(field.id)) this.availableFields.push(field);
                });
            },
            addRegCoordinator(newRegCoordinator) {
                this.regionalCoordinators.push(newRegCoordinator);
                this.totalRecords += 1;
                this.updateAvailableFields();
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                const fieldIDs = newData.fields.map((field) => field.id);

                const regionalCoordinatorRequest = {
                    userId: newData.coordinator ? newData.coordinator.id : newData.regionalCoordinator.id,
                    fieldIDs,
                };
                editRegionalCoordinatorCast({
                    programEditionId: this.$route.params.id,
                    body: regionalCoordinatorRequest,
                })
                    .then(() => {
                        this.regionalCoordinators[index] = newData;
                        this.updateAvailableFields();
                        this.$emit("reloadProgramState", true);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 422) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Powierzchnia jest już przypisana do innego koordynatora regionalnego",
                                life: 3000,
                            });
                        }
                    });
            },
            deleteRegCoordinator(regCoor) {
                deleteProgramRegionalCoordinatorFromCast({
                    programEditionId: this.$route.params.id,
                    regionalCoordinatorId: regCoor.id,
                })
                    .then(() => {
                        const index = this.regionalCoordinators.indexOf(regCoor);
                        this.regionalCoordinators.splice(index, 1);
                        this.updateAvailableFields();
                        this.$emit("reloadProgramState", true);
                    });
            },
            join(fields) {
                return ListUtils.join(fields, "name");
            },
            textTruncate(text) {
                if (text.length > 30) {
                    return _.truncate(text, {
                        length: 30,
                        separator: ",",
                    });
                }
                return text;
            },
            computedFieldsList(index) {
                return [...this.availableFields, ...this.regionalCoordinators[index].fields];
            },
            search() {
                // this.tableItems = this.regionalCoordinators.filter((rc) => {
                //     return this.searchCriteria.observerName.value == null
                //         || rc.coordinator.name.includes(this.searchCriteria.observerName.value);
                // });
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
        },

        computed: {
            regionalCoordinators: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[regionalCoordinators]`, value);
                },
                deep: true,
            },
            regionalCoordinators: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            editCast(value) {
                if (!value) {
                    this.editingRows = ref([]);
                }
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
</style>
