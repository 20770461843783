<template>
    <DictDetailsViewTemplate :acceptGeobject="acceptTransect" :acceptGeobjectHistory="acceptTransectHistory"
                             :getGeobjectData="getById" :createGeobjectFunction="createTransect"
                             :createGeobjectHistoryFunction="createTransectHistory"
                             :updateGeobjectHistoryFunction="updateTransectHistory"
                             :updateGeobjectFunction="updateTransect" :deleteGeobjectHistoryFunction="deleteTransectHistoryById"
                             :getAvailableControlVersionsFunction="getAvailableControlVersions"
                             name="transect" biernik="transekt" dopelniacz="transektu"/>
</template>

<script>
    import {
        acceptTransect,
        acceptTransectHistory,
        createTransect,
        createTransectHistory,
        deleteTransectHistoryById,
        getAvailableControlVersions,
        getById,
        listFieldsForProgram,
        updateTransect,
        updateTransectHistory,
    } from "@/swagger/vue-api-client";
    import DictDetailsViewTemplate from "@/views/dict/DictDetailsViewTemplate.vue";

    export default {
        name: "TransectDetailsView",

        components: {
            DictDetailsViewTemplate,
        },

        data() {
            return {
                transect: {
                    histories: [],
                },
                totalRecords: 0,
                hObj: null,
                loaded: false,
                isEditTransectActive: false,
                tmpTransect: {},
                fields: [],
            };
        },

        methods: {
            getAvailableControlVersions,
            deleteTransectHistoryById,
            updateTransect,
            updateTransectHistory,
            createTransectHistory,
            createTransect,
            getById,
            acceptTransectHistory,
            acceptTransect,

            loadFields(programCode) {
                listFieldsForProgram({ program: programCode })
                    .then((response) => {
                        this.fields = [...response.data];
                    })
                    .catch((error) => {
                        console.error(`Error loading fields for program ${programCode}:`, error);
                    });
            },
        },
    };
</script>

<style scoped lang="scss">

</style>
