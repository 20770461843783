/* eslint-disable */
export const SearchCriteria = {

    ARRAY_PREFIX: "array_",
    DATE_PREFIX: "date_",

    updateUrl(context) {
        const urlParam = {};
        Object.entries(context.searchCriteria).forEach(([key, value]) => {
            if (key !== "page") {
                if (value && Array.isArray(value)) {
                    urlParam[this.ARRAY_PREFIX + key] = "" + value;
                } else if (value && value.toISOString) {
                    urlParam[this.DATE_PREFIX + key] = value.toISOString();
                } else if (value || value === false) {
                    urlParam[key] = "" + value;
                }
            } else {
                Object.entries(value).forEach(([pageKey, pageValue]) => {
                    if (pageValue) {
                        urlParam["page" + pageKey] = "" + pageValue;
                    }
                });
            }
        });

        if (JSON.stringify(urlParam) !== JSON.stringify(context.$route.query)) {
            context.$router.push({name: context.$route.name, query: urlParam});
        }
    },

    loadCriteria(context) {
        Object.entries(context.$route.query).forEach(([key, value]) => {
            if (value) {
                if (key.startsWith("page")) {
                    context.searchCriteria.page[key.substr(4)] = isNaN(value) ? value : parseInt(value, 10);
                } else if (key.startsWith(this.ARRAY_PREFIX)) {
                    context.searchCriteria[key.replace(this.ARRAY_PREFIX, '')] = value.split(",");
                } else if (key.startsWith(this.DATE_PREFIX)) {
                    context.searchCriteria[key.replace(this.DATE_PREFIX, '')] = new Date(value);
                } else {
                    if (value === "false") {
                        context.searchCriteria[key] = false;
                    } else if (value === "true") {
                        context.searchCriteria[key] = true;
                    } else {
                        context.searchCriteria[key] = isNaN(value) ? value : parseInt(value, 10);
                    }
                }
            }
        });
    },

    prepareCriteria(searchCriteria, page, filterDescription) {
        const criteria = {};
        filterDescription.forEach((description) => {
            if (description.type.includes("range")) {
                criteria[description.name] = {};
                criteria[description.name].min =
                    searchCriteria[description.name].value
                        ? searchCriteria[description.name].value[0] : null;
                criteria[description.name].max =
                    searchCriteria[description.name].value
                        ? searchCriteria[description.name].value[1] : null;
            } else {
                criteria[description.name] = searchCriteria[description.name].value;
            }
        })
        criteria.page = page;
        return criteria;
    },

    getClearSearchCriteriaObserver(programEditionId) {
        return {
            programEditionId: programEditionId,
            observerName: "",
            fieldName: "",
            page: {
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
            },
        };
    },

    getClearSearchCriteriaControls(programEditionId) {
        return {
            programEditionId: programEditionId,
            observerName: "",
            fieldName: "",
            positions: "",
            controlName: "",
            controlState: "",
            textAnywhere: "",
            page: {
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
            },
        };
    },

    getClearPage() {
        return {
            limit: 10,
            offset: 0,
            sortField: null,
            sortOrder: null,
        };
    },

    getClearSearchCriteriaSemiRawData(programName, programEditionYear) {
        return {
            programName: programName,
            fieldName: null,
            positionName: null,
            year: programEditionYear,
            controlName: null,
            controlDate: null,
            observerName: null,
            speciesName: null,
            quantityUnit: null,
            nestingCriteria: null,
            hatchlingsNumber: null,
            habitat1: null,
            habitat2: null,
            page: {
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
            },
        };
    },

    getClearSearchCriteriaDataAggregates() {
        return {
            observer: [],
            year: null,
            fieldIds: [],
            speciesIds: [],
            programs: [],
            page: {
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
            },
        };
    },

    getClearSearchCriteriaEventLogs() {
        return {
            fieldIds: {
                value: [],
            },
            programs: {
                value: [],
            },
            activeUserIds: {
                value: [],
            },
            passiveUserIds: {
                value: [],
            },
            programEditionId: "",
        };
    },


    getSearchCriteriaEventLogsFilterDescription() {
        return [
            {name: "fieldIds", type: "dictionary", label: "Powierzchnia"},
            {name: "programs", type: "dictionary", label: "Programy"},
            {name: "activeUserIds", type: "dictionary", label: "Wykonał"},
            {name: "passiveUserIds", type: "dictionary", label: "Zmiana dotyczyła"},
            {name: "programEditionId", type: "", label: ""},
        ];
    },

    getClearSearchCriteriaLogLogs() {
        return {
            creationDate: null,
            ip: "",
            status: null,
            login: "",
        };
    },

    getSearchCriteriaObserverFilterDescription() {
        return [
            {name: "observerName",type: "", label: "Obserwator"},
            {name: "fieldName",type: "dictionary", label: "Powierzchnia"},
        ];
    },

    getSearchCriteriaControlsFilterDescription() {
        return [
            {name: "observerName",type: "dictionary", label: "Obserwator"},
            {name: "fieldName",type: "dictionary", label: "Powierzchnia"},
            {name: "positions",type: "", label: "Stanowiska"},
            {name: "controlName",type: "", label: "Kontrola"},
            {name: "controlState",type: "dictionary", label: "Status"},
        ];
    },

    getClearSearchCriteriaProgramEditions() {
        return {
            year: {
                value: null,
                matchMode: "between",
            },
            program: "",
            status: "",
        };
    },

    getClearSearchCriteriaDict(matchMode) {
        return {
            name: null,
            field: {
                name: null,
            },
            program: null,
            order: null,
            page: {
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
            },
            matchMode: matchMode,
        };
    },

    getSearchCriteriaProgramEditionFilterDescription() {
        return [
            {name: "year", type: "range", label: "Rok"},
            {name: "program", type: "dictionary", label: "Program"},
            {name: "status", type: "dictionary", label: "Status programu"},
        ];
    },

    getSearchCriteriaProgramEditionFilterDescriptionHome() {
        return [
            {name: "program", type: "dictionary", label: "Program"},
            {name: "season", type: "dictionary", label: "Sezon"},
            {name: "finished", type: "boolean", label: "Zakończony"},
            {name: "observer", type: "dictionary", label: "Obserwator"},
            {name: "regionalCoordinator", type: "dictionary", label: "Koordynator regionalny"},
            {name: "nationalCoordinator", type: "dictionary", label: "Koordynator krajowy"},
        ];
    },

    getClearSearchCriteriaProgramsList() {
        return {
            program: {
                value: null,
            },
            season: {
                value: null,
            },
            finished: {
                value: false,
            },
            observer: {
                value: null,
            },
            regionalCoordinator: {
                value: null,
            },
            nationalCoordinator: {
                value: null,
            },
        };
    },

    getSearchCriteriaFormsFilterDescriptionHome() {
        return [
            {name: "program", type: "dictionary", label: "Program"},
            {name: "programEditionId", type: "", label: "Program cd."},
            {name: "status", type: "dictionary", label: "Status"},
            {name: "finished", type: "boolean", label: "Zakończone"},
            {name: "observer", type: "dictionary", label: "Obserwator"},
            {name: "coordinator", type: "dictionary", label: "Koordynator regionalny"},
            {name: "nationalCoordinator", type: "dictionary", label: "Koordynator krajowy"},
            {name: "field", type: "dictionary", label: "Powierzchnia"},
            {name: "year", type: "range", label: "Rok"},
            {name: "control", type: "dictionary", label: "Kontrola"},
        ];
    },

    getClearSearchCriteriaForms() {
        return {
            program: {
                value: null,
            },
            programEditionId: {
                value: null,
            },
            status: {
                value: [],
            },
            finished: {
                value: false,
            },
            observer: {
                value: [],
            },
            coordinator: {
                value: [],
            },
            nationalCoordinator: {
                value: null,
            },
            field: {
                value: [],
            },
            year: {
                value: null,
                matchMode: "between",
            },
            control: {
                value: [],
            },
        };
    },

    getClearSearchCriteriaTrends() {
        return {
            programName: "",
            speciesName: "",
            yearStart: {
                value: null,
                matchMode: "between",
            },
            yearEnd: {
                value: null,
                matchMode: "between",
            },
            trendValue: {
                value: null,
                matchMode: "between",
            },
            standardError: {
                value: null,
                matchMode: "between",
            },
            trendName: "",
            trendTypeName: "",
            trendAreaName: "",
        };
    },

    getSearchCriteriaTrendsFilterDescription() {
        return [
            {name: "programName", type: "", label: "Program"},
            {name: "speciesName", type: "", label: "Gatunek"},
            {name: "yearStart", type: "range", label: "Rok od"},
            {name: "yearEnd", type: "range", label: "Rok do"},
            {name: "trendValue", type: "range", label: "Trend"},
            {name: "standardError", type: "range", label: "Błąd standardowy trendu"},
            {name: "trendName", type: "", label: "Trend (nazwa)"},
            {name: "trendTypeName", type: "", label: "Typ trendu"},
            {name: "trendAreaName", type: "", label: "Obszar"},
        ];
    },

    getClearSearchCriteriaIndicators() {
        return {
            programName: "",
            speciesName: "",
            year: {
                value: null,
                matchMode: "between",
            },
            areal: {
                value: null,
                matchMode: "between",
            },
            numberOfSettledSquares: {
                value: null,
                matchMode: "between",
            },
            populationSize: {
                value: null,
                matchMode: "between",
            },
            prevalence: {
                value: null,
                matchMode: "between",
            },
            prevalenceSE: {
                value: null,
                matchMode: "between",
            },
            averageNumOfChicksPerHatching: {
                value: null,
                matchMode: "between",
            },
            averageNumOfChicksPerHatchingSE: {
                value: null,
                matchMode: "between",
            },
            populationIndex: {
                value: null,
                matchMode: "between",
            },
            populationIndexSE: {
                value: null,
                matchMode: "between",
            },
            propOfPairsWithSucBread: {
                value: null,
                matchMode: "between",
            },
            propOfPairsWithSucBreadSE: {
                value: null,
                matchMode: "between",
            },
            averageNumOfChicksPerSucHatching: {
                value: null,
                matchMode: "between",
            },
            averageNumOfChicksPerSucHatchingSE: {
                value: null,
                matchMode: "between",
            },
            dataSource: "",
        };
    },

    getSearchCriteriaIndicatorsFilterDescription() {
        return [
            {name: "programName", type: "", label: "Program"},
            {name: "speciesName", type: "", label: "Gatunek"},
            {name: "year", type: "range", label: "Rok"},
            {name: "areal", type: "range", label: "Wyróżnik obszaru"},
            {name: "numberOfSettledSquares", type: "range", label: "Liczba zasiedlonych kwadratów"},
            {name: "populationSize", type: "range", label: "Liczebność populacji"},
            {name: "prevalence", type: "range", label: "Rozpowszechnienie"},
            {name: "prevalenceSE", type: "range", label: "Błąd standardowy rozpowszechnienia"},
            {name: "averageNumOfChicksPerHatching", type: "range", label: "Średnia liczba piskląt / lęg"},
            {name: "averageNumOfChicksPerHatchingSE", type: "range", label: "Błąd standardowy średniej liczby piskląt / lęgu"},
            {name: "populationIndex", type: "range", label: "Wskaźnik liczebności populacji"},
            {name: "populationIndexSE", type: "range", label: "Błąd standardowy wskaźnika liczebności populacji"},
            {name: "propOfPairsWithSucBread", type: "range", label: "Proporcja par z udanym lęgiem"},
            {name: "propOfPairsWithSucBreadSE", type: "range", label: "Błąd standardowy proporcji par z udanym lęgiem"},
            {name: "averageNumOfChicksPerSucHatching", type: "range", label: "Średnia liczba piskląt / lęg udany"},
            {name: "averageNumOfChicksPerSucHatchingSE", type: "range", label: "Błąd standardowy średniej liczby piskląt / lęgu udanego"},
            {name: "dataSource", type: "", label: "Źródło"},
        ];
    },

    getClearSearchCriteriaEnumDicts() {
        return {
            programs: [],
            code: "",
        };
    },
};
