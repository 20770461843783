<template>
    <DataTable :value="internalBirds" v-model:editingRows="editingRows" editMode="row" dataKey="id"
               @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
               class="navigate-table" @click="arrowsNavigateTable">
        <template #header>
            <AddBirdsMZPM v-model="internalBirds" />
        </template>
        <Column header="Odcinek transektu" field="transectSection" class="w-2">
            <template #editor="{data,field}">
                <CustomInputNumber label="Odcinek transektu" name="transectSection" v-model="data[field]"
                                   :customErrorMessage="getNotAuditedTransectMessage(data[field])" />
            </template>
        </Column>
        <Column header="Brak Ptaków" field="noBirds" class="w-1">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.noBirds) }}
            </template>
            <template #editor="{data,field}">
                <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="data[field]" />
            </template>
        </Column>
        <Column header="Kod" class="w-1" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.code }}
            </template>
            <template #editor="{data,field}">
                <CustomAutocompleteBirdSpecies label="Kod" name="species" v-model="data[field]"
                                               searchParam="code" @update:modelValue="completeSpecies(data)" />
            </template>
        </Column>
        <Column header="Gatunek" class="w-1" field="species2">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomAutocompleteBirdSpecies label="Gatunek" name="species" v-model="data[field]"
                                               searchParam="name" @update:modelValue="completeSpecies2(data)" />
            </template>
        </Column>
        <Column header="L. osobników" class="w-1" field="count">
            <template #editor="{data,field}">
                <CustomInputNumber label="L. osobników" name="count" v-model="data[field]" :min="1" />
            </template>
        </Column>
        <Column header="W locie" class="w-1" field="inFlight">
            <template #body="slotProps">
                <div :class="slotProps.data.inFlight ? 'true-style' : 'false-style'">
                    {{ computedBoolean(slotProps.data.inFlight) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomCheckbox label="W locie" name="inFlight" v-model="data[field]" />
            </template>
        </Column>
        <Column header="Strefa transektu" class="w-2" field="transectZone">
            <template #body="slotProps">
                {{ slotProps.data.transectZone?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Strefa transektu" name="transectZone" v-model="data[field]"
                                 :items="dicts.transectZone" />
            </template>
        </Column>
        <Column class="w-2"/>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import AddBirdsMZPM from "@/views/formMZPM/components/formBirds/AddBirdsMZPM.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMZPM",

        components: {
            DataTable,
            Column,
            CustomButton,
            AddBirdsMZPM,
            CustomInputNumber,
            CustomSelectOne,
            CustomAutocompleteBirdSpecies,
            CustomCheckbox,
        },

        props: {
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts", "transectInfo"],

        data() {
            return {
                editingRows: ref([]),
                auditedChecker: [],
                showErrorMessageTransectNotAudited: false,
            };
        },

        emits: ["update:birds"],

        beforeMount() {
            this.internalBirds.forEach((b) => {
                b.transectSection = parseInt(b.transect?.name, 10);
                b.transectId = b.transect?.id;
            });
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            onRowEditSave(event) {
                const {newData, index} = event;

                this.transectInfo.forEach((t, tIndex) => {
                    if (t.ordinal === newData.transectSection && t.notAudited) {
                        this.showErrorMessageTransectNotAudited = true;
                        this.auditedChecker[tIndex] = false;
                    } else {
                        this.auditedChecker[tIndex] = true;
                    }
                });
                const transectValidation = this.auditedChecker.every((el) => el);
                if (transectValidation) {
                    let transectId = null;
                    this.transectInfo.forEach((t) => {
                        if (t.ordinal === newData.transectSection) {
                            transectId = t.transectId;
                        }
                    });
                    this.internalBirds[index] = {...newData, transectId};
                    this.showErrorMessageTransectNotAudited = false;
                } else {
                    this.editingRows = [...this.editingRows, newData];
                    this.showErrorMessageTransectNotAudited = true;
                }
            },
            deleteBirdObservation(item) {
                const index = this.birds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            completeSpecies(ctx) {
                if (ctx && ctx.species?.code) ctx.species2 = ctx.species;
            },
            completeSpecies2(ctx) {
                if (ctx && ctx.species2?.code) ctx.species = ctx.species2;
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            getNotAuditedTransectMessage(value) {
                if (value && this.showErrorMessageTransectNotAudited) {
                    return this.$t("message.validations.transectValidation");
                }
                return "";
            },
        },

        computed: {
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../../../../assets/theme/mytheme/variables";

.true-style, .false-style {
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
}
.true-style {
    color: var(--secondary-color);
    background-color: $secondaryLightColor;
}
.false-style {
    color: var(--red);
    background-color: $colorLightRed;
}
</style>
