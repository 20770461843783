<template>
    <div class="p-fluid field relative">
        <span class="p-float-label" :style="computedWidth()">
            <InputNumber :id="name" v-model="internalValue" :class="computedClasses"
                         :min="notZero ? 1 : min" :max="max" mode="decimal" :show-buttons="false"
                         :disabled="disabled" @blur="onBlur" @input="onInput"
                         @keydown="onEsc" @change="handleChange" locale="pl-PL"
                         :minFractionDigits="minDigits" :maxFractionDigits="maxDigits"
                         :use-grouping="false"/>
            <CustomLabel v-if="label" :name="name" :label="label" :required="required"
                         :class="!showLabel ? 'visually-hidden' : ''" :disabled="disabled" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import InputNumber from "primevue/inputnumber";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomInputNumber",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: null,
            },
            rules: undefined,
            modelValue: null,
            showLabel: {
                type: Boolean,
                default: true,
            },
            minDigits: {
                type: Number,
                default: null,
            },
            maxDigits: {
                type: Number,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            handleEscEnterBlur: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            notZero: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
        },
        emits: ["update:modelValue", "onBlur", "onEsc", "input"],

        watch: {
            modelValue: {
                handler() {
                    this.inputValue = this.modelValue;
                },
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },

            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return `p-invalid ${this.name}`;
                }
                return `${this.name}`;
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleChange,
                handleInput,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                errorMessage,
                inputValue,
                handleChange,
                handleInput,
                meta,
            };
        },

        methods: {
            onInput(event) {
                this.$emit("update:modelValue", event.value);
                this.$emit("input", event.value);
            },
            onBlur() {
                if (this.handleEscEnterBlur) {
                    this.$nextTick(() => {
                        this.$emit("onBlur", this.internalValue);
                    });
                }
            },

            onEsc(event) {
                // block number 0
                if (this.notZero) {
                    if (event.key === "0" && event.target.value.length === 0) {
                        event.preventDefault();
                    }
                    if (event.target.value.length === 1 && event.target.value === "0") {
                        this.internalValue = null;
                    }
                }

                if (event.keyCode === 190
                    && !document.querySelector(`.${this.name} > input`).value.toString().includes(",")) {
                    document.querySelector(`.${this.name} > input`).value += ",";
                }
                if (!this.handleEscEnterBlur) {
                    return;
                }
                // esc
                if (event.keyCode === 27) {
                    event.target.blur();
                    this.$emit("onEsc");
                }
                // enter - calls onBlur through native events
                if (event.keyCode === 13) {
                    event.target.blur();
                }
            },
            computedWidth() {
                if (this.width === null) return "";
                return "width: " + this.width;
            },
        },

        components: {InputNumber, CustomValidationMessage, CustomLabel},
    };
</script>

<style scoped lang="scss">
</style>
