<template>
    <div class="flex justify-content-between flex-column">
        <div class="flex column-gap-3 justify-content-between">
            <div class="flex column-gap-3 flex-wrap w-11">
                <CustomInputText name="fileName" label="Nazwa pliku" class="w-2"
                                 style="min-width: 110px;" v-model="internalValue.fileName"
                                 @update:modelValue="update" />
                <CustomCalendar name="year" label="Rok" dateFormat="yy" view="year" placeholder="YYYY"
                                class="w-1" style="min-width: 100px;" v-model="internalValue.year"
                                @update:modelValue="update" />
                <CustomSelectOne name="control" :items="[]" :emptyValue="false" label="Kontrola"
                                 class="w-2" style="min-width: 150px;" v-model="internalValue.control"
                                 @update:modelValue="update" showClear />
                <CustomSelectOne :items="programs" :emptyValue="false" :show-clear="false"
                                 name="program" itemLabel="" label="Program" class="w-1"
                                 style="min-width: 110px;" v-model="internalValue.program"
                                 @update:modelValue="update" showClear />
                <CustomMultiSelect name="field" :items="fields" :emptyValue="false" itemValue="id"
                                   label="Powierzchnie" class="w-2" style="min-width: 150px;"
                                   v-model="internalValue.fieldId" itemLabel="name"
                                   virtualScrolling @update:modelValue="update"
                                   :disabled="!internalValue.program" />
                <CustomMultiSelect name="observer" :items="[]" :emptyValue="false" itemValue="id"
                                   label="Obserwatorzy" class="w-2" style="min-width: 150px;"
                                   v-model="internalValue.observerId" />
            </div>
            <CustomButton label="Wyczyść" @click="clear" class="field" />
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {
        getListOfPrograms,
        listFieldsForProgram,
    } from "@/swagger/vue-api-client";

    export default {
        name: "FileArchiveSearchCriteria",

        components: {
            CustomButton,
            CustomInputText,
            CustomCalendar,
            CustomSelectOne,
            CustomMultiSelect,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                programs: [],
                statuses: [],
                fields: [],
                name: "",
                loading: false,
            };
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            clear() {
                this.internalValue.fileName = "";
                this.internalValue.year = null;
                this.internalValue.control = null;
                this.internalValue.program = null;
                this.internalValue.fieldId = [];
                this.internalValue.observerId = [];
                this.update();
            },

            getFields() {
                if (this.internalValue.program) {
                    this.loading = true;
                    listFieldsForProgram({program: this.internalValue.program})
                        .then((response) => {
                            this.fields = response.data;
                            this.loading = false;
                        });
                } else {
                    this.field = [];
                    this.loading = false;
                }
            },

            update() {
                this.$emit("update:modelValue", this.internalValue);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            "internalValue.program"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.internalValue.fieldId = null;
                    this.getFields();
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
