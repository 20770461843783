<template>
    <div class="p-3">
        <AllEventLogsTable ref="eventLogsTable" />
    </div>
</template>

<script>
    import AllEventLogsTable from "./AllEventLogsTable.vue";

    export default {
        name: "EventLogsView",

        components: {
            AllEventLogsTable,
        },
    };
</script>

<style lang="scss">
</style>
