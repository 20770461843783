<template>
    <Dialog v-model:visible="showDownloadDialog" :closable="false" modal
            :draggable="false" class="download-all-dialog">
        <template #header>
            <p class="download-all-info p-3 text-center">
                Uwaga! Zostanie pobranych tylko pierwsze 500 wyszukiwanych plików. Jeśli chcesz pobrać wszystkie wyszukiwane pliki, zawęzij kryteria, dzieląc wyszukiwanie na grupy wyników.
            </p>
        </template>
        <div class="download-all-dialog-buttons">
            <CustomButton label="Anuluj" @click="showDownloadDialog = false" bgColor="transparent" />
            <CustomButton label="Pobierz" class="green-button" @click="downloadAll(true)" />
        </div>
    </Dialog>
    <div class="file-archive-table">
        <DataTable :value="files" dataKey="id" lazy :totalRecords="totalRecords"
                   :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="currentPageReportTemplate" paginator
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset">
            <!-- <Column header="Lp." field="" style="width: 4.1666%;" /> -->
            <template #header>
                <div class="flex justify-content-end">
                    <CustomButton label="Pobierz wszystko" icon="pi pi-download" @click="downloadAll(false)" />
                </div>
            </template>
            <Column header="Nazwa pliku" field="fileName"></Column>
            <Column header="Dodany" field="insertDate"></Column>
            <Column header="Obserwator" field="observer">
                <template #body="slotProps">
                    {{ slotProps.data.observer.name }}
                </template>
            </Column>
            <Column header="Koordynator" field="coordinator">
                <template #body="slotProps">
                    {{ slotProps.data.coordinator.name }}
                </template>
            </Column>
            <Column header="Rok" field="year"></Column>
            <Column header="Program" field="program"></Column>
            <Column header="Kontrola" field="control">
                <template #body="slotProps">
                    {{ slotProps.data.control.label }}
                </template>
            </Column>
            <Column header="Powierzchnia" field="field">
                <template #body="slotProps">
                    {{ slotProps.data.field.name }}
                </template></Column>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="flex flex-wrap gap-2">
                        <CustomButton label="Pobierz" icon="pi pi-download" @click="download(slotProps.data)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Dialog from "primevue/dialog";
    import {
        searchFiles,
        searchFilesCount,
        // downloadAllFiles,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FileArchiveTable",

        components: {
            DataTable,
            Column,
            CustomButton,
            Dialog,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                files: null,
                loading: false,
                totalRecords: 0,
                showDownloadDialog: false,
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;
                const year = this.searchCriteria?.year ? this.searchCriteria?.year.getFullYear() : null;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchFiles({body: {...this.searchCriteria, year}})
                    .then((response) => {
                        this.files = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                const year = this.searchCriteria?.year ? this.searchCriteria?.year.getFullYear() : null;

                searchFilesCount({body: {...this.searchCriteria, year}})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            download(file) {
                FileUtils.downloadFile({id: file.id, body: this.searchCriteria}, file.fileName, `/file/download/${file.id}`);
            },

            downloadAll(download) {
                if (download || this.totalRecords <= 500) {
                    FileUtils.downloadFile({body: this.searchCriteria}, `Pobrane_pliki_${(DateUtils.displayDateTime(new Date())).replace(" ", "_")}`, "/file/download-all", true);
                } else {
                    this.showDownloadDialog = true;
                }
            },
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },
    };
</script>

<style lang="scss">
</style>
