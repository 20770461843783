<template>
    <DictDetailsViewTemplate :acceptGeobject="acceptPosition" :acceptGeobjectHistory="acceptPositionHistory"
                             :getGeobjectData="getPositionById" :createGeobjectFunction="createPosition"
                             :createGeobjectHistoryFunction="createPositionHistory"
                             :updateGeobjectHistoryFunction="updatePositionHistory"
                             :updateGeobjectFunction="updatePosition" :deleteGeobjectHistoryFunction="deletePositionHistory"
                             :getAvailableControlVersionsFunction="getAvailablePositionControlVersions"
                             name="position" biernik="stanowisko" dopelniacz="stanowiska"/>
</template>

<script>
    import DictDetailsViewTemplate from "@/views/dict/DictDetailsViewTemplate.vue";
    import {
        acceptPosition,
        acceptPositionHistory,
        createPosition,
        createPositionHistory,
        deletePositionHistory,
        getAvailablePositionControlVersions,
        getPositionById,
        updatePosition,
        updatePositionHistory,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PointDetailsView",

        components: {
            DictDetailsViewTemplate,
        },

        methods: {
            getAvailablePositionControlVersions,
            deletePositionHistory,
            updatePosition,
            updatePositionHistory,
            createPositionHistory,
            createPosition,
            getPositionById,
            acceptPositionHistory,
            acceptPosition,
        },
    };
</script>
