<template>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" />
    <div ref="markerPopup" class="marker-popup hidden"></div>
    <div v-if="markerPopup">
        <div v-for="(position,index) in positions" ref="markerPopup" class="marker-popup" :key="position[idField]"
             @mouseover="mouseoverOrFocusPopup(position[idField],index)"
             @focus="mouseoverOrFocusPopup(position[idField],index)"
             @click="mouseoverOrFocusPopup(position[idField],index)">
            <slot name="markerPopup" :position="position" :index="index"></slot>
        </div>
    </div>
    <div v-if="markerPopupFull">
        <div v-for="(position,index) in positions" ref="markerPopupFull" class="marker-popup-full"
             :key="position[idField]" @mouseleave="hideFullPopup(position[idField],index)"
             @blur="hideFullPopup(position[idField],index)" @mouseover="clearTimer(index)" @focus="clearTimer(index)">
            <slot name="markerPopupFull" :position="position" :index="index"></slot>
        </div>
    </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
    import _ from "lodash";
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapMultiMarkers",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            markerPopup: {
                type: Boolean,
                default: false,
            },
            markerPopupFull: {
                type: Boolean,
                default: false,
            },
            positions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            markerTypes: {
                type: Map,
                default: new Map(),
            },
            openPopupByDefault: {
                type: Boolean,
                default: false,
            },
            openPopupOnHover: {
                type: Boolean,
                default: false,
            },
            openPopupOnClick: {
                type: Boolean,
                default: false,
            },
            changeIconOnHover: {
                type: Boolean,
                default: false,
            },
            iconOnHover: {
                type: Object,
            },
            fitBounds: {
                type: Boolean,
                default: false,
            },
            panToClicked: {
                type: Boolean,
                default: false,
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            onlyStepOne: {
                type: Boolean,
                default: false,
            },
            idField: {
                type: String,
                default: "positionId",
            },
            subfield: {
                type: Array,
            },
        },

        data() {
            return {
                markersCoordinates: [],
                markers: new Map(),
                /* eslint-disable */
                iconRed: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#EA3030"/></svg>',
                iconRedSmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#EA3030"/></svg>',
                iconGreen: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#33795B"/></svg>',
                iconGreenSmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#33795B99"/></svg>',
                iconGrey: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#738087"/></svg>',
                iconGreySmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#738087"/></svg>',
                iconLightGrey: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#73808799"/></svg>',
                iconLightGreySmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#73808799"/></svg>',
                /* eslint-enable */
                fieldOnMap: [],
                fieldShape: null,
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                currentPositionsOnMap: new Map(),
                timer: [],
            };
        },

        emits: ["dragendMarker", "clickMarker", "mouseoverMarker", "mouseoutMarker"],

        mounted() {
            this.initCustomIdMap(this.mapId);
            this.addZoomControls();
            if (this.field != null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            if (this.subfield != null && this.subfield.length > 0) {
                this.makeSubfield();
            }
            this.makeMarkers();
            this.placeMarkers();
        },

        updated() {
            if (this.openPopupByDefault) {
                this.positions.forEach((position, index) => {
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (!layer.isPopupOpen()) {
                                const popup = L.popup().setContent(this.$refs.markerPopup[index]);
                                layer.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                            }
                        }
                    });
                });
            }
        },

        methods: {
            mouseoverOrFocusPopup(positionId, index) {
                if (this.markerPopupFull && this.openPopupOnHover) this.showFullPopup(positionId, index);
            },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                this.fieldShape = L.polygon(this.fieldOnMap);
                this.fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                this.fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            makeSubfield() {
                const subfieldArray = [];
                for (let i = 0; i < this.subfield.length; i += 1) {
                    subfieldArray.push([this.subfield[i].latitude, this.subfield[i].longitude]);
                }
                const subfieldShape = L.polygon(subfieldArray);
                subfieldShape.setStyle({fillColor: "transparent", color: "#EA3030"});
                subfieldShape.addTo(this.map);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeMarkers() {
                for (let i = 0; i < this.positions.length; i += 1) {
                    this.makeMarker(this.positions[i][this.idField]);
                }
            },
            makeMarker(id) {
                const markerType = this.getMarkerType(id);
                const marker = this.getMarker(id, markerType.color, markerType.size, markerType.dontHidePopup);
                this.markers.set(id, marker);
            },
            getMarker(id, color, size, dontHidePopup) {
                return {
                    icon: this.getIcon(color, size),
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                    id,
                    dontHidePopup,
                };
            },
            getMarkerType(positionId) {
                if (this.markerTypes.has(positionId)) {
                    return this.markerTypes.get(positionId);
                }
                return {color: "lightGrey", size: "regular"};
            },
            getIcon(color, size) {
                if (size === "regular") {
                    let icon = this.iconLightGrey;
                    if (color === "red") icon = this.iconRed;
                    if (color === "green") icon = this.iconGreen;
                    if (color === "grey") icon = this.iconGrey;
                    if (color === "lightGrey") icon = this.iconLightGrey;
                    // eslint-disable-next-line
                    return new L.divIcon({
                        html: icon,
                        iconSize: [27, 38],
                        iconAnchor: [13.5, 38],
                        popupAnchor: [1, -34],
                    });
                }
                if (size === "small") {
                    let icon = this.iconLightGreySmall;
                    if (color === "red") icon = this.iconRedSmall;
                    if (color === "green") icon = this.iconGreenSmall;
                    if (color === "grey") icon = this.iconGreySmall;
                    if (color === "lightGrey") icon = this.iconLightGreySmall;
                    // eslint-disable-next-line
                    return new L.divIcon({
                        html: icon,
                        iconSize: [13, 19],
                        iconAnchor: [7.5, 19],
                        popupAnchor: [1, -34],
                    });
                }
                // eslint-disable-next-line
                return new L.divIcon({
                    html: this.iconLightGrey,
                    iconSize: [27, 38],
                    iconAnchor: [13.5, 38],
                    popupAnchor: [1, -34],
                });
            },
            placeMarkers() {
                for (let i = 0; i < this.positions.length; i += 1) {
                    this.placeMarker(
                        this.positions[i][this.idField], this.positions[i].coordinates, this.positions[i], i);
                }
            },
            placeMarker(positionId, coordinates, position, index) {
                if (coordinates != null && coordinates?.latitude != null && coordinates?.longitude != null) {
                    let popup;
                    if (this.markerPopup) popup = L.popup().setContent(this.$refs.markerPopup[index]);
                    const marker = L.marker([coordinates.latitude, coordinates.longitude],
                                            this.markers.get(positionId)).addTo(this.map);
                    let markerType = this.markerTypes.get(positionId);
                    if (markerType.popup) {
                        marker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                    }
                    marker.on("dragstart", () => {
                        markerType = this.markerTypes.get(positionId);
                        if (markerType.popup) {
                            marker.closePopup();
                        }
                    });
                    marker.on("dragend", (e) => {
                        this.$emit("dragendMarker", marker._latlng, e.target.options.id);
                        markerType = this.markerTypes.get(positionId);
                        if (markerType.popup) {
                            marker.openPopup();
                        }
                    });
                    marker.on("click", () => {
                        this.$emit("clickMarker", positionId);
                        marker.options.dontHidePopup = true;
                        if (this.markerPopupFull) {
                            this.showFullPopup(positionId, index);
                            this.hideOtherFullPopups(positionId);
                        } else if (this.openPopupOnClick) {
                            this.showPopup(positionId, index);
                            this.hideOtherPopups(positionId, index);
                        }
                        if (this.panToClicked) {
                            this.panToMarker(positionId);
                        }
                    });
                    marker.on("mouseover", () => {
                        this.$emit("mouseoverMarker", positionId);
                        if (this.openPopupOnHover && this.markerPopupFull && !marker.options.dontHidePopup) {
                            this.clearTimer(index);
                            this.showFullPopup(positionId, index);
                        } else if (this.openPopupOnHover && !marker.options.dontHidePopup && !this.changeIconOnHover) {
                            this.showPopup(positionId, index);
                        }
                    });
                    marker.on("mouseout", () => {
                        this.$emit("mouseoutMarker", positionId);
                        if (this.markerPopupFull && !marker.options.dontHidePopup) {
                            this.hideFullPopup(positionId, index);
                        } else if (this.openPopupOnHover && !marker.options.dontHidePopup && !this.changeIconOnHover) {
                            this.hidePopup(positionId, index);
                        }
                    });
                    if (this.changeIconOnHover) {
                        const normalIcon = marker.getIcon();
                        const hoverIcon = this.getIcon(this.iconOnHover.color, this.iconOnHover.size);
                        marker.once("mouseover", () => {
                            marker.setIcon(hoverIcon);
                            if (this.openPopupOnHover && !marker.options.dontHidePopup) {
                                this.showPopup(positionId, index);
                            }
                        });
                        marker.on("mouseout", () => {
                            marker.setIcon(normalIcon);
                            if (this.openPopupOnHover && !marker.options.dontHidePopup) {
                                this.hidePopup(positionId, index);
                            }
                            marker.once("mouseover", () => {
                                marker.setIcon(hoverIcon);
                                if (this.openPopupOnHover && !marker.options.dontHidePopup) {
                                    this.showPopup(positionId, index);
                                }
                            });
                        });
                    }
                    if (this.markerPopupFull) {
                        this.map.on("click", () => this.hideAllFullPopups());
                    }
                    if (this.fitBounds) {
                        const markersArr = [];
                        this.markers.forEach((value) => markersArr.push(value));
                        // eslint-disable-next-line
                        const group = new L.featureGroup(markersArr);
                        const bounds = group.getBounds();
                        this.map.fitBounds(bounds, {padding: [20, 20]});
                    }
                    this.markersCoordinates.push([coordinates.latitude, coordinates.longitude]);
                    this.currentPositionsOnMap.set(positionId, _.cloneDeep(position));
                }
            },
            panToMarker(positionId) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            this.map.setZoom(14);
                            this.map.panTo(layer.getLatLng());
                        }
                    }
                });
            },
            clearTimer(i) {
                if (this.timer[i] !== null) {
                    clearTimeout(this.timer[i]);
                }
            },
            showPopup(positionId, i) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId && !layer.options.dontHidePopup && !layer.isPopupOpen()) {
                            const popup = L.popup().setContent(this.$refs.markerPopup[i]);
                            layer.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                        }
                    }
                });
            },
            hidePopup(positionId) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId && !layer.options.dontHidePopup && layer.isPopupOpen()) {
                            layer.closePopup().unbindPopup();
                        }
                    }
                });
            },
            hideOtherPopups(positionId) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id !== positionId && layer.isPopupOpen()) {
                            layer.closePopup().unbindPopup();
                            layer.options.dontHidePopup = false;
                        }
                    }
                });
            },
            showFullPopup(positionId, i) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            layer.closePopup();
                            layer.setPopupContent(this.$refs.markerPopupFull[i]);
                            layer.openPopup();
                        }
                    }
                });
            },
            hideFullPopup(positionId, i) {
                this.timer[i] = setTimeout(() => {
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (layer.options.id === positionId && !layer.options.dontHidePopup) {
                                layer.setPopupContent(this.$refs.markerPopup[i]);
                            }
                        }
                    });
                }, 200);
            },
            hideOtherFullPopups(positionId) {
                let index = -1;
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        index += 1;
                        if (layer.options.id !== positionId) {
                            if (layer._popup && layer._popup._content && layer._popup._content.classList[0] === "marker-popup-full") {
                                layer.setPopupContent(this.$refs.markerPopup[index]);
                                layer.options.dontHidePopup = false;
                            }
                        }
                    }
                });
            },
            hideAllFullPopups() {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.dontHidePopup) {
                            for (let i = 0; i < this.positions.length; i += 1) {
                                if (this.positions[i][this.idField] === layer.options.id) {
                                    layer.setPopupContent(this.$refs.markerPopup[i]);
                                    layer.options.dontHidePopup = false;
                                }
                            }
                        }
                    }
                });
            },
            moveMarkerToNewPoint(positionId, coordinates) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            layer.setLatLng([coordinates.latitude, coordinates.longitude]);
                        }
                    }
                });
            },
            removeMarker(positionId) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            layer.removeFrom(this.map);
                        }
                    }
                });
                this.markers.delete(positionId);
            },
            removeAllMarkers() {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        layer.removeFrom(this.map);
                    }
                });
            },
            updateMarkerType(positionId, markerType) {
                this.map?.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            // update icon
                            layer.setIcon(this.getIcon(markerType.color, markerType.size));
                            // find popup index
                            let index;
                            this.positions.forEach((p, i) => {
                                if (p[this.idField] === positionId) index = i;
                            });
                            // update popup
                            const popup = L.popup().setContent(this.$refs.markerPopup[index]);
                            if (markerType.popup) {
                                if (markerType.dontHidePopup != null) {
                                    layer.options.dontHidePopup = markerType.dontHidePopup;
                                }
                                if (!layer.isPopupOpen()) {
                                    layer.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                                }
                            } else if (layer.isPopupOpen()) {
                                layer.closePopup().unbindPopup();
                            }
                            // update draggability
                            if (markerType.draggable) layer.dragging.enable();
                            else layer.dragging.disable();
                        }
                    }
                });
            },
        },

        watch: {
            markerTypes: {
                handler(value) {
                    value.forEach((markerType, positionId) => this.updateMarkerType(positionId, markerType));
                },
                deep: true,
            },
            isOn(value) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        layer.options.draggable = value;
                        if (value) layer.dragging.enable();
                        else layer.dragging.disable();
                    }
                });
            },
            positions: {
                handler(newPositions) {
                    const newPositionsIdsSet = new Set();
                    newPositions.forEach((pNew, index) => {
                        if (pNew.coordinates?.latitude != null && pNew.coordinates?.longitude != null) {
                            newPositionsIdsSet.add(pNew[this.idField]);
                            if (!this.currentPositionsOnMap.has(pNew[this.idField])) {
                                this.makeMarker(pNew[this.idField]);
                                setTimeout(() => {
                                    this.placeMarker(pNew[this.idField], pNew.coordinates, pNew, index);
                                }, 300);
                            } else {
                                const pOld = this.currentPositionsOnMap.get(pNew[this.idField]);
                                if (pNew.coordinates?.latitude !== pOld.coordinates?.latitude
                                    || pNew.coordinates?.longitude !== pOld.coordinates?.longitude) {
                                    this.moveMarkerToNewPoint(pNew[this.idField], pNew.coordinates);
                                }
                            }
                            this.currentPositionsOnMap.set(pNew[this.idField], _.cloneDeep(pNew));
                        }
                    });

                    const mapIter = this.currentPositionsOnMap.keys();
                    let pOldId = mapIter.next().value;
                    while (pOldId != null && pOldId) {
                        if (!newPositionsIdsSet.has(pOldId)) {
                            this.removeMarker(pOldId);
                            this.currentPositionsOnMap.delete(pOldId);
                        }
                        pOldId = mapIter.next().value;
                    }
                },
                deep: true,
            },
            onlyStepOne(value) {
                if (value) {
                    this.removeAllMarkers();
                } else {
                    this.placeMarkers();
                }
            },
            field: {
                handler(value) {
                    console.log("update field", value);
                    this.fieldOnMap = [];
                    for (let i = 0; i < value.length; i += 1) {
                        this.fieldOnMap.push([value[i].latitude, value[i].longitude]);
                    }
                    if (this.fieldOnMap.length > 0) {
                        if (this.fieldShape == null) {
                            this.makeField();
                        } else {
                            this.fieldShape.setLatLngs(this.fieldOnMap);
                        }
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/leaflet-map";
    @import "../../assets/theme/mytheme/variables";
</style>
