<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-1">
                <CustomOfflineAutocomplete label="Kod" name="speciesCode" v-model="newItem.species"
                                           :items="dicts.programBirds" optionLabel="code" searchField="code"
                                           required :showErrors="showErrors" :disabled="!editing"
                                           :customErrorMessage="getIncorrectSpeciesMessage()"/>
            </div>
            <div class="w-1">
                <CustomOfflineAutocomplete label="Gatunek" name="speciesName" v-model="newItem.species"
                                           :items="dicts.programBirds" optionLabel="name" searchField="name"
                                           required :showErrors="showErrors" :disabled="!editing"
                                           :customErrorMessage="getIncorrectSpeciesMessage()"/>
            </div>
            <div class="w-2">
                <CustomInputNumber label="Liczba osobników" name="count" v-model="newItem.count"
                                   required :showErrors="showErrors" :disabled="!editing"
                                   :min="1" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Kategoria odległości" name="distance" v-model="newItem.distance"
                                 :items="dicts.distance" :showErrors="showErrors"
                                 :disabled="!editing" required/>
            </div>
            <div class="w-3">
                <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="newItem.nestingCriteria"
                                 :items="dicts.nestingCriteria" :showErrors="showErrors && requiredNestingCriteria"
                                 :disabled="!editing"
                                 :required="requiredNestingCriteria" short tooltip/>
            </div>
            <div class="w-1"> </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomOfflineAutocomplete from "@/components/form/inner/CustomOfflineAutocomplete.vue";

    export default {
        name: "AddBirdsMLS",

        components: {
            CustomOfflineAutocomplete,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
            };
        },

        methods: {
            add() {
                if (!(ValidateUtils.validateNotEmpty(this.newItem, ["species", "count", "distance"]) || ValidateUtils.validateCorrectSpecies(this.newItem, ["species"]))
                    || (this.requiredNestingCriteria && !(ValidateUtils.validateNotEmpty(this.newItem, ["species", "count", "distance", "nestingCriteria"]) && typeof this.newItem.species === "object"))) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
            getIncorrectSpeciesMessage() {
                if (!ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) return "Niepoprawny gatunek";
                return "";
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
            requiredNestingCriteria() {
                return this.newItem.species?.code !== "CORRAX" && this.newItem.species?.code !== "CORNIX";
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
</style>
