<template>
    <div class="form-map mb-5 mt-0">
        <EditTransectDialog v-if="editedTransect != null" v-model:visible="showEditDialog" :field="field"
                            v-model:transect="editedTransect" @save="saveEdited"/>
        <MapMultiTransects mapId="mainMap" popup popupFull :transects="transects"
                           :field="field" openPopupByDefault :isOn="false">
            <template v-slot:popup="slotProps">
                <div class="marker-popup-header">
                    {{ slotProps.transect.name }}
                </div>
            </template>
            <template v-slot:popupFull="slotProps">
                <div class="marker-popup-full-header">
                    {{ slotProps.transect.name }}
                </div>
                <div class="marker-popup-full-content" style="min-width: 290px">
                    <div class="checkbox-container">
                        <Checkbox id="notAudited-checkbox" v-model="slotProps.transect.notAudited"
                                  :disabled="disabled" :binary="true"/>
                        <label for="notAudited-checkbox" :style="disabled ? {color: '#6c757d60'} : ''">
                            Kontrola nie została wykonana
                        </label>
                    </div>
                    <div v-if="slotProps.transect.notAudited">
                        <CustomSelectOne label="Przyczyna braku kontroli" name="notAuditedReason"
                                         v-model="slotProps.transect.notAuditedReason" :disabled="disabled"
                                         :required="slotProps.transect.notAudited" :showClear="false"
                                         :items="internalControlNotRealizedReason" class="mt-4"
                                         :filter="false" />
                        <CustomInputTextArea v-model="slotProps.transect.notAuditedNotes" name="notAuditedNotes"
                                             label="Uwagi" :disabled="disabled" />
                    </div>
                    <div>
                        <p v-if="canCallToChange(slotProps.transect)" class="cursor-pointer w-max"
                           @click="callToChange(slotProps.transect,slotProps.index)">
                            Zgłoś zmianę
                        </p>
                        <p v-if="canUndoReport(slotProps.transect)" class="cursor-pointer w-max"
                           @click="undoReport(slotProps.transect,slotProps.index)">
                            Cofnij zgłoszenie
                        </p>
                    </div>
                </div>
            </template>
        </MapMultiTransects>
    </div>
</template>

<script>
    import _ from "lodash";
    import Checkbox from "primevue/checkbox";
    import MapMultiTransects from "@/components/map/MapMultiTransects.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import {
        editGeographicalHistoryInForm,
        revertGeographicalHistoryInForm,
    } from "@/swagger/vue-api-client";
    import EditTransectDialog from "@/components/transectForm/EditTransectDialog.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "TransectsMap",

        components: {
            EditTransectDialog,
            Checkbox,
            CustomInputTextArea,
            MapMultiTransects,
            CustomSelectOne,
        },

        props: {
            modelValue: {
                type: Array,
            },
            field: {
                type: Array,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["programCode", "dicts"],

        data() {
            return {
                showEditDialog: false,
                editedTransect: null,
                editedTransectIndex: null,
                internalControlNotRealizedReason: [],
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            if (this.dicts.controlNotRealizedReason?.length > 0) {
                this.internalControlNotRealizedReason = this.dicts.controlNotRealizedReason.filter((cNRR) => cNRR.info === undefined || cNRR.info == null);
            }
        },

        methods: {
            canCallToChange(transect) {
                return !transect.notAudited && !this.disabled;
            },
            canUndoReport(transect) {
                return !transect.accepted && !transect.notAudited && !this.disabled;
            },
            callToChange(transect, index) {
                this.editedTransect = _.cloneDeep(transect);
                this.editedTransectIndex = index;
                this.showEditDialog = true;
            },
            undoReport(transect, index) {
                revertGeographicalHistoryInForm({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                    geographicalObjectId: transect.transectId,
                })
                    .then((response) => {
                        this.transects[index].line = response.data.line;
                        this.transects[index].accepted = response.data.accepted;
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zmiana została wycofana",
                            life: 3000,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response?.data != null ? error.response?.data : error.message,
                            life: 3000,
                        });
                    });
            },
            saveEdited(editedTransect) {
                editGeographicalHistoryInForm({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                    body: {
                        type: "transect",
                        objectId: editedTransect.transectId,
                        coordinates: editedTransect.line,
                    },
                })
                    .then((response) => {
                        this.transects[this.editedTransectIndex].accepted = response.data.accepted;
                        this.transects[this.editedTransectIndex].line = response.data.line;
                        this.showEditDialog = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zmiana lokalizacji została zgłoszona",
                            life: 3000,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response?.data != null ? error.response?.data : error.message,
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            transects: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            transects: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .form-map {
        width: 100%;
        height: calc(100vh - 600px);
        margin-top: -32px;
        #mainMap {
            position: relative;
            height: calc(100vh - 575px);
        }
    }
    @media screen and (max-height: 750px) {
            .form-map {
            height: 235px;
            #mainMap {
                height: 250px;
            }
        }
    }
</style>
