<template>
    <div v-if="loaded" class="flex gap-3 flex-wrap row-gap-0">
        <CustomSelectOne label="Filtruj po" :items="filterBy" itemValue="value" itemLabel="label"
                         name="filterBy" class="w-12rem" v-model="internalMatchMode" :showClear="false" :filter="false"/>
        <CustomMultiSelect v-if="geographyFilters.wojewodztwa !== null" id="wojFilter"
                           v-model="internalValue.geography.wojewodztwa" name="wojewodztwa"
                           itemValue="id" itemLabel="name"
                           :items="geographyFilters.wojewodztwa" label="Województwo" class="w-12rem"
                           @update:modelValue="updateWojewodztwa" />
        <CustomMultiSelect v-if="geographyFilters.powiaty !== null" id="powFilter"
                           v-model="internalValue.geography.powiaty" name="powiaty"
                           :items="powiaty" label="Powiat" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="updatePowiaty"
                           :disabled="internalValue.geography.wojewodztwa.length === 0 && powiaty.length === 0" />
        <CustomMultiSelect v-if="geographyFilters.gminy !== null" id="gmFilter"
                           v-model="internalValue.geography.gminy" name="gminy"
                           :items="gminy" label="Gmina" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update"
                           :disabled="gminy.length === 0" />
        <CustomMultiSelect v-if="geographyFilters.rdlp !== null" id="rdlpFilter"
                           v-model="internalValue.geography.rdlp" name="rdlp"
                           :items="geographyFilters.rdlp" label="RDLP" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="updateRdlp" />
        <CustomMultiSelect v-if="geographyFilters.nadlesnictwa !== null" id="nadFilter"
                           v-model="internalValue.geography.nadlesnictwa" name="nadlesnictwa"
                           :items="nadlesnictwa" label="Nadleśnictwo" class="w-12rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="updateNadlesnictwa"
                           :disabled="nadlesnictwa.length === 0" />
        <CustomMultiSelect v-if="geographyFilters.lesnictwa !== null" id="lesFilter"
                           v-model="internalValue.geography.lesnictwa" name="lesnictwa"
                           :items="lesnictwa" label="Leśnictwo" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update"
                           :disabled="lesnictwa.length === 0" />
        <CustomMultiSelect v-if="geographyFilters.oso !== null" id="osoFilter"
                           v-model="internalValue.geography.oso" name="oso"
                           :items="geographyFilters.oso" label="OSO" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.ochk !== null" id="ochFilter"
                           v-model="internalValue.geography.ochk" name="ochk"
                           :items="geographyFilters.ochk" label="OChK" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.soo !== null" id="sooFilter"
                           v-model="internalValue.geography.soo" name="soo"
                           :items="geographyFilters.soo" label="SOO" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.ramsar !== null" id="ramFilter"
                           v-model="internalValue.geography.ramsar" name="ramsar"
                           :items="geographyFilters.ramsar" label="RAMSAR" class="w-10rem"
                           itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.rezerwaty !== null" id="rezFilter"
                           v-model="internalValue.geography.rezerwaty" name="rezerwaty"
                           :items="geographyFilters.rezerwaty" label="Rezerwat"
                           class="w-10rem" itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.parkiKraj !== null" id="pkrajFilter"
                           v-model="internalValue.geography.parkiKraj" name="parkiKraj"
                           :items="geographyFilters.parkiKraj" label="Park krajobrazowy"
                           class="w-16rem" itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
        <CustomMultiSelect v-if="geographyFilters.parkiNar !== null" id="pnarFilter"
                           v-model="internalValue.geography.parkiNar" name="parkiNar"
                           :items="geographyFilters.parkiNar" label="Park narodowy"
                           class="w-14rem" itemValue="id" itemLabel="name"
                           @update:modelValue="update" />
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {
        getAllGeographies,
    } from "@/swagger/vue-api-client";

    export default {
        name: "GeographicalFilters",

        components: {
            CustomMultiSelect,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
            matchMode: null,
            filterBy: {
                type: Array,
                default: () => [],
            },
        },

        emits: ["update:modelValue", "update:matchMode"],

        data() {
            return {
                geographyFilters: {
                    wojewodztwa: [],
                    rdlp: [],
                    ochk: [],
                    oso: [],
                    soo: [],
                    parkiKraj: [],
                    parkiNar: [],
                    ramsar: [],
                    rezerwaty: [],
                },
                geography: {
                    wojewodztwa: [],
                    powiaty: [],
                    gminy: [],
                    rdlp: [],
                    nadlesnictwa: [],
                    lesnictwa: [],
                    ochk: [],
                    oso: [],
                    soo: [],
                    parkiKraj: [],
                    parkiNar: [],
                    ramsar: [],
                    rezerwaty: [],
                },
                selectedWoj: [],
                selectedPow: [],
                selectedRdlp: [],
                selectedNadl: [],
                loaded: false,
            };
        },

        beforeMount() {
            getAllGeographies()
                .then((response) => {
                    this.geographyFilters = response.data;
                    this.internalValue.geography = _.cloneDeep(this.geography);
                    this.loaded = true;
                });
        },

        mounted() {
            this.internalMatchMode = "FIELD";
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.geography = _.cloneDeep(this.geography);
                this.selectedWoj = [];
                this.selectedPow = [];
                this.selectedRdlp = [];
                this.selectedNadl = [];
            },

            updateWojewodztwa() {
                this.selectedWoj = this.geographyFilters.wojewodztwa.filter((woj) => this.internalValue.geography.wojewodztwa.includes(woj.id));
                this.update();
            },
            updatePowiaty() {
                this.selectedPow = this.powiaty.filter((pow) => this.internalValue.geography.powiaty.includes(pow.id));
                this.update();
            },
            updateNadlesnictwa() {
                this.selectedNadl = this.nadlesnictwa.filter((nadl) => this.internalValue.geography.nadlesnictwa.includes(nadl.id));
                this.update();
            },
            updateRdlp() {
                this.selectedRdlp = this.geographyFilters.rdlp.filter((rdlp) => this.internalValue.geography.rdlp.includes(rdlp.id));
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            powiaty() {
                return this.selectedWoj.flatMap((woj) => woj.sublist);
            },
            gminy() {
                return this.selectedPow.flatMap((pow) => pow.sublist);
            },
            nadlesnictwa() {
                return this.selectedRdlp.flatMap((rdlp) => rdlp.sublist);
            },
            lesnictwa() {
                return this.selectedNadl.flatMap((nadl) => nadl.sublist);
            },
            internalMatchMode: {
                get() { return this.matchMode; },
                set(value) { this.$emit("update:matchMode", value); },
            },
        },

        watch: {
            geographyFilters: {
                handler() {
                    if (this.internalValue.geography?.wojewodztwa?.length > 0) {
                        this.updateWojewodztwa();
                    }
                    if (this.internalValue.geography?.powiaty?.length > 0) {
                        this.updatePowiaty();
                    }
                    if (this.internalValue.geography?.rdlp?.length > 0) {
                        this.updateRdlp();
                    }
                    if (this.internalValue.geography?.nadlesnictwa?.length > 0) {
                        this.updateNadlesnictwa();
                        if (this.internalValue.geography?.lesnictwa?.length > 0) {
                            this.selectedNadl.flatMap((nadl) => nadl.sublist);
                        }
                    }
                    setTimeout(() => {
                    }, 1000);
                },
                deep: true,
            },
            internalValue: {
                handler(value) {
                    if (!value.geography) {
                        value.geography = _.cloneDeep(this.geography);
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
