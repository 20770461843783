<template>
    <div class="border-1 surface-border mt-3 p-3">
        <DataTable :value="emailSendingRules">
            <Column header="Reguła e-mail">
                <template #body="slotProps">
                    {{ computedText(slotProps.data) }}
                </template>
            </Column>
            <!-- <Column header="Liczba dni" field="quantity" />
            <Column header="Typ" field="type">
                <template #body="slotProps">
                    {{ computedType(slotProps.data.type) }}
                </template>
            </Column> -->
            <Column header="Typ e-maila" field="mailType">
                <template #body="slotProps">
                    {{ computedEmailType(slotProps.data.emailType) }}
                </template>
            </Column>
            <Column style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteRule(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    // import {} from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "ProgramEmailSendingTable",

        components: {
            DataTable,
            Column,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Object,
            },
        },

        data() {
            return {};
        },

        methods: {
            deleteRule(item) {
                const index = this.emailSendingRules.indexOf(item);
                this.emailSendingRules.splice(index, 1);
            },
            // computedType(type) {
            //     if (type === "DAYS_AFTER") return "po";
            //     if (type === "DAYS_BEFORE") return "przed";
            //     if (type === "AT_DEADLINE") return "w dniu";
            //     return "";
            // },
            computedText(item) {
                if (item.type === "DAYS_AFTER") return `co ${item.daysAfter} dni po`;
                if (item.type === "DAYS_BEFORE") return `${item.daysBefore} dni przed`;
                if (item.type === "AT_DEADLINE") return "w dniu";
                return "";
            },
            computedEmailType(type) {
                if (type === "NATIONAL_COORDINATOR_APPROVED_CAST") return "Obsada zatwierdzona przez koordynatora krajowego";
                if (type === "NATIONAL_COORDINATOR_APPROVED_PROGRAM_RESULTS") return "Wyniki programu zatwierdzone przez koordynatora krajowego";
                if (type === "NATIONAL_COORDINATOR_ASSIGNED_TO_PROGRAM") return "Koordynator krajowy przypisany do programu";
                if (type === "REMINDER_TO_ASSIGN_CAST") return "Przypomnienie o przypisaniu obsady";
                if (type === "MAIN_COORDINATOR_APPROVED_CAST") return "Obsada zatwierdzona przez koordynatora głównego";
                if (type === "REMINDER_ABOUT_END_OF_CONTROL_IN_TERRAIN") return "Przypomnienie o zakończeniu kontroli w terenie";
                if (type === "REGIONAL_COORDINATOR_APPROVED_PROGRAM_RESULTS") return "Wyniki programu zatwierdzone przez koordynator regionalnego";
                if (type === "MAIN_COORDINATOR_APPROVED_PROGRAM_RESULTS") return "Wyniki programu zatwierdzone przez koordynatora głównego";
                if (type === "REMINDER_ABOUT_DEADLINE_OF_INSERTING_DATA_TO_DATABASE") return "Przypomnienie o terminie wprowadzania danych do bazy danych";
                if (type === "OBSERVER_SENT_DATA_FOR_VERIFICATION") return "Obserwator przesłał dane do weryfikacji";
                if (type === "REMINDER_ABOUT_RESULT_NATIONAL_APPROVAL_DATE") return "Przypomnienie o dacie zatwierdzenia wyniku na poziomie krajowym";
                if (type === "REMINDER_ABOUT_RESULT_REGIONAL_APPROVAL_DATE") return "Przypomnienie o dacie zatwierdzenia wyniku na poziomie regionalnym";
                if (type === "RETURNED_FORM_FOR_CORRECTION") return "Formularz zwrócony do poprawy";
                if (type === "COORDINATOR_APPROVED_FORM") return "Koordynator zaakceptował formularz";
                return "";
            },
        },

        computed: {
            emailSendingRules: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
