<template>
    <CustomInfoBox v-if="activeTransect.id === 'main2'"
                   text="Wyjście z edycji drugiego transektu przed podzieleniem na odcinki spowoduje jego utratę."
                   class="mb-3" />
    <div class="mb-3 flex justify-content-between align-items-center">
        <span class="text-2xl">
            Długość:
            {{ kilometers ? (activeTransectDistance / 1000).toFixed(2) : activeTransectDistance }}
            {{ kilometers ? " km" : " m" }}
        </span>
        <div v-if="editable">
            <CustomButton v-if="transects.length > 1 && activeTransect.id !== 'main1' && activeTransect.id !== 'main2'"
                          label="Rozłącz odcinki" @click="() => internalConnect = !internalConnect" class="ml-2"
                          :bgColor="!internalConnect ? '#33795B' : 'white'" :color="!internalConnect ? 'white' : ''" />
            <CustomButton v-if="activeTransect.id === 'main1' || activeTransect.id === 'main2'"
                          label="Podziel transekt na odcinki" @click="cutTransect" class="ml-2" />
        </div>
    </div>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" class="map-multi-transects" />
    <div ref="popup" class="marker-popup hidden"></div>
    <div v-if="popup">
        <div v-for="(transect,index) in transects" ref="popup" class="marker-popup" :key="transect.id"
             @mouseover="closePopupOnHover(transect.id)" @focus="closePopupOnHover(transect.id)">
            <slot name="popup" :transect="transect" :index="index"></slot>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    /* eslint no-underscore-dangle: 0 */
    import _ from "lodash";
    import * as turf from "@turf/turf";
    import {toRaw} from "vue";
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInfoBox from "@/components/form/CustomInfoBox.vue";

    export default {
        name: "MapMultiTransectsFormZero",

        components: {
            CustomButton,
            CustomInfoBox,
        },

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            popup: {
                type: Boolean,
                default: false,
            },
            transects: {
                type: Array,
                default: () => [],
            },
            transectsParts: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            changeColorOnHover: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: "red",
            },
            colorOnHover: {
                type: String,
                default: "red",
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            activeTransect: null,
            mapReloader: {
                type: Number,
                default: 1,
            },
            connect: {
                type: Boolean,
                default: true,
            },
            numberOfTransects: {
                type: Number,
                default: 10,
            },
        },

        data() {
            return {
                markersCoordinates: [],
                redCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='red' /></svg>",
                greyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='grey' /></svg>",
                greenCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='green' /></svg>",
                lightGreyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='lightgrey' /></svg>",
                redPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='red' /></svg>",
                greyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='grey' /></svg>",
                greenPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='green' /></svg>",
                lightGreyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='lightgrey' /></svg>",
                redSquare: "<svg height='10' width='10'><rect width='10' height='10' fill='red' /></svg>",
                greySquare: "<svg height='10' width='10'><rect width='10' height='10' fill='grey' /></svg>",
                fieldOnMap: [],
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                currentPositionsOnMap: new Map(),
                timer: [],
                polylines: new Map(),
                markers: new Map(),
                polyline: null,
                latlngs: [],
                fieldShape: "",
                cutedTransectPoints: [],
                activeTransectDistance: 0,
                transectsClone: null,
                kilometers: false,
            };
        },

        emits: ["onClick", "onHover", "update:transects", "update:activeTransect", "update:transectsParts", "changeTransect",
            "getLineIndex", "update:connect", "checkTransectsToConnect", "clearSecondMainTransectLine"],

        mounted() {
            this.initCustomIdMap(this.mapId);
            L.Popup.prototype._animateZoom = function (e) {
                if (!this._map) {
                    return;
                }
                const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
                const anchor = this._getAnchor();
                L.DomUtil.setPosition(this._container, pos.add(anchor));
            };
            L.Marker.prototype._animateZoom = function (e) {
                if (!this._map) {
                    return;
                }
            };
            this.addZoomControls();
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            this.makeTransects();
            if (this.editable) {
                this.map.on("click", (e) => {
                    if (this.internalActiveTransect) {
                        const coordinates = {
                            latitude: e.latlng.lat,
                            longitude: e.latlng.lng,
                        };
                        this.internalActiveTransect.line.push({...coordinates});
                    }
                });
            }
            window.addEventListener("keydown", (e) => {
                if (this.internalActiveTransect && e.key === "Escape") {
                    this.$emit("onClick", null);
                }
            });
            this.polyline = this.polylines.get(this.internalActiveTransect.id);
            this.updateSingleTransect();
            if (this.mapReloader > 1) {
                this.updateTransects(this.transects);
            }
            this.changeFirstLastMarker();
        },

        methods: {
            closePopupOnHover(transectId) {
                if (this.markers.has(transectId)) {
                    const marker = this.markers.get(transectId);
                    marker.closePopup();
                    setTimeout(() => {
                        marker.openPopup();
                    }, 3000);
                }
            },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                const fieldShape = L.polygon(this.fieldOnMap);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeTransects() {
                this.transects.forEach((transect, index) => {
                    if (transect.line?.length > 0) {
                        this.makeTransect(transect, index);
                    }
                });
            },
            makeTransect(transect, index) {
                const polyline = this.makePolyline(transect, index);
                transect.line.forEach((line, i) => {
                    if (i === 0 || i === transect.line.length - 1) {
                        this.makeSingleTransectCircle(line, index, i, transect.id);
                    }
                });
                if (transect.id !== "main1" && transect.id !== "main2") {
                    this.makePopup(transect, index, polyline);
                }
            },
            makePopup(transect, index, polyline) {
                const centerMarker = this.getPointMarker(transect.id);
                const center = polyline.getCenter();
                const marker = L.marker([center.lat, center.lng], centerMarker).addTo(this.map);
                const popup = L.popup({autoPan: false}).setContent(this.$refs.popup[index]);
                marker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                this.markers.set(transect.id, marker);
            },
            makePolyline(transect, index) {
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                const color = transect.color != null ? transect.color : "red";
                const p = L.polyline(latlngs, {color}).addTo(this.map);
                p.on("click", (e) => { L.DomEvent.stopPropagation(e); this.$emit("onClick", index); });
                p.on("mouseover", () => { this.$emit("onHover", index); });
                p.on("mouseout", () => { this.$emit("onHover", null); });
                this.polylines.set(transect.id, p);
                return p;
            },
            updateSingleTransect() {
                if (this.internalActiveTransect.line.length > 1) {
                    this.updateSingleTransectPolyline();
                }
                if (this.internalActiveTransect.line.length > 0) {
                    this.updateSingleTransectCircles();
                    this.markers = new Map([...this.markers.entries()].sort());
                }
                if (this.internalActiveTransect.line.length < 1) {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === this.internalActiveTransect.id) {
                            index = i;
                        }
                    });
                    if (this.markers.has(`${index}.0`)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Marker) {
                                if (_.isEqual(this.markers.get(`${index}.0`).getLatLng(), layer.getLatLng())) {
                                    layer.removeFrom(this.map);
                                }
                            }
                        });
                        this.markers.delete(`${index}.0`);
                    }
                }
            },
            updateSingleTransectCircles(transect = this.internalActiveTransect) {
                let index;
                this.transects.forEach((t, i) => {
                    if (t.id === transect.id) {
                        index = i;
                        transect.line.forEach((l, j) => {
                            if (this.markers.has(`${i}.${j}`)) {
                                const marker = this.markers.get(`${i}.${j}`);
                                marker.setZIndexOffset(9999);
                            }
                        });
                    } else {
                        t.line.forEach((l, j) => {
                            if (this.markers.has(`${i}.${j}`)) {
                                const marker = this.markers.get(`${i}.${j}`);
                                marker.setZIndexOffset(99);
                                if (marker.dragging) {
                                    marker.dragging.disable();
                                }
                            }
                        });
                    }
                });
                if (transect.line.length < 2) {
                    if (this.polylines.has(transect.id)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Polyline) {
                                if (_.isEqual(layer, this.polylines.get(transect.id))) {
                                    layer.removeFrom(this.map);
                                    this.polylines.delete(transect.id);
                                }
                            }
                        });
                    }
                }
                transect.line.forEach((line, i) => {
                    if (this.markers.has(`${index}.${i + 1}`)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Marker) {
                                if (_.isEqual(this.markers.get(`${index}.${i + 1}`).getLatLng(), layer.getLatLng())
                                    && _.isEqual(this.markers.get(`${index}.${i + 1}`).options.id, layer.options.id)) {
                                    layer.removeFrom(this.map);
                                }
                            }
                        });
                        this.markers.delete(`${index}.${i + 1}`);
                    }
                    if (line.longitude != null && line.latitude != null) {
                        if (this.markers.has(`${index}.${i}`)) {
                            const marker = this.markers.get(`${index}.${i}`);
                            if (marker.dragging) {
                                marker.dragging.enable();
                            } else {
                                this.makeSingleTransectCircle(line, index, i, transect.id);
                            }
                            marker.setLatLng([line.latitude, line.longitude]);
                        } else {
                            this.makeSingleTransectCircle(line, index, i, transect.id);
                        }
                    }
                });
                if (transect.line.length < 1 && this.markers.has(`${index}.0`)) {
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (_.isEqual(this.markers.get(`${index}.0`).getLatLng(), layer.getLatLng())) {
                                layer.removeFrom(this.map);
                            }
                        }
                    });
                    this.markers.delete(`${index}.${0}`);
                }
            },
            updateSingleTransectPolyline() {
                let polyline;
                if (this.polylines.has(this.internalActiveTransect.id)) {
                    polyline = this.polylines.get(this.internalActiveTransect.id);
                } else {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === this.internalActiveTransect.id) {
                            index = i;
                        }
                    });
                    polyline = this.makePolyline(this.internalActiveTransect, index);
                }
                let color = this.internalActiveTransect.color != null ? this.internalActiveTransect.color : "red";
                polyline.setStyle({color});
                this.transects.forEach((t) => {
                    if (t.id !== this.internalActiveTransect.id) {
                        const polylineOthers = this.polylines.get(t.id);
                        color = "grey";
                        if (polylineOthers) {
                            polylineOthers.setStyle({color});
                        }
                    }
                });
                this.latlngs = this.internalActiveTransect.line.map((line) => {
                    if (line.latitude !== null && line.longitude !== null) {
                        return [line.latitude, line.longitude];
                    }
                    return [];
                });
                if (this.latlngs.length > 0 && this.polyline) {
                    this.polyline.setLatLngs(this.latlngs);
                }
            },
            makeSingleTransectCircle(line, index, i, transectId) {
                const color = this.internalActiveTransect.color != null ? this.internalActiveTransect.color : "red";
                const markerConfig = this.getMarker(i, color, 1, transectId);
                const marker = L.marker([line.latitude, line.longitude], markerConfig).addTo(this.map);
                this.markers.set(`${index}.${i}`, marker);
                if (this.editable) {
                    marker.dragging.enable();
                    marker.on("drag", (event) => {
                        this.$emit("getLineIndex", i);
                        if (this.internalConnect) {
                            this.markers.forEach((m) => {
                                // console.log(_.isEqual(marker.getLatLng(), m.getLatLng()));
                                // if (_.isEqual(marker.getLatLng(), m.getLatLng()) && m.options.transectId !== marker.options.transectId) console.log(m);
                                // console.log(m);
                                // if (m.options.id === 5) console.log(this.map.distance([marker.getLatLng().lat, marker.getLatLng().lng], [m.getLatLng().lat, m.getLatLng().lng]));
                                if (m.options.transectId !== marker.options.transectId && this.map.distance([marker.getLatLng().lat, marker.getLatLng().lng], [m.getLatLng().lat, m.getLatLng().lng]) < 15) {
                                    marker.setLatLng(m.getLatLng());
                                    this.$emit("checkTransectsToConnect", true);
                                }
                            });
                        }
                        this.latlngs[i] = [event.target._latlng.lat, event.target._latlng.lng];
                        if (this.polyline) {
                            this.polyline.setLatLngs(this.latlngs);
                        }
                    });
                    marker.on("dragend", () => {
                        this.internalActiveTransect.line[i] = {
                            latitude: this.latlngs[i][0],
                            longitude: this.latlngs[i][1],
                        };
                        if (i === 0 && index > 0 && this.internalActiveTransect.id !== "main2") {
                            this.updateSingleTransectCircles(this.transects[index - 1]);
                        } else if (i > 0 && index < this.transects.length - 1) {
                            this.updateSingleTransectCircles(this.transects[index + 1]);
                        }
                        this.internalConnect = true;
                    });
                }
                marker.setZIndexOffset(9999);
            },
            updateTransects(transects) {
                transects.forEach((transect, index) => {
                    if (transect.line.length > 0) {
                        this.updateTransect(transect, index);
                    }
                });
            },
            /* eslint-disable-next-line */
            updateTransect(transect, index) {
                if (transect.line.length > 0) {
                    this.updateCircles(transect, index);
                }
                if (transect.line.length >= 2) {
                    this.updatePolyline(transect);
                    if (transect.id !== "main1" && transect.id !== "main2") {
                        this.updatePopup(transect, index);
                    }
                }
            },
            updatePopup(transect, index) {
                const centerMarker = this.markers.get(transect.id);
                let polyline;
                if (this.polylines.has(transect.id)) {
                    polyline = this.polylines.get(transect.id);
                }
                if (!centerMarker) {
                    this.makePopup(transect, index, polyline);
                } else {
                    const center = polyline.getCenter();
                    centerMarker.setLatLng([center.lat, center.lng]);
                }
            },
            updatePolyline(transect) {
                let polyline;
                if (this.polylines.has(transect.id)) {
                    polyline = this.polylines.get(transect.id);
                } else {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === transect.id) {
                            index = i;
                        }
                    });
                    polyline = this.makePolyline(transect, index);
                }
                const color = transect.color != null ? transect.color : "red";
                polyline.setStyle({color});
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                polyline.setLatLngs(latlngs);
            },
            updateCircles(transect, index) {
                let internalActiveTransectIndex;
                this.transects.forEach((t, i) => {
                    if (t.id === this.internalActiveTransect?.id) {
                        internalActiveTransectIndex = i;
                    }
                });
                transect.line.forEach((l, i) => {
                    if (i !== 0 && i !== transect.line.length - 1) {
                        if (this.markers.has(`${index}.${i}`) && internalActiveTransectIndex !== index) {
                            const marker = this.markers.get(`${index}.${i}`);
                            if (this.map.hasLayer(toRaw(marker))) {
                                this.map.removeLayer(toRaw(marker));
                                this.markers.delete(`${index}.${i}`);
                            }
                        }
                    } else if (this.markers.has(`${index}.${i}`) && internalActiveTransectIndex !== index) {
                        const marker = this.markers.get(`${index}.${i}`);
                        const color = transect.color != null ? transect.color : "red";
                        if (color === "red") {
                            marker.setZIndexOffset(99999);
                        } else {
                            marker.setZIndexOffset(99);
                        }
                        const icon = this.getIcon(color, null);
                        marker.setIcon(icon);
                    }
                });
            },
            getPointMarker(id) {
                // eslint-disable-next-line
                const icon = L.divIcon({
                    html: this.redPoint,
                    iconSize: [1, 1],
                    iconAnchor: [0.5, 0.5],
                    popupAnchor: [0, 0],
                });
                return {
                    icon,
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getMarker(id, color, zIndexOffset = 1, transectId) {
                return {
                    icon: this.getIcon(color, id),
                    bubblingMouseEvents: false,
                    zIndexOffset,
                    draggable: this.isOn,
                    id,
                    transectId,
                    dontHidePopup: false,
                };
            },
            getIcon(color, id, endMarker = false) {
                const end = this.activeTransect.line.length - 1;
                let icon = endMarker ? this.redSquare : this.redCircle;
                if ((this.activeTransect.id === "main1" || this.activeTransect.id === "main2")
                    && (id === 0 || id === end)) {
                    icon = this.greenCircle;
                }
                else if (color === "red") icon = endMarker ? this.redSquare : this.redCircle;
                else if (color === "grey") icon = endMarker ? this.greySquare : this.greyCircle;
                else if (color === "lightGrey") icon = this.lightGreyCircle;
                else if (color === "green") icon = this.greenPoint;
                // eslint-disable-next-line
                return new L.divIcon({
                    html: icon,
                    iconSize: [12, 12],
                    iconAnchor: [5, 7.5],
                    popupAnchor: [1, -34],
                });
            },

            calculateDistance(line) {
                let distance = 0;
                if (line.length > 1) {
                    line.forEach((l, index) => {
                        if (index > 0) {
                            distance += this.map.distance(
                                [l.latitude, l.longitude],
                                [line[index - 1].latitude, line[index - 1].longitude],
                            );
                        }
                    });
                }
                if (distance < 1000) {
                    this.activeTransectDistance = Math.round((distance + Number.EPSILON) * 100) / 100;
                    this.kilometers = false;
                } else {
                    this.activeTransectDistance = Math.round((distance + Number.EPSILON) * 100) / 100;
                    this.kilometers = true;
                }
            },
            calculateDistanceToSort() {
                this.cutedTransectPoints.forEach((nP) => {
                    for (let i = 0; i < this.internalActiveTransect.line.length; i += 1) {
                        if (i + 1 <= this.internalActiveTransect.line.length - 1) {
                            const distTwoTrP = this.map.distance(
                                [
                                    this.internalActiveTransect.line[i].latitude,
                                    this.internalActiveTransect.line[i].longitude,
                                ],
                                [
                                    this.internalActiveTransect.line[i + 1].latitude,
                                    this.internalActiveTransect.line[i + 1].longitude,
                                ],
                            );
                            const distTrNewP1 = this.map.distance(
                                [
                                    this.internalActiveTransect.line[i].latitude,
                                    this.internalActiveTransect.line[i].longitude,
                                ],
                                [nP[1], nP[0]],
                            );
                            const distTrNewP2 = this.map.distance(
                                [nP[1], nP[0]],
                                [
                                    this.internalActiveTransect.line[i + 1].latitude,
                                    this.internalActiveTransect.line[i + 1].longitude,
                                ],
                            );
                            if (Math.round(distTwoTrP) === Math.round(distTrNewP1 + distTrNewP2)) {
                                this.internalActiveTransect.line.splice(
                                    i + 1,
                                    0,
                                    {latitude: nP[1], longitude: nP[0]},
                                );
                                break;
                            }
                        }
                    }
                });
                this.transformTransectToParts();
            },
            cutTransect() {
                if (this.internalActiveTransect.line.length > 1) {
                    const coordsArrayTurf = [];
                    this.internalActiveTransect.line.forEach((l) => {
                        coordsArrayTurf.push([l.longitude, l.latitude]);
                    });
                    let parts = this.numberOfTransects >= 5 ? 5 : this.numberOfTransects
                    const a = this.activeTransectDistance / parts;
                    const lineString = turf.lineString(coordsArrayTurf);
                    for (let i = 1; i < parts; i += 1) {
                        const cutLine = turf.along(lineString, a * i, {units: "meters"});
                        this.cutedTransectPoints.push(cutLine.geometry.coordinates);
                    }
                }
            },
            transformTransectToParts() {
                let newIndex = 0;
                const helpLine = [];
                this.cutedTransectPoints.forEach((t, idx) => {
                    this.internalActiveTransect.line.forEach((tr, index) => {
                        if (t[1] === tr.latitude) {
                            helpLine.push(this.internalActiveTransect.line.slice(newIndex, index + 1));
                            newIndex = index;
                            if (idx === this.cutedTransectPoints.length - 1) {
                                helpLine.push(this.internalActiveTransect.line.slice(index));
                            }
                        }
                    });
                });
                if (this.cutedTransectPoints.length > 0) {
                    if (this.mapReloader === 1) {
                        for (let i = 0; i < this.numberOfTransects; i += 1) {
                            if (helpLine[i]) {
                                this.internalTransectsParts[i] = {id: i + 1, name: `${i + 1}`, line: helpLine[i]};
                            }
                        }
                    }
                    if (this.mapReloader === 2 && this.numberOfTransects > 5) {
                        for (let i = 5; i < 10; i += 1) {
                            if (helpLine[i - 5]) {
                                this.internalTransectsParts[i] = {id: i + 1, name: `${i + 1}`, line: helpLine[i - 5]};
                            }
                        }
                    }
                }
            },
            changeFirstLastMarker() {
                this.transects.forEach((t) => {
                    if (t.id !== "main2" && t.id !== "main1") {
                        for (let i = 0; i < t.line.length; i += 1) {
                            if (i === 0 || i === t.line.length - 1) {
                                this.map.eachLayer((layer) => {
                                    if (layer instanceof L.Marker) {
                                        if (_.isEqual(layer.getLatLng(), {lat: t.line[i].latitude, lng: t.line[i].longitude}) && layer.options.transectId === t.id) {
                                            const icon = this.getIcon(t.color, null, true);
                                            layer.setIcon(icon);
                                        }
                                    }
                                });
                            } else {
                                this.map.eachLayer((layer) => {
                                    if (layer instanceof L.Marker) {
                                        if (_.isEqual(layer.getLatLng(), {lat: t.line[i].latitude, lng: t.line[i].longitude})) {
                                            const icon = this.getIcon(t.color, null, false);
                                            layer.setIcon(icon);
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
            },
        },

        computed: {
            internalActiveTransect: {
                get() { return this.activeTransect; },
                set(value) { this.$emit("update:activeTransect", value); },
            },
            internalTransectsParts: {
                get() { return this.transectsParts; },
                set(value) { this.$emit("update:transectsParts", value); },
            },
            internalConnect: {
                get() { return this.connect; },
                set(value) { this.$emit("update:connect", value) },
            },
        },

        watch: {
            internalConnect(value) {
                this.$emit("update:connect", value)
            },
            internalActiveTransect: {
                handler(value) {
                    if (value.id !== "main2") {
                        this.$emit("clearSecondMainTransectLine");
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Polyline) {
                                if (_.isEqual(layer, this.polylines.get("main2"))) {
                                    layer.removeFrom(this.map);
                                    this.polylines.delete("main2");
                                }
                            }
                        });
                    }
                    if (value) {
                        this.updateTransects(this.transects);
                        this.polyline = this.polylines.get(value.id);
                        this.polyline?.bringToFront();
                        this.updateSingleTransect();
                        this.calculateDistance(value.line);
                        this.$emit("update:activeTransect", value);
                    }
                },
                deep: true,
            },
            cutedTransectPoints: {
                handler() {
                    this.calculateDistanceToSort();
                },
                deep: true,
            },
            internalTransectsParts: {
                handler(value) {
                    this.$emit("update:transectsParts", value);
                    if (this.mapReloader === 2) {
                        this.$emit("changeTransect");
                    }
                    if (this.mapReloader === 1) {
                        this.$emit("changeTransect");
                    }
                },
                deep: true,
            },
            transects: {
                handler(value) {
                    if (this.activeTransect.id !== "main1" && this.activeTransect.id !== "main2") {
                        if (this.transectsClone?.length > value.length) {
                            this.map.eachLayer((layer) => {
                                if (layer instanceof L.Marker) {
                                    layer.removeFrom(this.map);
                                }
                                this.transectsClone.forEach((oV) => {
                                    if (layer instanceof L.Polyline) {
                                        if (_.isEqual(layer, this.polylines.get(oV.id))) {
                                            layer.removeFrom(this.map);
                                            this.polylines.delete(oV.id);
                                        }
                                    }
                                });
                            });
                            this.makeTransects();
                        } else {
                            this.updateTransects(value);
                            const arr = [];
                            value.forEach((v) => {
                                v.line.forEach((l) => {
                                    arr.push({lat: l.latitude, lng: l.longitude});
                                });
                            });
                            if (this.markers.size > arr.length) {
                                this.markers.forEach((m) => {
                                    if (!_.find(arr, m.getLatLng()) && !m._popup) {
                                        this.map.removeLayer(m);
                                        for (const [key, value] of this.markers.entries()) {
                                            if (value === m) {
                                                this.markers.delete(key);
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        this.changeFirstLastMarker();
                    }
                    this.transectsClone = _.cloneDeep(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/scss/leaflet-map";
@import "../../assets/theme/mytheme/variables";
.map-multi-transects .leaflet-popup .leaflet-popup-content {
    .marker-popup {
        &-header {
            padding: 5px;
        }
        &-content {
            padding: 5px;
        }
    }
    .marker-popup-full {
        &-header {
            padding: 15px;
        }
        &-content {
            padding: 15px;
        }
    }
}
</style>
