<template>
    <div v-if="loaded >= 2" class="px-3">
        <Toast/>
        <DictDetailsDataTemplate :item="geobject" :itemHistory="geobjectHistory" :name="name"
                                 :biernik="biernik" :dopelniacz="dopelniacz" :hasHabitatData="geobject.programHasHabitatData"
                                 :hasSubfields="geobject.programHasSubfields" :createGeobject="createGeobject"
                                 @deleteItemHistory="deleteGeobjectHistory" @exit="exit"
                                 @acceptItemHistory="onAcceptGeobjectHistory" @acceptItem="onAcceptGeobject" @saveItem="saveGeobject"
                                 @reloadItemData="onGetGeobjectData" @editItemHistory="editGeobjectHistory"
                                 @createItemHistory="onCreateGeobjectHistory" @selectHistoryToShow="selectHistoryToShow">
            <template v-slot:map>
                <MapMultiTransectsFormZero v-if="name === 'transect'"
                                           mapId="transectsMap" height="400px" :field="shape" popup :editable="false"
                                           :transects="coloredTransects" @onClick="onClickTransect" @onHover="onHoverTransect"
                                           v-model:activeTransect="geobject" :isOn="false">
                    <template v-slot:popup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.transect.name }}
                        </div>
                    </template>
                </MapMultiTransectsFormZero>
                <MapMultiMarkers v-else
                                 mapId="geobjectsMap" height="350px" :zoomControls="false" :positions="geobjectHistory"
                                 :field="shape" :markerTypes="markerTypes" :isOn="false" idField="id"/>
            </template>
        </DictDetailsDataTemplate>
        <EditGeobjectHistoryDialog v-if="name !== 'field' && editGeobjectHistoryDialogVisible"
                                   v-model:visible="editGeobjectHistoryDialogVisible" :create="createGeobjectHistory"
                                   v-model:item="geobjectHistoryToEdit" :field="shape"
                                   @reloadHistory="onGetGeobjectData" :dopelniacz="dopelniacz" :name="name"
                                   :createHistoryFunction="createGeobjectHistoryFunction"
                                   :updateHistoryFunction="updateGeobjectHistoryFunction"
                                   :getAvailableControlVersionsFunction="getAvailableControlVersionsFunction"/>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import DictDetailsDataTemplate from "@/views/dict/DictDetailsDataTemplate.vue";
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import EditGeobjectHistoryDialog from "@/views/dict/EditGeobjectHistoryDialog.vue";
    import MapMultiTransectsFormZero from "@/components/map/MapMultiTransectsFormZero.vue";

    export default {
        name: "DictDetailsViewTemplate",

        components: {
            MapMultiTransectsFormZero,
            EditGeobjectHistoryDialog,
            MapMultiMarkers,
            Toast,
            DictDetailsDataTemplate,
        },

        props: {
            acceptGeobject: {
                type: Function,
                required: true,
            },
            acceptGeobjectHistory: {
                type: Function,
                default: () => {},
            },
            getGeobjectData: {
                type: Function,
                required: true,
            },
            createGeobjectFunction: {
                type: Function,
                required: true,
            },
            updateGeobjectFunction: {
                type: Function,
                required: true,
            },
            createGeobjectHistoryFunction: {
                type: Function,
                default: () => {},
            },
            updateGeobjectHistoryFunction: {
                type: Function,
                default: () => {},
            },
            deleteGeobjectHistoryFunction: {
                type: Function,
                default: () => {},
            },
            getAvailableControlVersionsFunction: {
                type: Function,
                default: () => {},
            },
            name: {
                type: String,
                required: true,
            },
            biernik: {
                type: String,
                required: true,
            },
            dopelniacz: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                createGeobject: false,
                geobject: null,
                geobjectHistory: [],
                otherTransects: [],
                loaded: 0,
                createGeobjectHistory: false,
                geobjectHistoryToEdit: null,
                editGeobjectHistoryDialogVisible: false,
                markerTypes: new Map(),
                shape: [],
                coloredTransects: [],
                color: "grey",
                colorClickedOrHover: "red",
            };
        },

        beforeMount() {
            if (this.$route.name.substring(0, 6) === "create") {
                this.createGeobject = true;
                this.geobject = EmptyStructures.getEmptyDictGeobject();
                this.geobjectHistory = [];
                this.loaded = 2;
            } else {
                this.onGetGeobjectData();
            }
        },

        methods: {
            onGetGeobjectData() {
                this.getGeobjectData({ id: this.geobjectId })
                    .then((response) => {
                        console.log(response.data);
                        if (this.name === "transect") {
                            this.geobject = response.data.transect;
                            this.copyLocationHistoryDataIntoObject(this.geobject);
                            this.otherTransects = response.data.otherTransects;
                            this.otherTransects.forEach((ot) => this.copyLocationHistoryDataIntoObject(ot));
                        } else {
                            this.geobject = response.data;
                        }
                        if (this.name !== "field") {
                            this.geobject.program = this.geobject.field?.program;
                            this.geobject.fieldId = this.geobject.field?.id;
                            this.geobject.subfieldId = this.geobject.subfield?.id;
                            if (this.geobject.owner != null) {
                                this.geobject.ownerId = this.geobject.owner.id;
                                this.geobject.owner = this.geobject.owner.name;
                            }
                            if (this.geobject.histories != null) {
                                this.geobjectHistory = this.geobject.histories;
                                this.geobjectHistory.forEach((h) => {
                                    h.year = new Date(h.year.toString());
                                    if (h.coordinates == null) {
                                        h.coordinates = {latitude: null, longitude: null};
                                    }
                                });
                                if (this.name === "transect") {
                                    this.makeColoredTransects(0);
                                } else {
                                    this.setMarkerTypes(0);
                                }
                            }
                        }
                        this.updateFieldShape();
                        this.loaded += 2;
                    });
            },
            copyLocationHistoryDataIntoObject(object, index = 0) {
                if (object.histories != null && object.histories.length > index) {
                    object.line = object.histories[index].line;
                    object.geography = object.histories[index].geography;
                } else {
                    object.line = [];
                    object.geography = {};
                }
            },
            updateFieldShape() {
                if (this.name === "field") {
                    this.shape = this.geobject.shape;
                } else if (this.geobject != null) {
                    this.shape = this.geobject.field?.shape;
                }
            },
            deleteGeobjectHistory(geobjectHistoryId) {
                this.deleteGeobjectHistoryFunction({ id: geobjectHistoryId })
                    .then(() => { this.onGetGeobjectData(); });
            },
            onAcceptGeobject() {
                this.acceptGeobject({id: this.geobjectId})
                    .then(() => { this.onGetGeobjectData(); });
            },
            onAcceptGeobjectHistory(geobjectHistory) {
                this.acceptGeobjectHistory({id: geobjectHistory.id})
                    .then(() => { this.onGetGeobjectData(); });
            },
            saveGeobject(exit) {
                const request = {
                    name: this.geobject.name,
                };
                if (this.name !== "field") request.fieldId = this.geobject.fieldId;
                if (this.geobject.subfieldId != null) {
                    request.fieldId = this.geobject.subfieldId;
                }
                if (this.geobject.superFieldId != null) request.superFieldId = this.geobject.superFieldId;
                if (this.name === "position") {
                    request.commonName = this.geobject.commonName;
                    request.speciesId = this.geobject.speciesId;
                    request.ownerId = this.geobject.ownerId;
                    request.status = (this.geobject.status == null || this.geobject.status.key == null) ? "ACTIVE" : this.geobject.status.key;
                } else if (this.name === "field") {
                    request.includeInMZPWP = this.geobject.includeInMZPWP;
                    request.program = this.geobject.program;
                } else {
                    request.order = this.geobject.order;
                }

                let apiMethod = this.updateGeobjectFunction;
                const parameters = {body: request};
                if (this.createGeobject) apiMethod = this.createGeobjectFunction;
                else parameters.id = this.geobjectId;

                console.log(request);

                apiMethod(parameters)
                    .then((response) => {
                        if (exit) {
                            this.$router.push({ name: `${this.name}DictView`, query: {saveCorrectly: true} });
                        } else {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zmiany zostały zapisane",
                                life: 2000,
                            });
                            if (this.createGeobject) {
                                this.$router.replace({path: `/${this.name}/${response.data}`});
                            } else {
                                this.onGetGeobjectData();
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response.data == null ? "Nie udało się zapisać zmian" : error.response.data,
                            life: 3000,
                        });
                    });
            },
            exit() {
                this.$router.push({ name: `${this.name}DictView` });
            },
            editGeobjectHistory(item) {
                this.createGeobjectHistory = false;
                this.geobjectHistoryToEdit = item;
                this.editGeobjectHistoryDialogVisible = true;
            },
            onCreateGeobjectHistory() {
                this.createGeobjectHistory = true;
                this.geobjectHistoryToEdit = EmptyStructures.getEmptyDictGeobject();
                this.editGeobjectHistoryDialogVisible = true;
            },
            selectHistoryToShow(selected) {
                if (this.name === "transect") {
                    this.makeColoredTransects(selected);
                } else {
                    this.setMarkerTypes(selected);
                }
            },
            setMarkerTypes(selected) {
                // creates actual map which keeps for every geobjectId an array with icon color and icon size
                this.geobjectHistory.forEach((h, index) => {
                    if (index === selected) {
                        this.markerTypes.set(h.id, {
                            color: "red",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    } else {
                        this.markerTypes.set(h.id, {
                            color: "grey",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
            makeColoredTransects(selected) {
                this.coloredTransects = [];
                this.otherTransects.forEach((t) => {
                    this.coloredTransects.push({
                        ...t,
                        color: this.color,
                        openPopup: false,
                        zIndexOffset: 1,
                    });
                });
                this.copyLocationHistoryDataIntoObject(this.geobject, selected);
                this.coloredTransects.push({
                    ...this.geobject,
                    color: this.colorClickedOrHover,
                    openPopup: true,
                    zIndexOffset: 1000,
                });
                this.activeIndex = this.coloredTransects.length - 1;
            },
            onClickTransect(index) {
                this.$router.push(`/transect/${this.coloredTransects[index].id}`);
            },
            onHoverTransect(index) {
                if (index !== this.hoverIndex && index !== this.activeIndex) {
                    if (this.hoverIndex != null) {
                        this.coloredTransects[this.hoverIndex].color = this.color;
                        this.coloredTransects[this.hoverIndex].openPopup = false;
                        this.coloredTransects[this.hoverIndex].zIndexOffset = 1;
                    }
                    if (index != null) {
                        this.coloredTransects[index].color = this.colorClickedOrHover;
                        this.coloredTransects[index].openPopup = true;
                        this.coloredTransects[index].zIndexOffset = 1000;
                    }
                    this.hoverIndex = index;
                }
            },
        },

        computed: {
            geobjectId() { return this.$route.params.id; },
        },

        watch: {
            "geobject.shape": {
                handler() {
                    this.updateFieldShape();
                },
                deep: true,
            },
        },
    };
</script>
