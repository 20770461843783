<template>
    <div class="p-3">
        <BirdsDictSearchCriteria v-model="searchCriteria" @update:modelValue="search" />
        <BirdsDictTable ref="birdsDictTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import BirdsDictTable from "./BirdsDictTable.vue";
    import BirdsDictSearchCriteria from "./BirdsDictSearchCriteria.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "BirdsDictView",

        components: {
            BirdsDictTable,
            BirdsDictSearchCriteria,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    ident1: "",
                    ident2: "",
                    identEuring: null,
                    namePl: "",
                    nameEn: "",
                    nameLat: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            search() {
                this.$refs.birdsDictTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
