<template>
    <div class="menu-wrapper mt-2 sm:mt-6 relative">
        <PanelMenu :model="menuItems" class="menu-container">
            <template #item="{ item }">
                <router-link v-if="item.to" v-slot="{ href,navigate }" :to="item.to" custom>
                    <a class="flex align-items-center cursor-pointer text-color p-3 justify-content-between w-full" :href="href" @click="navigate">
                        <span class="ml-2 text-color font-semibold">{{ item.label }}</span>
                    </a>
                </router-link>
                <a v-else class="flex align-items-center cursor-pointer text-color p-3 justify-content-between w-full" :href="item.url" :target="item.target" @click="openCloseSubmenu(item)">
                    <span class="ml-2 font-semibold">{{ item.label }}</span>
                    <span v-if="item.items && item.items.length > 0 && !item.arrowUp" class="pi pi-chevron-down"></span>
                    <span v-if="item.items && item.items.length > 0 && item.arrowUp" class="pi pi-chevron-up"></span>
                </a>
            </template>
        </PanelMenu>
        <!-- <PanelMenu :model="menuItems" class="menu-container" /> -->
        <button class="p-link layout-menu-button" @click="onMenuToggle" type="button">
            <span class="pi pi-chevron-left"></span>
        </button>
    </div>
</template>

<script>
    import PanelMenu from "primevue/panelmenu";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "AppMenu",

        components: {PanelMenu},

        emits: ["menu-toggle"],

        data() {
            return {
                systemRole: SystemRole.getUserRole(),
                items: [
                    // {
                    //     label: this.$t("message.menu.monitoringCycles"),
                    //     icon: "",
                    //     to: "",
                    //     roles: [SystemRole.ADMIN],
                    // },
                    // {
                    //     label: this.$t("message.menu.monitoring"),
                    //     icon: "",
                    //     to: "",
                    // },
                    {
                        label: this.$t("message.menu.main"),
                        icon: "",
                        to: "/home",
                    },
                    {
                        label: this.$t("message.menu.programs"),
                        icon: "",
                        to: "/programs-list",
                    },
                    {
                        label: this.$t("message.menu.fileArchive"),
                        icon: "",
                        to: "/file-archive",
                        roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                    },
                    {
                        label: this.$t("message.menu.dictionaries"),
                        icon: "",
                        to: "",
                        items: [
                            {
                                label: this.$t("message.menu.programs"),
                                icon: "",
                                to: "/program",
                            },
                            {
                                label: this.$t("message.menu.positions"),
                                icon: "",
                                to: "/position",
                            },
                            {
                                label: this.$t("message.menu.transects"),
                                icon: "",
                                to: "/transect",
                            },
                            {
                                label: this.$t("message.menu.points"),
                                icon: "",
                                to: "/point",
                            },
                            {
                                label: this.$t("message.menu.fields"),
                                icon: "",
                                to: "/field",
                            },
                            {
                                label: this.$t("message.menu.selectLists"),
                                icon: "",
                                to: "",
                                items: [
                                    {
                                        label: this.$t("message.menu.activity"),
                                        icon: "",
                                        to: "/dicts/activity",
                                    },
                                    {
                                        label: this.$t("message.menu.humanActivity"),
                                        icon: "",
                                        to: "/dicts/humanActivity",
                                    },
                                    {
                                        label: this.$t("message.menu.reaction"),
                                        icon: "",
                                        to: "/dicts/reaction",
                                    },
                                    {
                                        label: this.$t("message.menu.rain"),
                                        icon: "",
                                        to: "/dicts/rain",
                                    },
                                    {
                                        label: this.$t("message.menu.dominantVegetation"),
                                        icon: "",
                                        to: "/dicts/dominantVegetation",
                                    },
                                    {
                                        label: this.$t("message.menu.treeBushesArea"),
                                        icon: "",
                                        to: "/dicts/treeBushesArea",
                                    },
                                    {
                                        label: this.$t("message.menu.nestingCategory"),
                                        icon: "",
                                        to: "/dicts/nestingCategory",
                                    },
                                    {
                                        label: this.$t("message.menu.distance"),
                                        icon: "",
                                        to: "/dicts/distance",
                                    },
                                    {
                                        label: this.$t("message.menu.clumps"),
                                        icon: "",
                                        to: "/dicts/clumps",
                                    },
                                    {
                                        label: this.$t("message.menu.whenEnum"),
                                        icon: "",
                                        to: "/dicts/when",
                                    },
                                    {
                                        label: this.$t("message.menu.rut"),
                                        icon: "",
                                        to: "/dicts/rut",
                                    },
                                    {
                                        label: this.$t("message.menu.control"),
                                        icon: "",
                                        to: "/dicts/controlType",
                                    },
                                    {
                                        label: this.$t("message.menu.nestingCriteria"),
                                        icon: "",
                                        to: "/dicts/nestingCriteria",
                                    },
                                    {
                                        label: this.$t("message.menu.controlMethod"),
                                        icon: "",
                                        to: "/dicts/controlMethod",
                                    },
                                    {
                                        label: this.$t("message.menu.breedingResult"),
                                        icon: "",
                                        to: "/dicts/breedingResult",
                                    },
                                    {
                                        label: this.$t("message.menu.countingMethod"),
                                        icon: "",
                                        to: "/dicts/countingMethod",
                                    },
                                    {
                                        label: this.$t("message.menu.nestPlace"),
                                        icon: "",
                                        to: "/dicts/nestPlace",
                                    },
                                    {
                                        label: this.$t("message.menu.lossesCircumstances"),
                                        icon: "",
                                        to: "/dicts/lossesCircumstances",
                                    },
                                    {
                                        label: this.$t("message.menu.sex"),
                                        icon: "",
                                        to: "/dicts/sexDict",
                                    },
                                    {
                                        label: this.$t("message.menu.controlNotRealizedReason"),
                                        icon: "",
                                        to: "/dicts/controlNotRealizedReason",
                                    },
                                    {
                                        label: this.$t("message.menu.treeType"),
                                        icon: "",
                                        to: "/dicts/treeType",
                                    },
                                    {
                                        label: this.$t("message.menu.nestType"),
                                        icon: "",
                                        to: "/dicts/nestType",
                                    },
                                    {
                                        label: this.$t("message.menu.controlType"),
                                        icon: "",
                                        to: "/dicts/controlType",
                                    },
                                    {
                                        label: this.$t("message.menu.habitatType"),
                                        icon: "",
                                        to: "/dicts/habitatType",
                                    },
                                    {
                                        label: this.$t("message.menu.audibility"),
                                        icon: "",
                                        to: "/dicts/audibility",
                                    },
                                    {
                                        label: this.$t("message.menu.lossesStage"),
                                        icon: "",
                                        to: "/dicts/lossesStage",
                                    },
                                    {
                                        label: this.$t("message.menu.seaState"),
                                        icon: "",
                                        to: "/dicts/seaState",
                                    },
                                    {
                                        label: this.$t("message.menu.transectZone"),
                                        icon: "",
                                        to: "/dicts/transectZone",
                                    },
                                    {
                                        label: this.$t("message.menu.succession"),
                                        icon: "",
                                        to: "/dicts/succession",
                                    },
                                    {
                                        label: this.$t("message.menu.plantLitter"),
                                        icon: "",
                                        to: "/dicts/plantLitter",
                                    },
                                    {
                                        label: this.$t("message.menu.temperature"),
                                        icon: "",
                                        to: "/dicts/temperature",
                                    },
                                    {
                                        label: this.$t("message.menu.thermalModernization"),
                                        icon: "",
                                        to: "/dicts/thermalModernization",
                                    },
                                    {
                                        label: this.$t("message.menu.cane"),
                                        icon: "",
                                        to: "/dicts/cane",
                                    },
                                    {
                                        label: this.$t("message.menu.plantsType"),
                                        icon: "",
                                        to: "/dicts/plantsType",
                                    },
                                    {
                                        label: this.$t("message.menu.spatial"),
                                        icon: "",
                                        to: "/dicts/spatial",
                                    },
                                    {
                                        label: this.$t("message.menu.utilization"),
                                        icon: "",
                                        to: "/dicts/utilization",
                                    },
                                    {
                                        label: this.$t("message.menu.wind"),
                                        icon: "",
                                        to: "/dicts/wind",
                                    },
                                    {
                                        label: this.$t("message.menu.visibility"),
                                        icon: "",
                                        to: "/dicts/visibility",
                                    },
                                    {
                                        label: this.$t("message.menu.age"),
                                        icon: "",
                                        to: "/dicts/age",
                                    },
                                    {
                                        label: this.$t("message.menu.water"),
                                        icon: "",
                                        to: "/dicts/water",
                                    },
                                    {
                                        label: this.$t("message.menu.plantHeight"),
                                        icon: "",
                                        to: "/dicts/plantHeight",
                                    },
                                    {
                                        label: this.$t("message.menu.clouds"),
                                        icon: "",
                                        to: "/dicts/clouds",
                                    },
                                ],
                            },
                            {
                                label: this.$t("message.menu.birds"),
                                icon: "",
                                to: "/dicts/birds",
                            },
                            {
                                label: this.$t("message.menu.mammals"),
                                icon: "",
                                to: "/dicts/mammals",
                            },
                            {
                                label: this.$t("message.menu.geographies"),
                                icon: "",
                                to: "",
                                items: [
                                    {
                                        label: this.$t("message.menu.gminy"),
                                        icon: "",
                                        to: "/areaDicts/gminy",
                                    },
                                    {
                                        label: this.$t("message.menu.powiaty"),
                                        icon: "",
                                        to: "/areaDicts/powiaty",
                                    },
                                    {
                                        label: this.$t("message.menu.wojewodztwa"),
                                        icon: "",
                                        to: "/areaDicts/wojewodztwa",
                                    },
                                    {
                                        label: this.$t("message.menu.lesnictwa"),
                                        icon: "",
                                        to: "/areaDicts/lesnictwa",
                                    },
                                    {
                                        label: this.$t("message.menu.nadlesnictwa"),
                                        icon: "",
                                        to: "/areaDicts/nadlesnictwa",
                                    },
                                    {
                                        label: this.$t("message.menu.ochk"),
                                        icon: "",
                                        to: "/areaDicts/ochk",
                                    },
                                    {
                                        label: this.$t("message.menu.oso"),
                                        icon: "",
                                        to: "/areaDicts/oso",
                                    },
                                    {
                                        label: this.$t("message.menu.parkiKrajobrazowe"),
                                        icon: "",
                                        to: "/areaDicts/parkikrajobrazowe",
                                    },
                                    {
                                        label: this.$t("message.menu.parkiNarodowe"),
                                        icon: "",
                                        to: "/areaDicts/parkinarodowe",
                                    },
                                    {
                                        label: this.$t("message.menu.rezerwaty"),
                                        icon: "",
                                        to: "/areaDicts/rezerwaty",
                                    },
                                    {
                                        label: this.$t("message.menu.soo"),
                                        icon: "",
                                        to: "/areaDicts/soo",
                                    },
                                    {
                                        label: this.$t("message.menu.ramsar"),
                                        icon: "",
                                        to: "/areaDicts/ramsar",
                                    },
                                    {
                                        label: this.$t("message.menu.rdlp"),
                                        icon: "",
                                        to: "/areaDicts/rdlp",
                                    },
                                ],
                            },
                        ],
                        roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                    },
                    {
                        label: this.$t("message.menu.users"),
                        icon: "",
                        items: [
                            {
                                label: this.$t("message.menu.usersList"),
                                icon: "",
                                to: "/user/list",
                            },
                            {
                                label: this.$t("message.menu.addUser"),
                                icon: "",
                                to: "/user/create",
                            },
                        ],
                        roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                    },
                    {
                        label: this.$t("message.menu.dataManagement"),
                        icon: "",
                        to: "",
                        items: [
                            {
                                label: this.$t("message.menu.rawData"),
                                icon: "",
                                to: "",
                                items: [
                                    {
                                        label: this.$t("message.menu.programMCH"),
                                        icon: "",
                                        to: "/raw-data/MCH",
                                    },
                                    {
                                        label: this.$t("message.menu.programMCY"),
                                        icon: "",
                                        to: "/raw-data/MCY",
                                    },
                                    {
                                        label: this.$t("message.menu.programMCZ"),
                                        icon: "",
                                        to: "/raw-data/MCZ",
                                    },
                                    {
                                        label: this.$t("message.menu.programMDU"),
                                        icon: "",
                                        to: "/raw-data/MDU",
                                    },
                                    {
                                        label: this.$t("message.menu.programMFGP"),
                                        icon: "",
                                        to: "/raw-data/MFGP",
                                    },
                                    {
                                        label: this.$t("message.menu.programMGZ"),
                                        icon: "",
                                        to: "/raw-data/MGZ",
                                    },
                                    {
                                        label: this.$t("message.menu.programMKO"),
                                        icon: "",
                                        to: "/raw-data/MKO",
                                    },
                                    {
                                        label: this.$t("message.menu.programMKR"),
                                        icon: "",
                                        to: "/raw-data/MKR",
                                    },
                                    {
                                        label: this.$t("message.menu.programMLS"),
                                        icon: "",
                                        to: "",
                                        items: [
                                            {
                                                label: this.$t("message.menu.programMLSBirds"),
                                                icon: "",
                                                to: "/raw-data/MLSBirds",
                                            },
                                            {
                                                label: this.$t("message.menu.programMLSMammals"),
                                                icon: "",
                                                to: "/raw-data/MLSMammals",
                                            },
                                        ],
                                    },
                                    {
                                        label: this.$t("message.menu.programMLSL"),
                                        icon: "",
                                        to: "/raw-data/MLSL",
                                    },
                                    {
                                        label: this.$t("message.menu.programMNG"),
                                        icon: "",
                                        to: "/raw-data/MNG",
                                    },
                                    {
                                        label: this.$t("message.menu.programMNZ"),
                                        icon: "",
                                        to: "/raw-data/MNZ",
                                    },
                                    {
                                        label: this.$t("message.menu.programMMC"),
                                        icon: "",
                                        to: "/raw-data/MMC",
                                    },
                                    {
                                        label: this.$t("message.menu.programMOG"),
                                        icon: "",
                                        to: "/raw-data/MOG",
                                    },
                                    {
                                        label: this.$t("message.menu.programMOP"),
                                        icon: "",
                                        to: "/raw-data/MOP",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPB"),
                                        icon: "",
                                        to: "/raw-data/MPB",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPD"),
                                        icon: "",
                                        to: "/raw-data/MPD",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPG"),
                                        icon: "",
                                        to: "/raw-data/MPG",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPM"),
                                        icon: "",
                                        to: "/raw-data/MPM",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPO"),
                                        icon: "",
                                        to: "/raw-data/MPO",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPPL"),
                                        icon: "",
                                        to: "",
                                        items: [
                                            {
                                                label: this.$t("message.menu.programMPPLBirds"),
                                                icon: "",
                                                to: "/raw-data/MPPLBirds",
                                            },
                                            {
                                                label: this.$t("message.menu.programMPPLMammals"),
                                                icon: "",
                                                to: "/raw-data/MPPLMammals",
                                            },
                                            {
                                                label: this.$t("message.menu.programMPPLNests"),
                                                icon: "",
                                                to: "/raw-data/MPPLNests",
                                            },
                                        ],
                                    },
                                    {
                                        label: this.$t("message.menu.programMPPM"),
                                        icon: "",
                                        to: "/raw-data/MPPM",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPS"),
                                        icon: "",
                                        to: "/raw-data/MPS",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPWR"),
                                        icon: "",
                                        to: "/raw-data/MPWR",
                                    },
                                    {
                                        label: this.$t("message.menu.programMRC"),
                                        icon: "",
                                        to: "",
                                        items: [
                                            {
                                                label: this.$t("message.menu.programMRCMain"),
                                                icon: "",
                                                to: "/raw-data/MRC",
                                            },
                                            {
                                                label: this.$t("message.menu.programMRCLosses"),
                                                icon: "",
                                                to: "/raw-data/MRCLosses",
                                            },
                                        ],
                                    },
                                    {
                                        label: this.$t("message.menu.programMRD"),
                                        icon: "",
                                        to: "/raw-data/MRD",
                                    },
                                    {
                                        label: this.$t("message.menu.programMRY"),
                                        icon: "",
                                        to: "/raw-data/MRY",
                                    },
                                    {
                                        label: this.$t("message.menu.programMSKR"),
                                        icon: "",
                                        to: "/raw-data/MSKR",
                                    },
                                    {
                                        label: this.$t("message.menu.programMSL"),
                                        icon: "",
                                        to: "/raw-data/MSL",
                                    },
                                    {
                                        label: this.$t("message.menu.programMWOP"),
                                        icon: "",
                                        to: "/raw-data/MWOP",
                                    },
                                    {
                                        label: this.$t("message.menu.programMWOT"),
                                        icon: "",
                                        to: "/raw-data/MWOT",
                                    },
                                    {
                                        label: this.$t("message.menu.programMZO"),
                                        icon: "",
                                        to: "/raw-data/MZO",
                                    },
                                    {
                                        label: this.$t("message.menu.programMZPM"),
                                        icon: "",
                                        to: "/raw-data/MZPM",
                                    },
                                    {
                                        label: this.$t("message.menu.programMZPW"),
                                        icon: "",
                                        to: "/raw-data/MZPW",
                                    },
                                    {
                                        label: this.$t("message.menu.programMZPWP"),
                                        icon: "",
                                        to: "/raw-data/MZPWP",
                                    },
                                ],
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.semiRawData"),
                                icon: "",
                                to: "/semi-raw-data",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.dataAggregates"),
                                icon: "",
                                to: "/data-aggregates",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.habitatData"),
                                icon: "",
                                to: "",
                                items: [
                                    {
                                        label: this.$t("message.menu.programMCY"),
                                        icon: "",
                                        to: "/habitat-data/MCY",
                                    },
                                    {
                                        label: this.$t("message.menu.programMDU"),
                                        icon: "",
                                        to: "/habitat-data/MDU",
                                    },
                                    {
                                        label: this.$t("message.menu.programMNZ"),
                                        icon: "",
                                        to: "/habitat-data/MNZ",
                                    },
                                    {
                                        label: this.$t("message.menu.programMMC"),
                                        icon: "",
                                        to: "/habitat-data/MMC",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPG"),
                                        icon: "",
                                        to: "/habitat-data/MPG",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPM"),
                                        icon: "",
                                        to: "/habitat-data/MPM",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPPL"),
                                        icon: "",
                                        to: "/habitat-data/MPPL",
                                    },
                                    {
                                        label: this.$t("message.menu.programMPPM"),
                                        icon: "",
                                        to: "/habitat-data/MPPM",
                                    },
                                    {
                                        label: this.$t("message.menu.programMSL"),
                                        icon: "",
                                        to: "/habitat-data/MSL",
                                    },
                                    {
                                        label: this.$t("message.menu.programMWOT"),
                                        icon: "",
                                        to: "/habitat-data/MWOT",
                                    },
                                    {
                                        label: this.$t("message.menu.programMZO"),
                                        icon: "",
                                        to: "/habitat-data/MZO",
                                    },
                                ],
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.eventLogs"),
                                icon: "",
                                to: "/event-logs",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.logLogs"),
                                icon: "",
                                to: "/log-logs",
                                roles: [SystemRole.ADMIN],
                            },
                            {
                                label: this.$t("message.menu.trends"),
                                icon: "",
                                to: "/trends",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.programPointers"),
                                icon: "",
                                to: "/indicators",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR],
                            },
                        ],
                    },
                    {
                        label: this.$t("message.menu.statute"),
                        icon: "",
                        to: "",
                        roles: [SystemRole.ADMIN],
                    },
                    {
                        label: this.$t("message.menu.raports"),
                        icon: "",
                        to: "",
                        roles: [SystemRole.ADMIN],
                    },
                    {
                        label: this.$t("message.menu.email"),
                        icon: "",
                        items: [
                            {
                                label: this.$t("message.menu.sendEmail"),
                                icon: "",
                                to: "/send-email",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR, SystemRole.REGIONAL_COORDINATOR],
                            },
                            {
                                label: this.$t("message.menu.emailTemplate"),
                                icon: "",
                                to: "/email-template",
                                roles: [SystemRole.ADMIN],
                            },
                            {
                                label: this.$t("message.menu.emailsHistory"),
                                icon: "",
                                to: "/email-history",
                                roles: [SystemRole.ADMIN, SystemRole.MAIN_COORDINATOR, SystemRole.REGIONAL_COORDINATOR],
                            },
                        ],
                    },
                    {
                        label: this.$t("message.menu.settings"),
                        icon: "",
                        to: "",
                        roles: [SystemRole.ADMIN],
                    },
                    {
                        label: this.$t("message.menu.help"),
                        icon: "",
                        items: [
                            {
                                label: this.$t("message.menu.userManual"),
                                icon: "",
                                to: "",
                            },
                            {
                                label: this.$t("message.menu.elearning"),
                                icon: "",
                                to: "",
                            },
                            {
                                label: this.$t("message.menu.filmsAndManuals"),
                                icon: "",
                                to: "",
                            },
                        ],
                        to: "",
                    },
                ],
            };
        },

        methods: {
            onMenuToggle(event) {
                this.$emit("menu-toggle", event);
            },
            addCommand(item) {
                if (item.to) {
                    item.command = (() => this.$router.push({path: item.to, query: item.query})); // query dodane na potrzeby makiet do MFGP, być może potem będzie można usunąć
                }
                if (item.items?.length > 0) {
                    item.items.forEach((internalItem) => this.addCommand(internalItem));
                }
            },
            openCloseSubmenu(item) {
                item.arrowUp = !item.arrowUp;
            },
        },

        computed: {
            menuItems() {
                const newMenuItems = [];
                this.items.filter((menuItem) => {
                    this.addCommand(menuItem);
                    if (menuItem.roles !== undefined
                        && menuItem.roles !== null
                        && menuItem.roles !== "") {
                        if (Object.values(menuItem.roles).includes(localStorage.getItem("role"))) {
                            newMenuItems.push(menuItem);
                        }
                    } else if (menuItem.items) {
                        const newSingleMenuItem = [];
                        menuItem.items.filter((singleMenuItem) => {
                            if (singleMenuItem.roles !== undefined
                                && singleMenuItem.roles !== null
                                && singleMenuItem.roles !== "") {
                                if (Object.values(singleMenuItem.roles).includes(localStorage.getItem("role"))) {
                                    newSingleMenuItem.push(singleMenuItem);
                                }
                                menuItem.items = newSingleMenuItem;
                            }
                            return menuItem;
                        });
                        newMenuItems.push(menuItem);
                    } else {
                        newMenuItems.push(menuItem);
                    }
                    return newMenuItems;
                });
                newMenuItems.forEach((item) => {
                    if (item.items && item.items.length > 0) {
                        item.arrowUp = false;
                        item.items.forEach((it) => {
                            if (it.items && it.items.length > 0) {
                                it.arrowUp = false;
                            }
                        });
                    }
                });
                return newMenuItems;
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/theme/mytheme/variables";
    @import "./assets/scss/app-menu.scss";
</style>
