<template>
    <DictListViewTemplate name="point" liczbaMnoga="Punkty" liczbaPojedyncza="Punkt" biernik="punkt"
                          :searchGeobjects="searchPoints" :searchGeobjectsCount="searchPointsCount"
                          :acceptGeobject="acceptPoint" :acceptGeobjectHistory="acceptPointHistory"/>
</template>

<script>
    import DictListViewTemplate from "@/views/dict/DictListViewTemplate.vue";
    import {
        acceptPoint,
        acceptPointHistory,
        searchPoints,
        searchPointsCount,
    } from "@/swagger/vue-api-client";

    export default {

        name: "TransectDictView",

        components: {
            DictListViewTemplate,
        },

        methods: {
            acceptPointHistory,
            searchPointsCount,
            acceptPoint,
            searchPoints,
        },
    };
</script>

<style lang="scss"></style>
