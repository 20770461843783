<template>
    <DataTable lazy :loading="loading" :value="logLogs" :totalRecords="totalRecords"
               dataKey="id" paginator v-model:rows="page.limit" v-model:first="page.offset"
               @page="onPage" :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small"
               @sort="onPage" removableSort class="log-logs-table">
        <Column header="Data utworzenia" field="creationDate">
            <template #body="slotProps">
                {{ computedDate(slotProps.data.creationDate) }}
            </template>
        </Column>
        <Column header="Ip" field="ip" />
        <Column header="Login" field="login" />
        <Column header="Status" field="status">
            <template #body="slotProps">
                {{ computedStatus(slotProps.data.status) }}
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchLoginLogs,
        countLoginLogs,
    } from "@/swagger/vue-api-client";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "LogLogsTable",

        components: {
            DataTable,
            Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                logLogs: [],
                totalRecords: 0,
                loading: false,
                page: SearchCriteria.getClearPage(),
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            search() {
                if (this.loading) return;
                this.loading = true;

                const dateRequest = {
                    min: this.searchCriteria.creationDate && this.searchCriteria.creationDate.length > 0 && this.searchCriteria.creationDate[0] ? this.searchCriteria.creationDate[0] : null,
                    max: this.searchCriteria.creationDate && this.searchCriteria.creationDate.length > 1 && this.searchCriteria.creationDate[1] ? this.searchCriteria.creationDate[1] : null,
                };

                setTimeout(() => {
                    searchLoginLogs({body: {...this.searchCriteria, creationDate: dateRequest}})
                        .then((response) => {
                            this.logLogs = response.data;
                            return countLoginLogs({body: {...this.searchCriteria, creationDate: dateRequest}});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },

            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },

            computedStatus(status) {
                if (status === "SUCCESS") return "Sukces";
                if (status === "FAIL") return "Porażka";
                if (status === "BLOCKED") return "Zablokowano";
                if (status === "LOGOUT") return "Wylogowano";
                if (status === "REFRESH") return "Odświeżanie";
                return "";
            },

            computedDate(date) {
                if (date) return DateUtils.displayDateTime(date);
                return "";
            },
        },

        computed: {
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
