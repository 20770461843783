<template>
    <div class="p-3">
        <ImportIndicatorsDialog v-model="showImportIndicatorsDialog" />
        <div class="flex gap-2 justify-content-end mb-2">
            <CustomButton label="Importuj" @click="importIndicators" />
            <CustomButton label="Eksportuj" @click="exportIndicators" />
        </div>
        <div>
            <CustomMultiSelect label="Wyświetlane kolumny" :items="allColumns"
                               v-model="selectColumns" item-label="header" item-value="field" name="selectColumns"
                               prevent-sorting @hide="updateTableColumns"/>
            <IndicatorsTable ref="indicatorsTable" :columns="tableColumns" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import IndicatorsTable from "./IndicatorsTable.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FileUtils} from "@/utils/FileUtils";
    import ImportIndicatorsDialog from "./ImportIndicatorsDialog.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "IndicatorsView",

        components: {
            IndicatorsTable,
            CustomButton,
            ImportIndicatorsDialog,
            CustomMultiSelect,
        },

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaIndicators(),
                page: SearchCriteria.getClearPage(),
                showImportIndicatorsDialog: false,
                tableColumns: [],
                selectColumns: [],
                allColumns: [],
                columnProgram: null,
                columnSpecies: null,
                columnYear: null,
                columnAreal: null,
                columnNumberOfSettledSquares: null,
                columnPopulationSize: null,
                columnPrevalence: null,
                columnPrevalenceSE: null,
                columnPopulationIndexSE: null,
                columnPropOfPairsWithSucBreadSE: null,
                columnAverageNumOfChicksPerHatchingSE: null,
                columnAverageNumOfChicksPerSucHatchingSE: null,
                columnPopulationIndex: null,
                columnPropOfPairsWithSucBread: null,
                columnAverageNumOfChicksPerHatching: null,
                columnAverageNumOfChicksPerSucHatching: null,
                columnDataSource: null,
            };
        },

        beforeMount() {
            this.assignColumnProps();
            /* eslint-disable */
            this.allColumns = [this.columnProgram, this.columnSpecies, this.columnYear, this.columnPopulationIndex, this.columnPopulationIndexSE, this.columnPopulationSize, this.columnPropOfPairsWithSucBread, this.columnPropOfPairsWithSucBreadSE, this.columnAverageNumOfChicksPerHatching, this.columnAverageNumOfChicksPerHatchingSE, this.columnAverageNumOfChicksPerSucHatching, this.columnAverageNumOfChicksPerSucHatchingSE, this.columnNumberOfSettledSquares, this.columnPrevalence, this.columnPrevalenceSE, this.columnAreal, this.columnDataSource];
            /* eslint-enable */
            this.allColumns.forEach((c) => this.selectColumns.push(c.field));
            this.updateTableColumns();
        },

        methods: {
            assignColumnProps() {
                /* eslint-disable */
                this.columnProgram = {header: "Program", field: "program", filterField: "programName", showFilterMatchModes: false, filterMenuClass: "w-16rem", filterType: "selectOne", filterItems: [], filterItemLabel: "", sortable: true, bodyContent: (data) => data.program, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "programs", editorItemLabel: ""};
                this.columnSpecies = {header: "Gatunek", field: "species", bodyContent: (data) => {return data.species?.namePl ? data.species?.namePl : data.species?.name}, bodyClass: () => "", filterField: "speciesName", showFilterMatchModes: false, filterMenuClass: "w-16rem", filterType: "selectOne", filterItems: [], sortable: true, editorType: "selectOne", editorEmptyValue: false, editorItems: "species", editorItemLabel: "name"};
                this.columnYear = {header: "Rok", field: "year", filterField: "year", showFilterMatchModes: false,
                filterMenuClass: "w-16rem", sortable: true, bodyContent: (data) => data.year.getFullYear(),
                filterType: "interval", filterOtherAttrs: {}, bodyClass: () => "", editorType: "calendar", range: {min: 0, max: new Date().getFullYear()}};
                this.columnAreal = this.getNumberColumn("Wyróżnik obszaru", "areal", "areal", 0, 999);
                this.columnNumberOfSettledSquares = this.getNumberColumn("Liczba zasiedlonych kwadratów", "numberOfSettledSquares", "numberOfSettledSquares", 0, 999);
                this.columnPopulationSize = this.getNumberColumn("Liczebność populacji", "populationSize", "populationSize", 0, 999);
                this.columnPrevalence = this.getNumberColumn("Rozpowszechnienie", "prevalence", "prevalence", 0, 999);
                this.columnPrevalenceSE = this.getNumberColumn("Błąd standardowy rozpowszechnienia", "prevalenceSE", "prevalenceSE", 0, 999);
                this.columnAverageNumOfChicksPerHatching = this.getNumberColumn("Średnia liczba piskląt / lęg", "averageNumOfChicksPerHatching", "averageNumOfChicksPerHatching", 0, 999);
                this.columnAverageNumOfChicksPerHatchingSE = this.getNumberColumn("Błąd standardowy średniej liczby piskląt / lęgu", "averageNumOfChicksPerHatchingSE", "averageNumOfChicksPerHatchingSE", 0, 999);
                this.columnPopulationIndex = this.getNumberColumn("Wskaźnik liczebności populacji", "populationIndex", "populationIndex", 0, 999);
                this.columnPopulationIndexSE = this.getNumberColumn("Błąd standardowy wskaźnika liczebności populacji", "populationIndexSE", "populationIndexSE", 0, 999);
                this.columnPropOfPairsWithSucBread = this.getNumberColumn("Proporcja par z udanym lęgiem", "propOfPairsWithSucBread", "propOfPairsWithSucBread", 0, 999);
                this.columnPropOfPairsWithSucBreadSE = this.getNumberColumn("Błąd standardowy proporcji par z udanym lęgiem", "propOfPairsWithSucBreadSE", "propOfPairsWithSucBreadSE", 0, 999);
                this.columnAverageNumOfChicksPerSucHatching = this.getNumberColumn("Średnia liczba piskląt / lęg udany", "averageNumOfChicksPerSucHatching", "averageNumOfChicksPerSucHatching", 0, 999);
                this.columnAverageNumOfChicksPerSucHatchingSE = this.getNumberColumn("Błąd standardowy średniej liczby piskląt / lęgu udanego", "averageNumOfChicksPerSucHatchingSE", "averageNumOfChicksPerSucHatchingSE", 0, 999);
                this.columnDataSource = {header: "Źródło", field: "dataSource", filterField: "dataSource", showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, filterType: "", bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.dataSource, editorType: "inputText",};
            },

            getNumberColumn(header, field, filterField, min, max) {
                return {header: header, field: field, filterField: filterField, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: true, filterType: "interval",
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field],
                    editorType: "inputNumber", range: {min: min, max: max}};
            },
            /* eslint-enable */

            updateTableColumns() {
                const set = new Set(this.selectColumns);
                const temporaryTable = [];
                this.allColumns.forEach((c) => {
                    if (set.has(c.field)) temporaryTable.push(_.cloneDeep(c));
                });
                this.tableColumns = temporaryTable;
            },

            exportIndicators() {
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                FileUtils.downloadFile(criteria, "wskazniki.xlsx", "/dataIndicator/search/export");
            },

            importIndicators() {
                this.showImportIndicatorsDialog = true;
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaIndicatorsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
</style>
