/* eslint-disable */
export const SystemRole = {
    ADMIN: "ADMIN",
    MAIN_COORDINATOR: "MAIN_COORDINATOR",
    NATIONAL_COORDINATOR: "NATIONAL_COORDINATOR",
    REGIONAL_COORDINATOR: "REGIONAL_COORDINATOR",
    OBSERVER: "OBSERVER",

    isAdmin() {
        return localStorage.getItem("role") === this.ADMIN;
    },
    
    isCoordinator() {
        return this.isMainCoordinator() || this.isNationalCoordinator() || this.isRegionalCoordinator();
    },

    isMainCoordinator() {
        return localStorage.getItem("role") === this.MAIN_COORDINATOR;
    },

    isNationalCoordinator() {
        return localStorage.getItem("role") === this.NATIONAL_COORDINATOR;
    },

    isRegionalCoordinator() {
        return localStorage.getItem("role") === this.REGIONAL_COORDINATOR;
    },

    isObserver() {
        return localStorage.getItem("role") === this.OBSERVER;
    },

    getUserRole() {
        return localStorage.getItem("role");
    },

};
