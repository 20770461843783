<template>
    <DictListViewTemplate name="position" liczbaMnoga="Stanowiska" liczbaPojedyncza="Stanowisko" biernik="stanowisko"
                          :searchGeobjects="searchPositions" :searchGeobjectsCount="searchPositionsCount"
                          :acceptGeobject="acceptPosition" :acceptGeobjectHistory="acceptPositionHistory"/>
</template>

<script>
    import DictListViewTemplate from "@/views/dict/DictListViewTemplate.vue";
    import {
        acceptPosition,
        acceptPositionHistory,
        searchPositions,
        searchPositionsCount,
    } from "@/swagger/vue-api-client";

    export default {

        name: "TransectDictView",

        components: {
            DictListViewTemplate,
        },

        methods: {
            acceptPositionHistory,
            acceptPosition,
            searchPositionsCount,
            searchPositions,
        },
    };
</script>

<style lang="scss"></style>
