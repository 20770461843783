<template>
    <div>
        <div class="flex gap-3 flex-wrap" v-if="loadUsers">
            <!-- <CustomMultiSelect name="obserwatorzy" label="Do obserwatorów z programu" :items="programs"
                               itemValue="value" v-model="internalValue.observersFromProgram"
                               class="w-4 mb-0" @update:modelValue="update" />
            <CustomMultiSelect name="koordynatorzy" label="Do koordynatorów z programu" :items="programs"
                               itemValue="value" v-model="internalValue.coordinatorsFromProgram"
                               class="w-4 mb-0" @update:modelValue="update" />
            <CustomMultiSelect name="obserwatorzy-niezatwierdzonym-formularze"
                               label="Do obserwatorów z niezatwierdzonym formularze w programie"
                               :items="programs" itemValue="value" v-model="internalValue.notAcceptedFormsFromProgram"
                               class="w-4 mb-0" @update:modelValue="update" /> -->
            <!-- <CustomMultiSelect name="obserwatorzy-niezatwierdzonym-formularze" label="Do innych użytkowników"
                               :items="users" itemLabel="name" itemValue="id" v-model="internalValue.otherReceivers"
                               class="w-4 mb-0" virtual-scrolling @update:modelValue="update" /> -->
            <CustomInputText label="Temat" v-model="internalValue.subject" name="subject" @update:modelValue="update"
                             class="w-4" />
            <CustomMultiSelect name="recipients" label="Odbiorcy" :items="users" v-model="internalValue.recipients"
                               @update:modelValue="update" class="w-4" virtual-scrolling itemLabel="name"
                               itemValue="id" />
            <CustomCalendar label="Data od do" class="w-3 mb-0" name="controlDate" selectionMode="range"
                            v-model="internalValue.sendDate" @update:modelValue="update" />
        </div>
        <div class="flex justify-content-end mb-3">
            <CustomButton label="Wyczyść" @click="clear"/>
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import { SearchCriteria } from "@/utils/SearchCriteria";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {
        // getListOfPrograms,
        listUsers,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EmailHistorySearchCrtiera",

        components: {
            CustomButton,
            CustomMultiSelect,
            CustomCalendar,
            CustomInputText,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue", "unselect"],

        data() {
            return {
                searchCriteria: SearchCriteria,
                loadPrograms: false,
                loadUsers: false,
            };
        },

        beforeMount() {
            // this.getPrograms();
            this.getUsersList();
        },

        methods: {
            // getPrograms() {
            //     getListOfPrograms()
            //         .then((response) => {
            //             this.programs = response.data;

            //             // In order to use search, is necessary to change data structur
            //             for (let i = 0; i < this.programs.length; i += 1) {
            //                 this.programs[i] = { label: this.programs[i], value: this.programs[i] };
            //             }

            //             this.loadPrograms = true;
            //         });
            // },
            getUsersList() {
                listUsers()
                    .then((response) => {
                        this.users = response.data;
                        this.loadUsers = true;
                    });
            },

            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.sendDate = null;
                this.internalValue.recipients = [];
                this.internalValue.subject = "";
                // this.$emit("unselect");
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            "internalValue.programs"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getFields();
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
