<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2 w-full">
            <CustomInputText label="Kod" name="code" v-model="internalValue.code"
                             @update:modelValue="update" />
            <CustomInputText label="Nazwa polska" name="namePl" v-model="internalValue.namePl"
                             @update:modelValue="update" />
            <CustomInputText label="Nazwa angielska" name="nameEn" v-model="internalValue.nameEn"
                             @update:modelValue="update" />
            <CustomInputText label="Nazwa łacińska" name="nameLat" v-model="internalValue.nameLat"
                             @update:modelValue="update" />
        </div>
        <CustomButton label="Wyczyść" @click="clear" />
    </div>
    <div class="flex justify-content-end mb-3">
        <CustomButton label="Dodaj nowego ssaka" @click="navigateToCreateMammal" />
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "MammalsDictSearchCriteria",

        components: {
            CustomInputText,
            CustomButton,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.code = "";
                this.internalValue.namePl = "";
                this.internalValue.nameEn = "";
                this.internalValue.nameLat = "";
                this.update();
            },

            navigateToCreateMammal() {
                this.$router.push("/create-mammal");
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
