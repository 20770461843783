<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row" dataKey="ordinal"
               @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
               class="navigate-table" @click="arrowsNavigateTable">
        <template #header>
            <AddBirdsMGZ v-model="internalBirds" :error="showError()"/>
        </template>
        <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
        <Column header="Gatunek" class="w-3" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Gatunek" name="species" v-model="data[field]"
                                 :items="dicts.programBirds" itemLabel="name" />
            </template>
        </Column>
        <Column header="Nie liczono" class="w-1" field="notCounted">
            <template #body="slotProps">
                <div :class="slotProps.data.notCounted ? 'true-style' : 'false-style'">
                    {{ computedBoolean(slotProps.data.notCounted) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomCheckbox label="Nie liczono" name="notCounted" v-model="data[field]" />
            </template>
        </Column>
        <Column header="L. osobników" class="w-2" field="count">
            <template #editor="{data,field}">
                <CustomInputNumber label="L. osobników" name="count" v-model="data[field]" :min="1" />
            </template>
        </Column>
        <Column class="w-5"/>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import AddBirdsMGZ from "@/views/formMGZ/components/AddBirdsMGZ.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMGZ",

        components: {
            AddBirdsMGZ,
            DataTable,
            Column,
            CustomSelectOne,
            CustomInputNumber,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        emits: ["update:noBirds", "update:birds"],

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.birds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
