<template>
    <div class="position-table">
        <DataTable :value="emailHistory" editMode="row" dataKey="id" lazy selectionMode="single"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria" @page="onPage"
                   @sort="onPage" removableSort paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Emaili na stronie"
                   paginator :rows="10" :rowsPerPageOptions="[5,10,20,50]" v-model:rows="searchParams.page.limit"
                   v-model:first="searchParams.page.offset">
            <Column header="Data" field="sendDate"></Column>
            <Column header="Nadawca" field="sender"></Column>
            <Column header="Temat" field="title"></Column>
            <Column header="Odbiorcy" field="recipients"></Column>
            <!-- <Column header="Odbiorcy" field="message"></Column>
            <Column header="Odbiorcy" field="error"></Column>
            <Column header="Odbiorcy" field="errorDescription"></Column> -->
            <!-- <Column header="Działania">
                <template #body="slotProps">
                    <div class="position-dic-table-buttons flex flex-wrap gap-2">
                        <CustomButton label="Wyświetl szczegóły" @click="navigateToPosition(slotProps.data.id)" />
                    </div>
                </template>
            </Column> -->
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchEmailHistory,
        countEmailHistory,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    // import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "HistoryEmailTable",

        components: {
            // CustomButton,
            DataTable,
            Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                emailHistory: null,
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                const dateRequest = {
                    min: this.searchParams.sendDate && this.searchParams.sendDate.length > 0 && this.searchParams.sendDate[0] ? DateUtils.dateAsString(this.searchParams.sendDate[0]) : null,
                    max: this.searchParams.sendDate && this.searchParams.sendDate.length > 1 && this.searchParams.sendDate[1] ? DateUtils.dateAsString(this.searchParams.sendDate[1]) : null,
                };

                console.log(dateRequest);

                searchEmailHistory({body: {...this.searchParams, sendDate: dateRequest}})
                    .then((response) => {
                        this.emailHistory = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                const dateRequest = {
                    min: this.searchParams.sendDate && this.searchParams.sendDate.length > 0 && this.searchParams.sendDate[0] ? DateUtils.dateAsString(this.searchParams.sendDate[0]) : null,
                    max: this.searchParams.sendDate && this.searchParams.sendDate.length > 1 && this.searchParams.sendDate[1] ? DateUtils.dateAsString(this.searchParams.sendDate[1]) : null,
                };

                countEmailHistory({body: {...this.searchParams, sendDate: dateRequest}})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchParams.page.offset,
                    rows: this.searchParams.page.limit,
                };
            },

            // navigateToPosition(id) {
            //     this.$router.push(`/position/${id}`);
            // },
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },
    };
</script>
