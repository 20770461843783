<template>
    <div class="p-3">
        <LogLogsSearchCriteria v-model="searchCriteria" @update:modelValue="search" />
        <LogLogsTable ref="logLogsTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import LogLogsTable from "./LogLogsTable.vue";
    import LogLogsSearchCriteria from "./LogLogsSearchCriteria.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "LogLogsView",

        components: {
            LogLogsTable,
            LogLogsSearchCriteria,
        },

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaLogLogs(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            search() {
                this.$refs.logLogsTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
