<template>
    <div>
        <ProgramDictSearchCriteria v-model="searchCriteria" class="p-3" />
        <ProgramDictTable ref="programDictTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import ProgramDictSearchCriteria from "./components/ProgramDictSearchCriteria.vue";
    import ProgramDictTable from "./components/ProgramDictTable.vue";

    export default {
        name: "ProgramDictView",

        components: {
            ProgramDictSearchCriteria,
            ProgramDictTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    key: "",
                };
            },
        },
    };
</script>

<style lang="scss">
</style>
