<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMCYHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Typ zbiornika" name="habitatType"
                                     v-model="slotProps.habitatData.habitatType" :emptyValue="false"
                                     :items="dicts.habitatType" :disabled="!editing" class="w-3"
                                     required @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                     :showErrors="showErrorMessages" />
                    <CustomInputText v-if="slotProps.habitatData?.habitatType?.code === 'IN'"
                                     label="Podaj inny typ" name="otherHabitat"
                                     v-model="slotProps.habitatData.otherHabitat" class="w-9"
                                     required @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                     :showErrors="showErrorMessages" />
                </div>
                <div class="flex gap-3">
                    <CustomMultiSelect label="Otoczenie zbiornika" name="surroundings"
                                       v-model="slotProps.habitatData.surroundings" :emptyValue="false"
                                       :items="dicts.surroundings" :disabled="!editing" class="w-8"
                                       required @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                       :showErrors="showErrorMessages" />
                    <div class="dummy w-4" />
                </div>
                <div class="flex gap-3">
                    <CustomMultiSelect label="Roślinność wynurzona (wybierz dominujący typ roślinności)" name="dominantVegetation"
                                       v-model="slotProps.habitatData.dominantVegetation" :emptyValue="false"
                                       :items="dicts.dominantVegetation" :disabled="!editing" class="w-8"
                                       required @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                       :showErrors="showErrorMessages" />
                    <CustomSelectOne label="Poziom wody" name="water"
                                     v-model="slotProps.habitatData.water" :emptyValue="false"
                                     :items="dicts.water" :disabled="!editing" class="w-4"
                                     required @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                     :showErrors="showErrorMessages" />
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMCY",

        components: {
            CustomSelectOne,
            CustomInputText,
            CustomMultiSelect,
            HabitatDataTemplate,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
