<template>
    <div class="w-full flex justify-content-end pr-3 pb-3">
        <CustomButton label="Powrót do mapy" icon="pi pi-chevron-left" rowReverse @click="goBack" />
    </div>
    <FormObservationsTemplate ref="observationsTemplate" :controls="controls">
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" :index="slotProps.index" controlTypeDates />
            <CustomCheckbox class="w-4 darker-font" label="Brak wody na stanowisku" v-model="slotProps.control.noWater" name="noWater"/>
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirdsMPO v-if="!slotProps.control.noWater" v-model:birds="slotProps.control.birds" v-model:noBirds="slotProps.control.noBirds" />
        </template>
        <template v-slot:habitatData>
            <!-- <HabitatDataMPO :habitatData="internalForm.habitatData" v-model:habitatDataToChange="internalForm.habitatDataToChange"/> -->
            <div class="flex justify-content-between flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Typ zbiornika" name="habitatType" :items="dicts.habitatType" class="w-5"
                                     v-model="internalForm.habitatType"
                                     required :showErrors="showErrorMessages" :disabled="!editing" />
                    <CustomInputText v-if="internalForm?.habitatType?.code === 'INNE'"
                                     label="Podaj inny typ zbiornika" name="otherHabitat" class="w-7"
                                     v-model="internalForm.otherHabitat" :disabled="!editing"
                                     :required="internalForm.habitatType.code === 'IN'" :showErrors="showErrorMessages" />
                    <div v-else class="w-7"/>
                </div>
                <div class="flex gap-3">
                    <CustomMultiSelect label="Roślinność wynurzona" name="vegetation" :items="dicts.dominantVegetation" class="w-5"
                                       v-model="internalForm.vegetation"
                                       required :showErrors="showErrorMessages" :disabled="!editing" />
                    <CustomInputText v-if="computedCondition(internalForm.vegetation)"
                                     label="Podaj inną roślinność" name="otherVegetation" class="w-7"
                                     v-model="internalForm.otherVegetation" :disabled="!editing"
                                     :required="internalForm.vegetation.code === 'INNA'" :showErrors="showErrorMessages" />
                    <div v-else class="w-7"/>
                </div>
            </div>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import FormBirdsMPO from "@/views/formMPO/components/FormBirdsMPO.vue";
    // import HabitatDataMPO from "@/views/formMPO/components/HabitatDataMPO.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FormMPOObservations",

        components: {
            // HabitatDataMPO,
            FormBirdsMPO,
            ControlTypeAndDates,
            FormObservationsTemplate,
            CustomCheckbox,
            CustomInputText,
            CustomSelectOne,
            CustomMultiSelect,
            CustomButton,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:form", "update:step"],

        data() {
            return {
                controls: [],
                control: _.cloneDeep(EmptyStructures.getEmptyMPOControl()),
            };
        },

        beforeMount() {
            // this.controls[0] = this.form.control1;
            // this.controls[1] = this.form.control2;
            if (this.form.control1 != null) {
                this.controls[0] = this.form.control1;
            } else {
                this.controls[0] = _.cloneDeep(EmptyStructures.getEmptyMPOControl());
            }
            if (this.form.control2 != null) {
                this.controls[1] = this.form.control2;
            } else {
                this.controls[1] = _.cloneDeep(EmptyStructures.getEmptyMPOControl());
            }
        },

        methods: {
            computedCondition(vegetationList) {
                if (vegetationList && vegetationList.length > 0) {
                    return vegetationList.some((v) => v.code === "INN");
                }
                return false;
            },
            goBack() {
                this.$emit("update:step", 1);
                let query = "?step=1";
                if (this.$route.query.controlCode != null) query += `&controlCode=${this.$route.query.controlCode}`;
                window.scrollTo({ top: 0, behavior: "smooth" });
                window.history.pushState(null, "", this.$route.path + query);
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.$emit("update:form", value);
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .darker-font {
        color: var(--text-color-secondary);
    }
</style>
