<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton v-if="showSpeciesButton" icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div v-for="(c,index) in columns" :key="index" :class="`w-${c.columnWidth}`">
                <CustomAutocompleteBirdSpecies v-if="c.editorType === 'autocompleteBirdSpecies'"
                                               v-model="newItem[c.field]" :emptyValue="c.editorEmptyValue"
                                               :name="c.field" :label="c.header" :required="c.required"
                                               :showErrors="c.required && showErrorMessagesBirds"
                                               :disabled="!editing" :searchParam="c.searchParam"
                                               :customErrorMessage="getIncorrectSpeciesMessage(c.field)" />
                <CustomAutocompleteTreeSpecies v-if="c.editorType === 'autocompleteTreeSpecies'"
                                               v-model="newItem[c.field]" :emptyValue="c.editorEmptyValue"
                                               :name="c.field" :label="c.header" :required="c.required"
                                               :showErrors="c.required && showErrorMessagesBirds"
                                               :disabled="!editing" :searchParam="c.searchParam" />
                <CustomInputText v-else-if="c.editorType === 'inputText'" v-model="newItem[c.field]"
                                 :name="c.field" :label="c.header" :required="c.required"
                                 :showErrors="c.required && showErrorMessagesBirds"
                                 :disabled="!editing" />
                <CustomInputNumber v-else-if="c.editorType === 'inputNumber'" v-model="newItem[c.field]"
                                   :name="c.field" :label="c.header" :required="c.required" :notZero="c.notZero"
                                   :showErrors="c.required && showErrorMessagesBirds"
                                   :disabled="!editing" :min="c.min ? c.min : 0" />
                <CustomSelectOne v-else-if="c.editorType === 'selectOne'" v-model="newItem[c.field]"
                                 :items="(dicts[c.editorItems] ? dicts[c.editorItems] : [])"
                                 :itemLabel="c.field === 'nestingCriteria' ? getDictLabelWithCodePrefix : c.editorItemLabel"
                                 :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue"
                                 :name="c.field" :label="c.header" :required="c.required"
                                 :showErrors="c.required && showErrorMessagesBirds" :disabled="!editing"
                                 :tooltip="c.bodyTooltip" />
                <CustomMultiSelect v-else-if="c.editorType === 'multiSelect'" v-model="newItem[c.field]"
                                   :items="dicts[c.editorItems] ? dicts[c.editorItems] : []"
                                   :itemLabel="c.editorItemLabel"
                                   :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue"
                                   :name="c.field" :label="c.header" :required="c.required"
                                   :showErrors="c.required && showErrorMessagesBirds"
                                   :disabled="!editing"/>
                <CustomCheckbox v-else-if="c.editorType === 'checkbox'" v-model="newItem[c.field]"
                                :name="c.field" :label="c.header" :required="c.required"
                                :showErrors="c.required && showErrorMessagesBirds"
                                :disabled="!editing || noCountDisabled" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="addBirdsControl" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem"
                          v-on:keydown.tab="addBirdsControl"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import CustomAutocompleteTreeSpecies from "@/components/form/CustomAutocompleteTreeSpecies.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddBirds",

        components: {
            CustomAutocompleteBirdSpecies,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
            CustomInputText,
            CustomCheckbox,
            CustomMultiSelect,
            CustomAutocompleteTreeSpecies,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
            columns: {
                type: Array,
                required: true,
            },
            showSpeciesButton: {
                type: Boolean,
                default: true,
            },
        },

        inject: ["editing", "dicts", "programCode"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrorMessagesBirds: false,
                speciesName: "",
                speciesCode: "",
                requiredChecker: [],
                noCountDisabled: false,
            };
        },

        beforeMount() {
            if (this.programCode === "MRD") {
                this.dicts.programBirds = this.dicts.programBirds.filter((b) => b.info?.length > 0);
            }
        },

        methods: {
            setSpecies(species) {
                this.newItem.species = species;
            },
            addBirdsControl() {
                this.columns.forEach((c, index) => {
                    if (c.required && (this.newItem[c.field] == null || this.newItem[c.field] === "")) {
                        this.showErrorMessagesBirds = true;
                        this.requiredChecker[index] = false;
                    } else {
                        this.requiredChecker[index] = true;
                    }
                });
                const validation = this.requiredChecker.every((el) => el);
                if (validation && ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrorMessagesBirds = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
            getIncorrectSpeciesMessage(key) {
                if (key === "species" && !ValidateUtils.validateCorrectSpecies(this.newItem, [key])) return "Niepoprawny kod";
                if (key === "species2" && !ValidateUtils.validateCorrectSpecies(this.newItem, [key])) return "Niepoprawny gatunek";
                return "";
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            speciesName(value) {
                if (value.name != null) {
                    this.newItem.species = value;
                    this.speciesCode = value;
                } else {
                    this.newItem.species = null;
                    this.speciesCode = "";
                }
            },
            speciesCode(value) {
                if (value.code != null) {
                    this.newItem.species = value;
                    this.speciesName = value;
                } else {
                    this.newItem.species = null;
                    this.speciesName = "";
                }
            },
            "newItem.species"(value) {
                if (value && value.code) this.newItem.species2 = value;
            },
            "newItem.species2"(value) {
                if (value && value.code) this.newItem.species = value;
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
