<template>
    <div></div>
</template>

<script>
    import {
        addForm,
        addTransectZero,
        addPointZero,
    } from "@/swagger/vue-api-client";

    export default {
        name: "ToCreateForm",

        methods: {
            addNewForm() {
                if (this.$route.query.controlCode === "0T") {
                    addTransectZero({
                        programObserverId: this.$route.query.observerID,
                        fieldId: this.$route.query.fieldId,
                    }).then((response) => this.goToFormZero(response, "transect"));
                } else if (this.$route.query.controlCode === "0P") {
                    addPointZero({
                        programObserverId: this.$route.query.observerID,
                        fieldId: this.$route.query.fieldId,
                    }).then((response) => this.goToFormZero(response, "point"));
                } else {
                    addForm({
                        program: this.$route.query.programCode,
                        programObserverId: this.$route.query.observerID,
                        fieldId: this.$route.query.fieldId,
                        controlCode: this.$route.query.controlCode ? this.$route.query.controlCode : undefined,
                    })
                        .then((response) => this.goToForm(response))
                        .catch((error) => { console.log(error); });
                }
            },
            goToForm(response) {
                const path = `/form/${this.$route.query.programCode}/${response.data}`;
                if (this.$route.query.controlCode == null) {
                    this.$router.replace({ path });
                } else {
                    this.$router.replace({ path, query: {controlCode: this.$route.query.controlCode} });
                }
            },
            goToFormZero(response, type) {
                const path = `/form-zero/${type}/${response.data}`;
                if (this.$route.query.controlCode == null) {
                    this.$router.replace({ path });
                } else {
                    this.$router.replace({ path, query: {controlCode: this.$route.query.controlCode} });
                }
            },
        },

        mounted() {
            this.addNewForm();
        },
    };
</script>

<style lang="scss">
</style>
