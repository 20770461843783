<template>
    <div>
        <div class="login-panel-outer p-4 sm:py-4 md:px-8">
            <div class="login-panel-header text-center flex">
                <img src="@/assets/logo.png" alt="logo" class="w-6">
                <img src="@/assets/MPP_logo.png" alt="logo" class="w-6">
            </div>
            <div class="login-panel-content">
                <h1 class="py-3 text-center">{{ $t('message.login.title') }}</h1>
                <VeeForm @submit="login" v-slot="{ errors }">
                    <div v-if="loginError" class="p-mb-5">{{ $t('message.login.loginError') }}</div>
                    <CustomInputText name="login" v-model="loginForm.login" :label="$t('message.login.login')"
                                     rules="required"/>
                    <CustomPassword name="password" v-model="loginForm.password"
                                    :label="$t('message.login.password')" rules="required"/>
                    <div class="login-error" v-if="isBlocked">
                        Zbyt dużo nieudanych prób logowania. Logowanie zablokowane na 24 godziny
                    </div>
                    <div class="login-error" v-if="badCredentials">
                        Niepoprawne dane logowania
                    </div>
                    <Button :label="$t('message.login.action.login')" class="mt-3" type="submit"
                            :disabled="isDisabled(errors)"/>
                </VeeForm>
                <router-link :to="{name: 'remindPassword'}" class="text-center block my-3">
                    {{ $t('message.login.action.remindPassword') }}
                </router-link>
                <div class="text-center">
                    {{ $t('message.register.action.dontHaveAnAccountYet') }} -
                    <router-link :to="{name: 'register'}" class="text-center block my-2">
                        {{ $t('message.register.action.register') }}
                    </router-link>
                </div>
                <div class="flex justify-content-center">
                    <img src="@/assets/logotyp-NFOSiGW.png" alt="logo" class="w-full">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {Form as VeeForm} from "vee-validate";
    import axios from "axios";
    // import {login as loginRequest} from "@/swagger/vue-api-client";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "LoginView",
        components: {
            CustomInputText, CustomPassword, Button, VeeForm,
        },

        data() {
            return {
                loginError: false,
                loginForm: {
                    login: "",
                    password: "",
                },
                isBlocked: false,
                badCredentials: false,
            };
        },

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            login() {
                const uninterceptedAxiosInstance = axios.create();
                uninterceptedAxiosInstance.post(process.env.VUE_APP_API_URL + "/login", this.loginForm, { withCredentials: true })
                    .then((response) => {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("role", response.data.activeRole);
                        localStorage.setItem("roles", response.data.roles);
                        localStorage.setItem("name", response.data.name);
                        localStorage.setItem("token_validity", String(Date.now() + response.data.expires_in * 1000));
                        if (this.$route.query.next) {
                            this.$router.push(this.$route.query.next);
                        } else {
                            this.$router.push({name: "homeProgramsList"});
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        switch (error.code) {
                            case "ERR_BAD_RESPONSE":
                                this.isBlocked = true;
                                this.badCredentials = false;
                                break;
                            case "ERR_BAD_REQUEST":
                                this.badCredentials = true;
                                break;
                            default:
                        }
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "assets/scss/login";
</style>
