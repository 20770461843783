<template>
    <div>
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true" v-model:rows="searchParams.page.limit"
                   v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="e-mail" :sortable="true"></Column>
            <Column field="systemRole" header="Rola">
                <template #body="slotProps">
                    {{ computedSystemRole(slotProps.data.roles) }}
                </template>
            </Column>
            <Column v-if="Role.isAdmin()" headerStyle="width: 80px">
                <template #body="slotProps">
                    <CustomButton v-if="!slotProps.data.accepted" label="Akceptuj" @click="acceptUserById(slotProps.data.id)"/>
                </template>
            </Column>
            <Column headerStyle="width: 80px">
                <template #body="slotProps">
                    <CustomButton label="Edytuj" @click="navigateToEditUser(slotProps.data.id)"/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchUser,
        searchUserCount,
        acceptUser,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "UserTable",
        components: {
            CustomButton,
            Column,
            DataTable,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria", "update:modelValue"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchUser({body: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchUserCount({body: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            navigateToEditUser(id) {
                this.$router.push(`/user/edit/${id}`);
            },

            computedSystemRole(systemRoles) {
                const computedSystemRoles = systemRoles.map((systemRole) => {
                    if (systemRole === "ADMIN") return "Administrator";
                    if (systemRole === "MAIN_COORDINATOR") return "Koordynator główny";
                    if (systemRole === "NATIONAL_COORDINATOR") return "Koordynator krajowy";
                    if (systemRole === "REGIONAL_COORDINATOR") return "Koordynator regionalny";
                    if (systemRole === "OBSERVER") return "Obserwator";
                    return "";
                });
                return computedSystemRoles.join(", ");
            },

            acceptUserById(id) {
                acceptUser({id});
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    console.log(value);
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style scoped>

</style>
