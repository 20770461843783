<template>
    <div class="flex flex-column justify-content-between w-full flex-wrap">
        <div class="flex flex-wrap w-11 column-gap-2">
            <CustomSelectOne label="Program" name="program" :items="programs"
                             class="w-1" v-model="newIndicator.program" style="min-width: 115px;"
                             itemLabel="" />
            <CustomSelectOne label="Gatunek" name="species" :items="dicts.programBirds"
                             itemLabel="name" itemValue="" class="w-2"
                             v-model="newIndicator.species" style="min-width: 230px;" />
            <CustomCalendar label="Rok" name="year" class="w-1" dateFormat="yy" view="year"
                            v-model="newIndicator.year" style="min-width: 115px;" />
            <CustomInputNumber label="Wskaźnik liczebności populacji" name="populationIndex"
                               v-model="newIndicator.populationIndex" class="w-2" style="min-width: 230px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy wskaźnika liczebności populacji" name="populationIndexSE"
                               v-model="newIndicator.populationIndexSE" class="w-4" style="min-width: 370px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Liczebność populacji" name="populationSize"
                               v-model="newIndicator.populationSize" class="w-2" style="min-width: 230px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Proporcja par z udanym lęgiem" name="propOfPairsWithSucBread"
                               v-model="newIndicator.propOfPairsWithSucBread" class="w-2" style="min-width: 230px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy proporcji par z udanym lęgiem"
                               name="propOfPairsWithSucBreadSE"
                               v-model="newIndicator.propOfPairsWithSucBreadSE" class="w-4" style="min-width: 380px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Średnia liczba piskląt / lęg" name="averageNumOfChicksPerHatching"
                               v-model="newIndicator.averageNumOfChicksPerHatching" class="w-2"
                               style="min-width: 230px;" :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy średniej liczby piskląt / lęgu"
                               name="averageNumOfChicksPerHatchingSE"
                               v-model="newIndicator.averageNumOfChicksPerHatchingSE" class="w-3"
                               style="min-width: 345px;" :maxDigits="99" />
            <CustomInputNumber label="Średnia liczba piskląt / lęg udany"
                               name="averageNumOfChicksPerSucHatching"
                               v-model="newIndicator.averageNumOfChicksPerSucHatching" class="w-3"
                               style="min-width: 345px;" :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy średniej liczby piskląt / lęgu udanego"
                               name="averageNumOfChicksPerSucHatchingSE"
                               v-model="newIndicator.averageNumOfChicksPerSucHatchingSE" class="w-4"
                               style="min-width: 400px;" :maxDigits="99" />
            <CustomInputNumber label="Liczba zasiedlonych kwadratów" name="numberOfSettledSquares"
                               v-model="newIndicator.numberOfSettledSquares" class="w-3" style="min-width: 345px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Rozpowszechnienie" name="prevalence"
                               v-model="newIndicator.prevalence" class="w-2" style="min-width: 230px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy rozpowszechnienia" name="prevalenceSE"
                               v-model="newIndicator.prevalenceSE" class="w-3" style="min-width: 345px;"
                               :maxDigits="99" />
            <CustomInputNumber label="Wyróżnik obszaru" name="areal" v-model="newIndicator.areal" class="w-2"
                               style="min-width: 230px;" :maxDigits="99" />
            <CustomInputText label="Źródło danych" name="dataSource"
                             v-model="newIndicator.dataSource" class="w-2" style="min-width: 230px;" />
        </div>
        <div class="flex justify-content-end w-1 align-self-end mb-3">
            <CustomButton label="Dodaj" name="addIndicator" @click="addIndicator" style="min-width: 74px;" />
        </div>
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {
        addDataIndicator,
    } from "@/swagger/vue-api-client";

    export default {
        name: "AddIndicator",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomCalendar,
            CustomButton,
            CustomInputText,
        },

        props: {
            programs: {
                type: Array,
                default: () => [],
            },
            dicts: {
                type: Object,
                default: () => {},
            },
        },

        emits: ["refreshTable"],

        data() {
            return {
                newIndicator: {
                    program: null,
                    species: null,
                    year: null,
                    areal: null,
                    numberOfSettledSquares: null,
                    populationSize: null,
                    prevalence: null,
                    prevalenceSE: null,
                    populationIndexSE: null,
                    propOfPairsWithSucBreadSE: null,
                    averageNumOfChicksPerHatchingSE: null,
                    averageNumOfChicksPerSucHatchingSE: null,
                    populationIndex: null,
                    propOfPairsWithSucBread: null,
                    averageNumOfChicksPerHatching: null,
                    averageNumOfChicksPerSucHatching: null,
                    dataSource: null,
                },
            };
        },

        methods: {
            addIndicator() {
                const indicatorRequest = {
                    program: this.newIndicator.program,
                    speciesId: this.newIndicator.species.id,
                    year: this.newIndicator.year.getFullYear(),
                    areal: this.newIndicator.areal,
                    numberOfSettledSquares: this.newIndicator.numberOfSettledSquares,
                    populationSize: this.newIndicator.populationSize,
                    prevalence: this.newIndicator.prevalence,
                    prevalenceSE: this.newIndicator.prevalenceSE,
                    populationIndexSE: this.newIndicator.populationIndexSE,
                    propOfPairsWithSucBreadSE: this.newIndicator.propOfPairsWithSucBreadSE,
                    averageNumOfChicksPerHatchingSE: this.newIndicator.averageNumOfChicksPerHatchingSE,
                    averageNumOfChicksPerSucHatchingSE: this.newIndicator.averageNumOfChicksPerSucHatchingSE,
                    populationIndex: this.newIndicator.populationIndex,
                    propOfPairsWithSucBread: this.newIndicator.propOfPairsWithSucBread,
                    averageNumOfChicksPerHatching: this.newIndicator.averageNumOfChicksPerHatching,
                    averageNumOfChicksPerSucHatching: this.newIndicator.averageNumOfChicksPerSucHatching,
                    dataSource: this.newIndicator.dataSource,
                };
                addDataIndicator({body: [indicatorRequest]})
                    .then(() => {
                        this.$emit("refreshTable");
                        this.newIndicator = {
                            program: null,
                            species: null,
                            year: null,
                            areal: null,
                            numberOfSettledSquares: null,
                            populationSize: null,
                            prevalence: null,
                            prevalenceSE: null,
                            populationIndexSE: null,
                            propOfPairsWithSucBreadSE: null,
                            averageNumOfChicksPerHatchingSE: null,
                            averageNumOfChicksPerSucHatchingSE: null,
                            populationIndex: null,
                            propOfPairsWithSucBread: null,
                            averageNumOfChicksPerHatching: null,
                            averageNumOfChicksPerSucHatching: null,
                            dataSource: null,
                        };
                    });
            },
        },
    };
</script>

<style lang="scss">
</style>
