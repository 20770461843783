<template>
    <DictListViewTemplate name="transect" liczbaMnoga="Transekty" liczbaPojedyncza="Transekt" biernik="transekt"
                          :searchGeobjects="searchTransects" :searchGeobjectsCount="searchTransectsCount"
                          :acceptGeobject="acceptTransect" :acceptGeobjectHistory="acceptTransectHistory"/>
</template>

<script>
    import DictListViewTemplate from "@/views/dict/DictListViewTemplate.vue";
    import {
        acceptTransect,
        acceptTransectHistory,
        searchTransects,
        searchTransectsCount,
    } from "@/swagger/vue-api-client";

    export default {

        name: "TransectDictView",

        components: {
            DictListViewTemplate,
        },

        methods: {
            acceptTransectHistory,
            acceptTransect,
            searchTransectsCount,
            searchTransects,
        },
    };
</script>

<style lang="scss"></style>
