<template>
    <div class="users-view p-3">
        <UserSearchCriteria ref="userSearchCriteria" v-model="searchCriteria" @update:modelValue="search" />
        <UserTable ref="userTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import UserSearchCriteria from "./components/UserSearchCriteria";
    import UserTable from "./components/UserTable";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "UserListView",
        components: {
            UserTable,
            UserSearchCriteria,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.userTable.search();
            },

            // createUser() {
            //     this.$router.push({name: "userCreate"});
            // },

            // clear() {
            //     this.searchCriteria = this.getClearSearchCriteria();
            // },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style scoped>

</style>
