<template>
    <div class="history-email-view p-3">
        <HistoryEmailSearchCriteria v-model="searchCriteria" @update:modelValue="search" class="mb-4"/>
        <HistoryEmailTable ref="historyEmailTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import HistoryEmailSearchCriteria from "./components/HistoryEmailSearchCriteria";
    import HistoryEmailTable from "./components/HistoryEmailTable";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "EmailHistory",
        components: {
            HistoryEmailSearchCriteria,
            HistoryEmailTable,
        },
        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    sendDate: null,
                    recipients: [],
                    subject: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            search() {
                this.$refs.historyEmailTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>
