<template>
    <div v-if="loaded" class="flex justify-content-between">
        <div class="flex gap-2 w-full">
            <CustomInputText label="Nazwa" name="name" v-model="internalValue.name" class="w-3" />
            <CustomMultiSelect v-if="dictType === 'gminy' || dictType === 'powiaty'"
                               v-model="internalValue.wojewodztwa" name="wojewodztwa"
                               itemValue="id" itemLabel="name"
                               :items="geoFilters.wojewodztwa" label="Województwo" class="w-3"
                               :emptyValue="false" @update:modelValue="updateWojewodztwa" />
            <CustomMultiSelect v-if="dictType === 'gminy'"
                               v-model="internalValue.powiaty" name="powiaty"
                               :items="powiaty" label="Powiat" class="w-3"
                               itemValue="id" itemLabel="name" :emptyValue="false"
                               :disabled="internalValue.wojewodztwa.length === 0 && powiaty.length === 0"
                               @update:modelValue="updatePowiaty" />
            <CustomMultiSelect v-if="dictType === 'lesnictwa' || dictType === 'nadlesnictwa'"
                               v-model="internalValue.rdlp" name="rdlp"
                               itemValue="id" itemLabel="name"
                               :items="geoFilters.rdlp" label="RDLP" class="w-3"
                               :emptyValue="false" @update:modelValue="updateRdlp" />
            <CustomMultiSelect v-if="dictType === 'lesnictwa'"
                               v-model="internalValue.nadlesnictwa" name="nadlesnictwa"
                               :items="nadlesnictwa" label="Nadleśnictwo" class="w-3"
                               itemValue="id" itemLabel="name" :emptyValue="false"
                               :disabled="internalValue.rdlp.length === 0 && nadlesnictwa.length === 0"
                               @update:modelValue="updateNadlesnictwa" />
        </div>
        <CustomButton label="Wyczyść" @click="clear" />
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {
        getSelectedGeographies,
    } from "@/swagger/vue-api-client";

    export default {
        name: "AreaDictSearchCriteria",

        components: {
            CustomInputText,
            CustomButton,
            CustomMultiSelect,
        },

        props: {
            modelValue: null,
            dictType: null,
        },

        data() {
            return {
                name: "",
                searchCriteria: SearchCriteria,
                geoFilters: {
                    wojewodztwa: [],
                    rdlp: [],
                },
                loaded: false,
                selectedWoj: [],
                selectedPow: [],
                selectedRdlp: [],
                selectedNadl: [],
            };
        },

        emits: ["update:modelValue", "unselect"],

        beforeMount() {
            if (this.dictType === "gminy" || this.dictType === "powiaty") {
                this.getFiltersData("powiaty", "wojewodztwa");
            }
            if (this.dictType === "lesnictwa" || this.dictType === "nadlesnictwa") {
                this.getFiltersData("nadlesnictwa", "rdlp");
            }
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.name = "";
                this.internalValue.wojewodztwa = [];
                this.internalValue.powiaty = [];
                this.internalValue.rdlp = [];
                this.internalValue.nadlesnictwa = [];
                this.selectedWoj = [];
                this.selectedPow = [];
                this.selectedRdlp = [];
                this.selectedNadl = [];
                this.update();
            },

            getFiltersData(type, filterType) {
                getSelectedGeographies({type})
                    .then((response) => {
                        this.geoFilters[filterType] = response.data;
                        this.loaded = true;
                    });
            },

            updateWojewodztwa() {
                this.selectedWoj = this.geoFilters.wojewodztwa.filter((woj) => this.internalValue.wojewodztwa.includes(woj.id));
                this.update();
            },
            updatePowiaty() {
                this.selectedPow = this.powiaty.filter((pow) => this.internalValue.powiaty.includes(pow.id));
                this.update();
            },
            updateRdlp() {
                this.selectedRdlp = this.geoFilters.rdlp.filter((rdlp) => this.internalValue.rdlp.includes(rdlp.id));
                this.update();
            },
            updateNadlesnictwa() {
                this.selectedNadl = this.nadlesnictwa.filter((nadl) => this.internalValue.nadlesnictwa.includes(nadl.id));
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            powiaty() {
                return this.selectedWoj.flatMap((woj) => woj.sublist);
            },
            nadlesnictwa() {
                return this.selectedRdlp.flatMap((rdlp) => rdlp.sublist);
            },
        },

        watch: {
            internalValue: {
                handler(value) {
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
            geoFilters: {
                handler() {
                    if (this.internalValue.wojewodztwa?.length > 0) {
                        this.updateWojewodztwa();
                    }
                    if (this.internalValue.powiaty?.length > 0) {
                        this.updatePowiaty();
                    }
                    if (this.internalValue.rdlp?.length > 0) {
                        this.updateRdlp();
                    }
                    if (this.internalValue.nadlesnictwa?.length > 0) {
                        this.updateNadlesnictwa();
                    }
                    setTimeout(() => {
                    }, 1000);
                },
                deep: true,
            },
            selectedRdlp: {
                handler(value) {
                    if (value.length === 0) {
                        this.internalValue.nadlesnictwa = [];
                        this.selectedNadl = [];
                    }
                },
                deep: true,
            },
            selectedWoj: {
                handler(value) {
                    if (value.length === 0) {
                        this.internalValue.powiaty = [];
                        this.selectedPow = [];
                    }
                },
                deep: true,
            },
        },
    };
</script>
