<template>
    <div class="flex justify-content-between flex-column">
        <div class="flex gap-3 row-gap-0">
            <div class="flex gap-3 flex-wrap row-gap-0">
                <CustomInputText v-model="internalValue.key" placeholder="Szukaj według nazwy"
                                 label="Kod" class="w-12rem" @update:modelValue="update" name="key" />
            </div>
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "ProgramDictSearchCriteria",

        components: {
            CustomButton,
            CustomInputText,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                programs: null,
            };
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.key = "";
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
