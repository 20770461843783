<template>
    <DataTable :value="enumDict" v-model:editingRows="editingRows" editMode="row" dataKey="id" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @row-edit-save="onRowEditSave" removableSort size="small">
        <Column header="Klucz" field="id" />
        <Column header="Kod" field="code" />
        <Column v-if="$route.params.dict === 'breedingResult'" header="Nazwa" field="name">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="name" />
            </template>
        </Column>
        <Column header="Etykieta" field="label">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="label" />
            </template>
        </Column>
        <Column v-if="$route.params.dict === 'controlType' || $route.params.dict === 'control'"
                header="Początek kontroli" field="controlStart">
            <template #editor="{data,field}">
                <CustomCalendar v-model="data[field]" name="controlStart" />
            </template>
        </Column>
        <Column v-if="$route.params.dict === 'controlType' || $route.params.dict === 'control'"
                header="Koniec kontroli" field="controlEnd">
            <template #editor="{data,field}">
                <CustomCalendar v-model="data[field]" name="controlEnd" />
            </template>
        </Column>
        <Column v-if="$route.params.dict === 'control'"
                header="Termin wprowadzenia wyników do bazy przez obserwatorów"
                field="insertingDataDeadline" style="max-width: 200px;">
            <template #editor="{data,field}">
                <CustomCalendar v-model="data[field]" name="insertingDataDeadline" />
            </template>
        </Column>
        <Column v-if="$route.params.dict === 'control'"
                header="Pierwsza kontrola"
                field="isFirstControl">
            <template #editor="{data,field}">
                <CustomCheckbox v-model="data[field]" name="isFirstControl" />
            </template>
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.isFirstControl) }}
            </template>
        </Column>
        <Column header="Programy" field="supportedTypes" >
            <template #body="{data,field}">
                {{data[field].join(", ")}}
            </template>
        </Column>
        <Column :rowEditor="true" style="text-align: center;" class="w-1"/>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchList, updateDict,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "EnumDictTable",

        components: {
            DataTable,
            Column,
            CustomInputText,
            CustomCalendar,
            CustomCheckbox,
        },

        props: {
            searchCriteria: null,
        },

        data() {
            return {
                enumDict: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                const criteria = {
                    ...this.searchCriteria,
                };

                searchList({dictType: this.$route.params.dict, body: criteria})
                    .then((response) => {
                        this.enumDict = response.data;
                        this.totalRecords = response.data.length;
                        this.loading = false;
                    });
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                updateDict({body: {dictType: this.$route.params.dict, name: newData.id, newLabel: newData.label}})
                    .then(() => {
                        this.enumDict[index] = newData;
                    });
            },

            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
        },
    };
</script>

<style lang="scss">
</style>
