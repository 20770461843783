<template>
    <div>
        <div class="change-password-panel-header">
            <img alt="logo" src="@/assets/logo.png">
        </div>
        <div class="change-password-panel-content">
            <Form @submit="change" v-slot="{isSubmitting}">
                <div class="p-grid">
                    <div class="p-col-12">
                        <h2>Zmiana hasła</h2>
                        <div v-show="!afterChange">
                            Podaj nowe hasło. Hasło zawierać musi co najmniej 8 znaków.
                        </div>
                    </div>
                    <div v-show="!afterChange" class="p-col-12" @keyup.enter="change">
                        <CustomPassword v-model="changePasswordForm.newPassword"
                                        class="input-password" label="Nowe hasło" name="newPassword"
                                        rules="required|validPassword" :feedback="true"/>
                        <CustomPassword v-model="changePasswordForm.repeatPassword" label="Powtórz hasło"
                                        name="repeatPassword"
                                        rules="required|confirmed:@newPassword,Hasła"/>
                    </div>
                    <div v-show="!afterChange" class="p-col-12">
                        <PrimeButton label="Zmień hasło" @click="change" :disabled="isSubmitting"/>
                    </div>
                    <div v-show="afterChange" class="p-col-12">
                        <div v-if="success">
                            Hasło zostało zmienione.
                            <router-link :to="{name: 'login'}">Zaloguj się</router-link>
                        </div>
                        <div v-else>
                            Nie udało się zmienić hasła.
                            <router-link :to="{name: 'remindPassword'}">Spróbuj ponownie</router-link>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import PrimeButton from "primevue/button";
    import {changePassword} from "@/swagger/vue-api-client";
    import CustomPassword from "../components/form/CustomPassword";

    export default {
        name: "ChangePasswordView",
        // eslint-disable-next-line vue/no-reserved-component-names
        components: {Form, CustomPassword, PrimeButton},

        computed: {},

        data() {
            return {
                changePasswordForm: {
                    resetPasswordToken: "",
                    newPassword: "",
                },
                repeatPassword: "",
                afterChange: false,
                success: false,
            };
        },

        mounted() {
            this.changePasswordForm.resetPasswordToken = this.$route.query.resetPasswordToken;
        },

        methods: {
            change() {
                changePassword({body: this.changePasswordForm}).then((response) => {
                    this.success = response.data;
                    this.afterChange = true;
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "assets/scss/change-password";
</style>
