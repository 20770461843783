<template>
    <div class="program-main-info p-3">
        <div class="flex gap-3 pr-6">
            <CustomInputText label="Kod" class="w-3" name="key" v-model="program.key" />
            <CustomInputText label="Nazwa" class="w-3" name="name" v-model="program.name" />
        </div>
        <div class="flex gap-3">
            <CustomCalendar label="Data początkowa" class="w-3" name="startTime"
                            v-model="program.startTime" />
            <CustomCalendar label="Czas upływu kontroli regionalnej" class="w-3" name="regionalDeadline"
                            v-model="program.regionalDeadline" />
            <CustomCalendar label="Czas upływu kontroli krajowej" class="w-3" name="nationalDeadline"
                            v-model="program.nationalDeadline" />
            <CustomCalendar label="Czas upływu głównej kontroli" class="w-3" name="mainDeadline"
                            v-model="program.mainDeadline" />
        </div>
        <h2 class="">Reguły wysyłania e-maili</h2>
        <div class="flex gap-7 mt-5">
            <div class="flex gap-2 align-items-center">
                <CustomInputNumber name="daysBeforeQuantity" class="mb-0" style="width: 64px"
                                   v-model="newEmailRule.before.quantity" />
                <span class="text-style">dni przed: </span>
                <CustomSelectOne name="daysBefore" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="width: 256px"
                                 v-model="newEmailRule.before.emailType" />
                <CustomButton icon="pi pi-plus" @click="addEmailRule('before')" />
            </div>
            <div class="flex gap-2 align-items-center">
                <span class="text-style">w dniu:</span>
                <CustomSelectOne name="onDay" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="width: 256px"
                                 :disabled="noMoreAtDeadlineRule" v-model="newEmailRule.onDay.emailType" />
                <CustomButton v-if="!noMoreAtDeadlineRule" icon="pi pi-plus" @click="addEmailRule('onDay')" />
            </div>
            <div class="flex gap-2 align-items-center">
                <span class="text-style">co</span>
                <CustomInputNumber name="everyXDaysQuantity" class="mb-0" style="width: 64px"
                                   v-model="newEmailRule.after.quantity" />
                <span class="text-style">dni po:</span>
                <CustomSelectOne name="everyXDays" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="width: 256px"
                                 v-model="newEmailRule.after.emailType" />
                <CustomButton icon="pi pi-plus" @click="addEmailRule('after')" />
            </div>
        </div>
    </div>
</template>

<script>
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "ProgramMainInfo",

        components: {
            CustomCalendar,
            CustomInputText,
            CustomInputNumber,
            CustomSelectOne,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Object,
            },
        },

        data() {
            return {
                emailTypes: [],
                newEmailRule: {
                    before: {
                        quantity: null,
                        type: "DAYS_BEFORE",
                        emailType: null,
                    },
                    onDay: {
                        quantity: "---",
                        type: "AT_DEADLINE",
                        emailType: null,
                    },
                    after: {
                        quantity: null,
                        type: "DAYS_AFTER",
                        emailType: null,
                    },
                },
                noMoreAtDeadlineRule: false,
            };
        },

        beforeMount() {
            this.emailTypes = [
                {code: "NATIONAL_COORDINATOR_APPROVED_CAST", label: "[MPP3] Koordynator krajowy zaakceptował obsadę"},
                {code: "NATIONAL_COORDINATOR_APPROVED_PROGRAM_RESULTS", label: "rodzaj 2"},
                {code: "NATIONAL_COORDINATOR_ASSIGNED_TO_PROGRAM", label: "rodzaj 3"},
                {code: "REMINDER_TO_ASSIGN_CAST", label: "rodzaj 4"},
                {code: "MAIN_COORDINATOR_APPROVED_CAST", label: "rodzaj 5"},
                {code: "REMINDER_ABOUT_END_OF_CONTROL_IN_TERRAIN", label: "rodzaj 6"},
                {code: "REGIONAL_COORDINATOR_APPROVED_PROGRAM_RESULTS", label: "rodzaj 7"},
                {code: "MAIN_COORDINATOR_APPROVED_PROGRAM_RESULTS", label: "rodzaj 8"},
                {code: "REMINDER_ABOUT_DEADLINE_OF_INSERTING_DATA_TO_DATABASE", label: "rodzaj 9"},
                {code: "OBSERVER_SENT_DATA_FOR_VERIFICATION", label: "rodzaj 10"},
                {code: "REMINDER_ABOUT_RESULT_NATIONAL_APPROVAL_DATE", label: "rodzaj 11"},
                {code: "REMINDER_ABOUT_RESULT_REGIONAL_APPROVAL_DATE", label: "rodzaj 12"},
                {code: "RETURNED_FORM_FOR_CORRECTION", label: "rodzaj 13"},
                {code: "COORDINATOR_APPROVED_FORM", label: "rodzaj 14"},
            ];
        },

        methods: {
            addEmailRule(type) {
                if (type === "after" || type === "before") {
                    if (ValidateUtils.validateNotEmpty(this.newEmailRule[type], ["quantity", "emailType"])) {
                        this.program.emailSendingRules.push(this.newEmailRule[type]);
                        this.newEmailRule[type] = {
                            quantity: null,
                            type: type === "after" ? "DAYS_AFTER" : "DAYS_BEFORE",
                            emailType: null,
                        };
                    }
                }
                if (type === "onDay") {
                    let check = false;
                    if (this.program.emailSendingRules.length > 0) {
                        this.program.emailSendingRules.forEach((esr) => {
                            if (esr.type === "AT_DEADLINE") {
                                check = true;
                            }
                        });
                    }
                    if (!check && ValidateUtils.validateNotEmpty(this.newEmailRule[type], ["emailType"])) {
                        this.program.emailSendingRules.push(this.newEmailRule[type]);
                        this.newEmailRule[type] = {
                            quantity: null,
                            type: "AT_DEADLINE",
                            emailType: null,
                        };
                    }
                }
            },
        },

        computed: {
            program: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            program: {
                handler(value) {
                    if (value.emailSendingRules.length > 0) {
                        value.emailSendingRules.forEach((esr) => {
                            if (esr.type === "AT_DEADLINE") {
                                this.noMoreAtDeadlineRule = true;
                                return;
                            }
                            this.noMoreAtDeadlineRule = false;
                        });
                    } else {
                        this.noMoreAtDeadlineRule = false;
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .program-main-info {
        .text-style {
            color: var(--text-color-secondary);
            font-weight: 600;
        }
    }
</style>
