<template>
    <div class="form-map mb-5 mt-0">
        <MapMultiMarkers mapId="mainMap" markerPopup markerPopupFull :positions="positions"
                         :field="field" :subfield="subfield" :markerTypes="markerTypes" openPopupByDefault :isOn="false"
                         openPopupOnClick @dragendMarker="updateCoordinates" :key="disabled" :onlyStepOne="onlyStepOne">
            <template v-slot:markerPopup="slotProps">
                <div class="marker-popup-header" :class="computedHeaderClass(slotProps.position)">
                    {{ slotProps.position.name }}
                </div>
            </template>
            <template v-slot:markerPopupFull="slotProps">
                <div class="marker-popup-header" :class="computedHeaderClass(slotProps.position)">
                    {{ slotProps.position.name }}
                </div>
                <div class="marker-popup-content" style="min-width: 290px">
                    <div v-if="programCode !== 'MCH'" class="checkbox-container">
                        <Checkbox id="notAudited-checkbox" v-model="slotProps.position.notAudited"
                                  :disabled="disabled" :binary="true"/>
                        <label for="notAudited-checkbox" :style="disabled ? {color: '#6c757d60'} : ''">
                            Kontrola nie została wykonana
                        </label>
                    </div>
                    <div v-if="slotProps.position.notAudited">
                        <CustomSelectOne label="Przyczyna braku kontroli" name="notAuditedReason"
                                         v-model="slotProps.position.notAuditedReason" :disabled="disabled"
                                         :required="slotProps.position.notAudited" :showClear="false"
                                         :items="internalControlNotRealizedReason" class="mt-4"
                                         :filter="false" />
                        <CustomInputTextArea v-model="slotProps.position.notAuditedNotes" name="notAuditedNotes"
                                             label="Uwagi" :disabled="disabled" />
                    </div>
                    <div>
                        <p v-if="canUndoReport(slotProps.position)" class="cursor-pointer w-max"
                           @click="undoReport(slotProps.position.positionId,slotProps.index)">
                            Cofnij zgłoszenie
                        </p>
                        <p v-if="canDelete(slotProps.position)" class="cursor-pointer w-max"
                           @click="callToDelete(slotProps.position.positionId,slotProps.index)">
                            Usuń stanowisko
                        </p>
                        <p v-else-if="canUndoChange(slotProps.position)" class="cursor-pointer w-max"
                           @click="undoReport(slotProps.position.positionId,slotProps.index)">
                            Cofnij zmianę
                        </p>
                        <p v-else-if="canCallToDelete(slotProps.position)" class="cursor-pointer w-max"
                           @click="callToDelete(slotProps.position.positionId,slotProps.index)">
                            Zgłoś do usunięcia
                        </p>
                        <p v-if="!disabled" v-show="!changeCoordinates[slotProps.index]"
                           class="cursor-pointer w-max" @click="changeDraggable(slotProps.index)">
                            Zgłoś zmianę lokalizacji
                        </p>
                    </div>
                    <div v-if="changeCoordinates[slotProps.index]"
                         class="input-coordinates flex flex-column mt-4 align-items-center">
                        <div class="input-coordinates flex gap-2">
                            <CustomInputNumber class="w-15rem" label="Koordynaty N" name="latitude" :disabled="disabled"
                                               :maxDigits="6"
                                               v-model="positions[slotProps.index].coordinates.latitude"
                                               @input="latitudeNotNull($event,slotProps.index)" />
                            <CustomInputNumber class="w-15rem" label="Koordynaty E" name="longitude" :disabled="disabled"
                                               :maxDigits="6"
                                               v-model="positions[slotProps.index].coordinates.longitude"
                                               @input="longitudeNotNull($event,slotProps.index)" />
                        </div>
                        <CustomButton label="Zapisz nową lokalizację" @click="saveNewPosition(slotProps.index)"
                                      bgColor="var(--secondary-color)" color="var(--surface-a)"/>
                    </div>
                </div>
            </template>
        </MapMultiMarkers>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";
    import {
        deletePosition,
        editGeographicalHistoryInForm,
        editPositionHistory,
        revertGeographicalHistoryInForm,
        revertPosition,
    } from "@/swagger/vue-api-client";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "PositionsMap",

        components: {
            CustomButton,
            CustomSelectOne,
            MapMultiMarkers,
            Checkbox,
            CustomInputNumber,
            CustomInputTextArea,
        },

        props: {
            modelValue: {
                type: Array,
            },
            field: null,
            subfield: null,
            disabled: {
                type: Boolean,
                default: false,
            },
            programCode: null,
            onlyStepOne: {
                type: Boolean,
                default: false,
            },
            dynamicPositions: {
                type: Boolean,
                default: false,
            },
            objectBeingChanged: {
                type: String,
                default: "stanowisko",
            },
        },

        data() {
            return {
                markerTypes: new Map(),
                changeCoordinates: [],
                internalControlNotRealizedReason: [],
            };
        },

        inject: ["dicts"],

        emits: ["update:modelValue"],

        beforeMount() {
            if (this.dicts.controlNotRealizedReason?.length > 0) {
                this.internalControlNotRealizedReason = this.dicts.controlNotRealizedReason.filter((cNRR) => cNRR.info === undefined || cNRR.info == null);
            }
            for (let i = 0; i < this.positions.length; i += 1) {
                this.changeCoordinates.push(false);
            }
            this.setMarkerTypes(this.positions);
        },

        methods: {
            latitudeNotNull(val, index) {
                if (val == null) {
                    this.positions[index].coordinates.latitude = 0;
                }
            },
            longitudeNotNull(val, index) {
                if (val == null) {
                    this.positions[index].coordinates.longitude = 0;
                }
            },
            setMarkerType(i) {
                const markerType = {
                    color: "grey",
                    size: "regular",
                    popup: true,
                    draggable: this.changeCoordinates[i],
                };
                const status = (this.objectBeingChanged === "punkt") ? "ACTIVE" : this.positions[i].status;
                if (status === "REPORTED_REMOVE") markerType.color = "lightGrey";
                if (status === "ACTIVE" && !this.positions[i].accepted) markerType.color = "grey";
                if (status === "ACTIVE" && this.positions[i].accepted) markerType.color = "red";
                if (status === "REPORTED_RESTORE" || status === "CREATED") markerType.color = "grey";
                this.markerTypes.set(this.positions[i].positionId, markerType);
            },
            setMarkerTypes(positions) {
                // creates actual map which keeps for every positionId an array with icon color and icon size
                // colors can be red, green, grey or lightGrey
                // sizes can be regular or small
                positions.forEach((position, index) => this.setMarkerType(index));
            },
            changeDraggable(i) {
                const markerType = this.markerTypes.get(this.positions[i].positionId);
                markerType.draggable = !markerType.draggable;
                this.markerTypes.set(this.positions[i].positionId, markerType);
                this.changeCoordinates[i] = markerType.draggable;
            },
            canUndoChange(position) {
                return ((position.status !== "ARCHIVED" && position.status !== "ACTIVE") || !position.accepted)
                    && !position.notAudited && !this.disabled;
            },
            canUndoReport(position) {
                return this.dynamicPositions && (position.status === "REPORTED_REMOVE" || position.status === "REPORTED_RESTORE")
                    && !position.notAudited && !this.disabled;
            },
            canDelete(position) {
                return this.dynamicPositions && position.status === "CREATED" && !position.notAudited && !this.disabled;
            },
            canCallToDelete(position) {
                return this.dynamicPositions && !position.notAudited && position.status === "ACTIVE" && !this.disabled;
            },
            undoReport(positionId, index) {
                const parameters = {programCode: this.programCode, formId: this.$route.params.id};
                let revertApiMethod = revertGeographicalHistoryInForm;
                if (this.dynamicPositions) {
                    revertApiMethod = revertPosition;
                    parameters.positionId = positionId;
                } else {
                    parameters.geographicalObjectId = positionId;
                }
                revertApiMethod(parameters)
                    .then((response) => {
                        console.log(response);
                        if (response.data == null || response.data === "") {
                            this.positions.splice(index, 1);
                            this.markerTypes.delete(positionId);
                        } else {
                            this.positions[index].coordinates = response.data.coordinates;
                            this.positions[index].accepted = response.data.accepted;
                            this.positions[index].status = response.data.status;
                            this.setMarkerType(index);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response?.data != null ? error.response?.data : error.message,
                            life: 3000,
                        });
                    });
            },
            callToDelete(positionId, index) {
                deletePosition({programCode: this.programCode, formId: this.$route.params.id, positionId})
                    .then((response) => {
                        if (response.data === null || response.data === "") {
                            this.positions.splice(index, 1);
                            this.markerTypes.delete(positionId);
                        } else {
                            this.positions[index] = response.data;
                            this.setMarkerType(index);
                        }
                    })
                    .catch((error) => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response?.data != null ? error.response?.data : error.message,
                            life: 3000,
                        });
                    });
            },
            computedHeaderClass(position) {
                if (position.status === "CREATED" || position.status === "REPORTED_RESTORE") return "font-italic";
                if (position.status === "REPORTED_REMOVE") return "line-through";
                return "";
            },
            updateCoordinates(coordinates, positionId) {
                for (let i = 0; i < this.positions.length; i += 1) {
                    if (this.positions[i].positionId === positionId) {
                        this.positions[i].coordinates = {latitude: coordinates.lat, longitude: coordinates.lng};
                    }
                }
            },
            saveNewPosition(index) {
                const p = this.positions[index];
                const body = {
                    coordinates: {
                        latitude: p.coordinates.latitude,
                        longitude: p.coordinates.longitude,
                    },
                };
                let editApiMethod = editGeographicalHistoryInForm;
                if (this.dynamicPositions) {
                    editApiMethod = editPositionHistory;
                    body.positionId = p.positionId;
                } else {
                    body.objectId = p.positionId;
                    body.type = this.objectBeingChanged === "stanowisko" ? "position" : "point";
                }
                editApiMethod({programCode: this.programCode, formId: this.$route.params.id, body: body})
                    .then((response) => {
                        console.log(response);
                        this.positions[index].accepted = response.data.accepted;
                        this.positions[index].status = response.data.status;
                        this.changeDraggable(index);
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zgłoszono zmianę lokalizacji",
                            life: 3000,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response?.data != null ? error.response?.data : error.message,
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            positions: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            positions: {
                handler(value) {
                    this.$emit("update:modelValue", value);
                    this.setMarkerTypes(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .form-map {
        width: 100%;
        height: calc(100vh - 600px);
        margin-top: -32px;
        #mainMap {
            position: relative;
            height: calc(100vh - 575px);
        }
    }
    @media screen and (max-height: 750px) {
            .form-map {
            height: 235px;
            #mainMap {
                height: 250px;
            }
        }
    }
</style>
