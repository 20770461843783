<template>
    <DictDetailsViewTemplate :acceptGeobject="acceptPoint" :acceptGeobjectHistory="acceptPointHistory"
                             :getGeobjectData="getPointById" :createGeobjectFunction="createPoint"
                             :createGeobjectHistoryFunction="createPointHistory" :updateGeobjectHistoryFunction="updatePointHistory"
                             :updateGeobjectFunction="updatePoint" :deleteGeobjectHistoryFunction="deletePointHistoryById"
                             :getAvailableControlVersionsFunction="getAvailablePointControlVersions"
                             name="point" biernik="punkt" dopelniacz="punktu"/>
</template>

<script>
    import {
        acceptPoint,
        acceptPointHistory,
        createPoint,
        createPointHistory,
        deletePointHistoryById,
        getAvailablePointControlVersions,
        getPointById,
        updatePoint,
        updatePointHistory,
    } from "@/swagger/vue-api-client";
    import DictDetailsViewTemplate from "@/views/dict/DictDetailsViewTemplate.vue";

    export default {
        name: "PointDetailsView",

        components: {
            DictDetailsViewTemplate,
        },

        methods: {
            updatePointHistory,
            createPointHistory,
            deletePointHistoryById,
            updatePoint,
            getAvailablePointControlVersions,
            createPoint,
            getPointById,
            acceptPointHistory,
            acceptPoint,
        },
    };
</script>
