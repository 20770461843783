<template>
    <div class="monitoring-program-controls-table">
        <DataTable :lazy="true" :value="programControls" dataKey="id" :paginator="true"
                   v-model:rows="page.limit" v-model:first="page.offset"
                   :totalRecords="totalRecords"
                   @page="onPage" @sort="onPage" removableSort selectionMode="single"
                   :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small"
                   v-model:filters="searchCriteria"
                   filter-display="menu" @update:filters="search()"
                   @rowClick="goToLink">
            <Column field="observer" header="Imię i nazwisko" sortable
                    :showFilterMatchModes="false" filter-menu-class="w-16rem"
                    filterField="observerName" sortField="observer.name">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Obserwator" class="flex-1 w-0" :items="observers"
                                     filter itemLabel="name" itemValue="name"
                                     v-model="filterModel.value" name="observerName" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.observer.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column field="field" header="Powierzchnia" sortable
                    filterField="fieldName" :showFilterMatchModes="false" filter-menu-class="w-16rem"
                    sortField="field.name">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Powierzchnia" name="fieldName" v-model="filterModel.value"
                                     :items="fields" filter
                                     itemLabel="name" itemValue="name" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.field.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Kontrola" field="control" sortable v-if="dictControlNameVisible"
                    filterField="controlName" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomInputText label="Kontrola" class="flex-1 w-0" v-model="filterModel.value"
                                     name="controlName" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.control.label }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column v-if="programType === 'POSITION'" header="Stanowiska (Powierzchnia)"
                    filterField="positions" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomInputText label="Stanowiska" class="flex-1 w-0" v-model="filterModel.value"
                                     name="positions" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.positions }} ({{ slotProps.data.fieldName }})
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Data graniczna" field="deadline" sortable>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.deadline }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Pozostało" field="daysLeft">
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div v-if="(!slotProps.data.controlState || slotProps.data.controlState === ''
                            || slotProps.data.controlState.includes('DRAFT')) && slotProps.data.daysLeft">
                            <template v-if="slotProps.data.daysLeft > 1 || slotProps.data.daysLeft === 0">
                                Pozostało {{ slotProps.data.daysLeft }} dni
                            </template>
                            <template v-else-if="slotProps.data.daysLeft === 1">
                                Pozostał 1 dzień
                            </template>
                            <template v-else-if="slotProps.data.daysLeft === -1">
                                Przekroczono termin o 1 dzień
                            </template>
                            <template v-else>
                                Przekroczono termin o {{-slotProps.data.daysLeft}} dni
                            </template>
                        </div>
                        <div v-else>
                            Nie dotyczy
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column field="controlState" header="Status" sortable
                    filterField="controlState" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Status" class="flex-1 w-0" :items="states" filter itemValue="value"
                                     v-model="filterModel.value" name="controlState" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <span v-if="slotProps.data.controlState === 'APPROVED'"
                              style="background-color: rgba(0, 128, 0, 0.1); color: green; font-size: 12px;"
                              class="control-status">
                            {{ getStateLabel(slotProps.data.controlState,slotProps.data) }}
                        </span>
                        <span v-else>
                            {{ getStateLabel(slotProps.data.controlState,slotProps.data) }}
                        </span>
                    </router-link>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchProgramControlsCount,
        searchProgramControls,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {ControlState} from "@/utils/ControlState";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "ControlTable",

        components: {
            DataTable,
            Column,
            FilterClear,
            FilterApply,
            CustomSelectOne,
            CustomInputText,
        },

        props: {
            modelValue: null,
        },

        inject: ["programCode", "programEditionState", "programType", "observers", "fields"],

        data() {
            return {
                programControls: [],
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaControls(this.$route.params.id),
                page: SearchCriteria.getClearPage(),
                states: ControlState.getControlStates(),
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[controls]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[controls]`);
            }
        },

        mounted() {
            this.search();
        },

        methods: {
            goToLink(data) {
                this.$router.push(this.getRouterLink(data.data));
            },
            search(text = "") {
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                criteria.textAnywhere = text;
                const parameters = {id: this.$route.params.id, body: criteria};
                searchProgramControls(parameters)
                    .then((response) => {
                        this.programControls = response.data;
                        return searchProgramControlsCount(parameters);
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            getRouterLink(rowData) {
                // if (rowData.dictControlId === "0P") {
                //     return "";
                // }
                if (rowData.formId != null) {
                    let path;
                    if (rowData.control.code === "0T") {
                        path = `/form-zero/transect/${rowData.formId}`;
                    } else if (rowData.control.code === "0P") {
                        path = `/form-zero/point/${rowData.formId}`;
                    } else {
                        path = `/form/${this.programCode}/${rowData.formId}`;
                        if (rowData.control.code !== null) path += `?controlCode=${rowData.control.code}`;
                    }
                    return path;
                }
                const query = {
                    observerID: rowData.programObserverId,
                    fieldId: rowData.field.id,
                    programCode: this.programCode,
                };
                if (rowData.control.code !== null) {
                    query.controlCode = rowData.control.code;
                }
                return {
                    name: "toCreateForm",
                    query,
                };
            },
            getStateLabel(state) {
                // if (data.dictControlId === "0P") return "Brak punktów, dodaj je w słowniku";
                return ControlState.getStateLabel(state);
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[controls]`, value);
                },
                deep: true,
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaControlsFilterDescription();
            },
            canAddForm() {
                return ((this.programEditionState === "ENTERING_DATA"
                    || this.programEditionState === "RESULTS_WAITING_FOR_NATIONAL_APPROVAL"
                    || this.programEditionState === "RESULTS_WAITING_FOR_MAIN_APPROVAL")
                    && (SystemRole.isRegionalCoordinator() || SystemRole.isNationalCoordinator()
                        || SystemRole.isObserver())) || SystemRole.isMainCoordinator();
            },
            dictControlNameVisible() {
                return this.programCode !== "MPS";
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";

    .control-status {
        color: var(--surface-a);
        display: block;
        padding: 6px 8px;
        width: fit-content;
        text-align: center;
        border-radius: 6px;
    }

    .monitoring-program-controls-table {
        .p-datatable .p-datatable-tbody > tr > td {
            padding: 0;
        }
        a {
            color: var(--text-color);
            padding: 1rem;
            display: block;
        }
    }
</style>
