/* eslint-disable */
import {DateUtils} from "@/utils/DateUtils";
import _ from "lodash";

export const ValidateUtils = {

    validateNotEmpty(item, properties) {
        if (item == null) return false;
        let notEmpty = true;
        properties.forEach((prop) => {
            if (item[prop] == null || item[prop] === "") notEmpty = false;
        });
        return notEmpty;
    },

    validateIsDateInRange(controlDate, controlStart, controlEnd) {
        let isInRange = true;
        if (controlDate !== null && controlDate !== ""
            && (DateUtils.isDateEarlier(controlDate, controlStart)
                || DateUtils.isDateEarlier(controlEnd, controlDate))) {
            isInRange = false;
        }
        return isInRange;
    },

    validateCorrectSpecies(item, properties) {
        let isCorrect = false;
        properties.forEach((prop) => {
            if (typeof item[prop] === "object") isCorrect = true;
        });
        return isCorrect;
    },

    validateNotAudited(positions, mianownik) {
        const errors = [];
        for (let i = 0; i < positions.length; i += 1) {
            if (positions[i].notAudited === true) {
                if (positions[i].notAuditedReason == null || positions[i].notAuditedReason === "") {
                    errors.push(mianownik + ": " + (i+1) + ". " + mianownik + " nieskontrolowany, podaj powód.");
                }
            }
        }
        return errors;
    },

    validateListItemsIfCounted(list, notCounted, requiredProperties) {
        let isCorrect = true;
        if ((list == null || list.length === 0) && !(notCounted === true)) {
            isCorrect = false;
        } else {
            for (let j = 0; j < list.length; j += 1) {
                // eslint-disable-next-line
                isCorrect = this.validateNotEmpty(list[j], requiredProperties) && isCorrect;
            }
        }
        return isCorrect;
    },

    validateBirds(birds, noBirds, requiredProperties) {
        return this.validateListItemsIfCounted(birds, noBirds, requiredProperties);
    },

    flattenData(positions, type) {
        for (let i = 0; i < positions.length; i += 1) {
            const position = positions[i];
            position.accepted = position[type].accepted;
            position.name = position[type].name;
            position.commonName = position[type].commonName;
            position.coordinates = position[type].coordinates;
            position.line = position[type].line;
            position.status = position[type].status;
            position.ordinal = position[type].ordinal;
            position[`${type}HistoryId`] = position[type][`${type}HistoryId`];
            position[`${type}Id`] = position[type][`${type}Id`];
            if (type === "point") position.positionId = position[type][`${type}Id`];
            delete position[type];
        }
    },

    getFormRequest(form, program, type, controls = false) {
        const request = {
            noFormControl: form.noFormControl,
            noFormControlReason: form.noFormControlReason,
            noFormControlNotes: form.noFormControlNotes,
            program: program,
        };
        if (type != null) request[type] = [];
        if (form.noFormControl) {
            if (type === "positions") {
                if (controls) {
                    form.positions.forEach((position) => {
                        request.positions.push({positionId: position.positionId, controls: []});
                        for (let i = 0; i < request.positions.length; i += 1) {
                            if (position.positionId === request.positions[i].positionId) {
                                position.controls.forEach((c) => {
                                    request.positions[i].controls.push({id: c.id});
                                });
                            }
                        }
                    });
                } else {
                    form.positions.forEach((position) => {
                        request.positions.push({positionId: position.positionId});
                    });
                }
            }
            if (type === "points") {
                if (controls) {
                    form.points.forEach((point) => {
                        request.points.push({pointId: point.pointId, controls: []});
                        for (let i = 0; i < request.points.length; i += 1) {
                            if (point.pointId === request.points[i].pointId) {
                                point.controls.forEach((c) => {
                                    request.points[i].controls.push({id: c.id});
                                });
                            }
                        }
                    });
                } else {
                    form.points.forEach((point) => {
                        request.points.push({pointId: point.pointId});
                    });
                }
            }
            if (type === "formTransects") {
                if (controls) {
                    form.formTransects.forEach((transect) => {
                        request.formTransects.push({transectId: transect.transectId, controls: []});
                        for (let i = 0; i < request.formTransects.length; i += 1) {
                            if (transect.transectId === request.formTransects[i].transectId) {
                                transect.controls.forEach((c) => {
                                    request.formTransects[i].controls.push({id: c.id});
                                });
                            }
                        }
                    });
                } else {
                    form.formTransects.forEach((transect) => {
                        request.formTransects.push({transectId: transect.transectId});
                    });
                }
            }
        };
        return request;
    },

    copyFieldValues(from, to, fields) {
        fields.forEach((field) => {
            to[field] = from[field];
        });
        return to;
    },

    formatControlDateStartTimeAndEndTime(data) {
        if (data != null) {
            data.controlDate = DateUtils.formatFormDate(data.controlDate);
            data.startTime = DateUtils.formatFormTime(data.startTime);
            data.endTime = DateUtils.formatFormTime(data.endTime);
        }
    },

    formatTimesValue(data, fields) {
        if (data != null) {
            fields.forEach((field) => {
                data[field] = DateUtils.formatFormTime(data[field]);
            });
        }
    },

    validateControls(form, controlNumber, controlProperties, array = false, birdsInControl) {
        let isCorrect = true;
        for (let i = array ? 0 : 1; i <= controlNumber; i += 1) {
            let controlIsCorrect = true;
            // const c = form[`control${i}`];
            const c = array ? form.controls[i] : form[`control${i}`];
            if (c == null) controlIsCorrect = false;
            if (c.noControl === true) {
                controlIsCorrect = ValidateUtils.validateNotEmpty(c, ["noControlReason"]);
            } else {
                controlIsCorrect = ValidateUtils.validateNotEmpty(c, controlProperties);
            }
            if (birdsInControl?.length > 0) {
                controlIsCorrect = this.validateBirds(c.birds, c.noBirds, birdsInControl);
            }
            if (c.error) delete c.error; // remove previous checks
            if (!controlIsCorrect) {
                c.error = true;
                isCorrect = false;
            }
        }
        return isCorrect;
    },

    someGeobjectWasAudited(positions, noFormControl) {
        const auditedList = positions.filter((p) => (p.notAudited !== true));
        return !noFormControl && auditedList.length > 0;
    },

    validateNoFormControl(form) {
        if (form.noFormControl) {
            return this.validateNotEmpty(form, ["noFormControlReason"]);
        } else {
            return true;
        }
    },

    getGeobjectRequestWithHabitatData(geobject, controlDate, programCode) {
        const request = _.cloneDeep(geobject);
        if (geobject.habitatDataToChange != null) {
            request.habitatData = _.cloneDeep(geobject.habitatDataToChange);
            request.habitatData.controlDate = controlDate;
            request.habitatData.program = programCode;
            delete request.habitatDataToChange;
        } else {
            delete request.habitatData;
            delete request.habitatDataToChange;
        }
        return request;
    },
};
