<template>
    <DataTable :value="birds" v-model:editingRows="editingRows" editMode="row" dataKey="id" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @page="onPage" @sort="onPage" @row-edit-save="onRowEditSave"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown" removableSort
               currentPageReportTemplate="Pozycji na stronie" paginator
               :rows="10" :rowsPerPageOptions="[5,10,20,50]" size="small"
               v-model:rows="page.limit" v-model:first="page.offset">
        <Column header="Kod gatunku (MPPL)" field="ident1">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="ident1" />
            </template>
        </Column>
        <Column header="Kod gatunku (obrączkarski)" field="ident2">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="ident2" />
            </template>
        </Column>
        <Column header="Identyfikator EURING" field="identEuring">
            <template #editor="{data,field}">
                <CustomInputNumber v-model="data[field]" name="identEuring" />
            </template>
        </Column>
        <Column header="Indeks" field="indexVal">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="indexVal" />
            </template>
        </Column>
        <Column header="Nazwa polska" field="namePl">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="namePl" />
            </template>
        </Column>
        <Column header="Nazwa angielska" field="nameEn">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameEn" />
            </template>
        </Column>
        <Column header="Nazwa łacińska" field="nameLat">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameLat" />
            </template>
        </Column>
        <Column header="Programy" field="programs">
            <template #body="{data,field}">
                {{ ListUtils.join(data[field],"program") }}
            </template>
        </Column>
        <Column header="Program priorytetowy" field="priorityProgram">
        </Column>
        <Column :rowEditor="true" style="text-align: center; padding-left: 0; padding-right: 0;" class="w-1"/>
        <Column style="padding-left: 0; padding-right: 0;">
            <template #body="slotProps">
                <div>
                    <CustomButton icon="pi pi-pencil" label="Więcej" @click="goToEditBirds(slotProps.data.id)" />
                </div>
            </template>
        </Column>
        <Column style="width: 4.1666%; text-align: center; padding-left: 0; padding-right: 0;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteMammalDict(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {searchBirds, countBirds, updateBird} from "@/swagger/vue-api-client";
    import ListUtils from "../../utils/ListUtils";

    export default {
        name: "BirdsDictTable",
        computed: {
            ListUtils() {
                return ListUtils;
            },
        },

        components: {
            DataTable,
            Column,
            CustomInputText,
            CustomInputNumber,
            CustomButton,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                birds: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                page: SearchCriteria.getClearPage(),
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                const criteria = {
                    ...this.searchCriteria,
                    page: this.page,
                };
                searchBirds({body: criteria})
                    .then((response) => {
                        this.birds = response.data;
                        return countBirds({body: criteria});
                    }).then((response) => {
                        this.totalRecords = response.data;
                    });
                this.loading = false;
            },

            onPage(event) {
                this.page.offset = event.first;
                this.page.limit = event.rows;
                this.page.sortField = event.sortField;
                this.page.sortOrder = event.sortOrder;

                this.search();
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                updateBird({id: newData.id, body: newData})
                    .then(() => {
                        this.birds[index] = newData;
                    });
            },

            goToEditBirds(id) {
                this.$router.push(`/bird-dict/${id}`);
            },

            deleteBirdDict(item) {
                const index = this.birds.indexOf(item);
                this.birds.splice(index, 1);
            },
        },
    };
</script>

<style lang="scss">
</style>
