<template>
    <Dialog class="edit-positions-dialog" :header="(create ? 'Utwórz nową' : 'Edytuj') + ` historię ${dopelniacz}`"
            :draggable="false" v-model:visible="internalVisible" :closable="false">
        <div class="edit-positions-dialog-container flex flex-column">
            <div class="edit-positions-dialog-content flex justify-content-between gap-8">
                <div class="flex flex-column justify-content-between">
                    <div>
                        <div v-if="name === 'transect'" class="mb-4" style="min-width: 500px">
                            <div class="mb-4">Kolejne punkty wyznaczające odcinek:</div>
                            <div v-for="(line,index) in editableLineArray" :key="index"
                                 class="flex gap-3 align-items-center">
                                <div class="mb-3">{{index + 1}}.</div>
                                <CustomInputNumber label="Koordynaty N" v-model="line.latitude"
                                                   @update:modelValue="(v) => updateTransect(v,index,'latitude')"
                                                   :maxDigits="6" name="latitude" :showLabel="true"
                                                   :showErrors="showErrors" required />
                                <CustomInputNumber label="Koordynaty E" v-model="line.longitude"
                                                   @update:modelValue="(v) => updateTransect(v,index,'longitude')"
                                                   :maxDigits="6" name="longitude" :showLabel="true"
                                                   :showErrors="showErrors" required />
                                <div class="flex flex-column mb-3 pr-0 align-items-center">
                                    <CustomIconButton icon="pi pi-chevron-up" small @click="moveBefore(index)" />
                                    <CustomIconButton icon="pi pi-chevron-down" small @click="moveAfter(index)" />
                                </div>
                                <div class="flex flex-column mb-3 pl-0 align-items-center">
                                    <CustomIconButton icon="pi pi-times" small @click="remove(index)" />
                                </div>
                            </div>
                            <div>
                                <CustomButton label="Dodaj punkt" class="justify-content-center" bgColor="#33795B"
                                              color="white" @click="add()" />
                            </div>
                            <div v-if="showErrors && editableLineArray.length < 2" class="mt-3 error-info">
                                Transekt musi być wyznaczony przez co najmniej dwa punkty
                            </div>
                        </div>
                        <div v-else>
                            <CustomInputNumber label="Koordynaty N" v-model="internalItem.coordinates.latitude"
                                               :maxDigits="6" @update:modelValue="updateMap" name="latitude"
                                               required :showErrors="showErrors"/>
                            <CustomInputNumber label="Koordynaty E" v-model="internalItem.coordinates.longitude"
                                               :maxDigits="6" @update:modelValue="updateMap" name="longitude"
                                               required :showErrors="showErrors"/>
                        </div>
                        <CustomCalendar label="Rok" name="year" required dateFormat="yy" v-model="internalItem.year"
                                        view="year"/>
                        <CustomSelectOne id="controlVersion" v-model="internalItem.controlVersion"
                                         :items="controlVersions" label="Wersja kontrolna" name="controlVersion"
                                         required :showErrors="showErrors" itemLabel=""
                                         :filter="false" />
                    </div>
                    <div class="flex gap-3 mt-3">
                        <CustomButton label="Zatwierdź" class="justify-content-center"
                                      bgColor="var(--secondary-color)" color="var(--surface-a)" @click="save" />
                        <CustomButton label="Anuluj" class="justify-content-center" @click="cancel" />
                    </div>
                </div>
                <div class="edit-positions-dialog-content__left">
                    <div class="edit-positions-map" style="height: 400px; width: 600px;">
                        <MapSingleTransect v-if="name === 'transect'" mapId="editTransectMap" v-model="internalItem"
                                           height="400px" :field="field" :deleteCircle="deleteCircle" />
                        <MapSingleMarker v-else v-model="mapCoordinates" mapId="editGeobjectMap" height="400px"
                                         @emitCoor="onMapCoordinatesChange" :zoomControls="false" :field="field" />
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
    import _ from "lodash";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import MapSingleTransect from "@/components/map/MapSingleTransect.vue";

    export default {
        name: "EditGeobjectHistoryDialog",

        mixins: [MapMixin],

        components: {
            MapSingleTransect,
            CustomIconButton,
            CustomSelectOne,
            CustomCalendar,
            Dialog,
            CustomButton,
            CustomInputNumber,
            MapSingleMarker,
        },

        emits: ["update:visible", "update:item", "reloadHistory"],

        props: {
            item: {
                type: Object,
                required: true,
            },
            visible: {
                type: Boolean,
                default: false,
            },
            create: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Array,
                required: true,
            },
            createHistoryFunction: {
                type: Function,
                required: true,
            },
            updateHistoryFunction: {
                type: Function,
                required: true,
            },
            getAvailableControlVersionsFunction: {
                type: Function,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            dopelniacz: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                mapCoordinates: [],
                showErrors: false,
                controlVersions: [],
                editableLineArray: [],
                polandCenter: {latitude: 52.65221, longitude: 19.252482},
                deleteCircle: null,
            };
        },

        beforeMount() {
            console.log(this.name);
            this.loadControlVersions();
            this.updateMap();
        },

        methods: {
            loadControlVersions() {
                this.getAvailableControlVersionsFunction({
                    year: this.year ? this.year : new Date().getFullYear(),
                    id: this.$route.params.id,
                })
                    .then((response) => {
                        this.controlVersions = response.data;
                        if (this.internalItem.controlVersion != null) {
                            this.controlVersions.push(this.internalItem.controlVersion);
                        }
                    });
            },
            onMapCoordinatesChange(coordinates) {
                this.internalItem.coordinates.latitude = coordinates.lat;
                this.internalItem.coordinates.longitude = coordinates.lng;
            },
            updateMap() {
                if (this.name === "transect") {
                    this.updateEditableLineArray(this.internalItem);
                } else if (this.validateCoordinates(this.internalItem.coordinates)) {
                    this.mapCoordinates = [
                        this.internalItem.coordinates.latitude,
                        this.internalItem.coordinates.longitude,
                    ];
                }
            },
            validateCoordinates(coordinates) {
                return ValidateUtils.validateNotEmpty(coordinates, ["longitude", "latitude"]);
            },
            validate() {
                let isCorrect = true;
                if (this.name === "transect") {
                    this.editableLineArray.forEach((l) => { isCorrect = this.validateCoordinates(l) && isCorrect; });
                    console.log(isCorrect);
                    if (this.editableLineArray.length < 2) isCorrect = false;
                    console.log(isCorrect);
                } else {
                    isCorrect = this.validateCoordinates(this.internalItem.coordinates) && isCorrect;
                }
                console.log(isCorrect);
                isCorrect = ValidateUtils.validateNotEmpty(this.internalItem, ["controlVersion", "year"]) && isCorrect;
                console.log(isCorrect);
                return isCorrect;
            },
            save() {
                if (this.validate()) {
                    this.showErrors = false;
                    const historyId = this.internalItem.id;
                    const requestBody = {
                        year: DateUtils.getYearOnly(this.internalItem.year),
                        controlVersion: this.internalItem.controlVersion,
                        accepted: this.internalItem.accepted,
                    };
                    if (this.name === "transect") {
                        requestBody.line = this.internalItem.line;
                    } else {
                        requestBody.coordinates = this.internalItem.coordinates;
                    }
                    requestBody[`${this.name}Id`] = this.$route.params.id;

                    if (this.create) {
                        console.log("create", historyId, requestBody);
                        this.createHistoryFunction({id: this.$route.params.id, body: requestBody})
                            .then(() => {
                                console.log("call reload");
                                this.$emit("reloadHistory");
                                this.$emit("update:visible", false);
                            })
                            .catch((error) => { console.log(error); });
                    } else {
                        console.log("update", historyId, requestBody);
                        this.updateHistoryFunction({id: historyId, body: requestBody})
                            .then(() => {
                                console.log("call reload");
                                this.$emit("reloadHistory");
                                this.$emit("update:visible", false);
                            })
                            .catch((error) => { console.log(error); });
                    }
                } else {
                    this.showErrors = true;
                }
            },
            cancel() {
                this.$emit("reloadHistory");
                this.$emit("update:visible", false);
            },
            updateTransect(value, index, type) {
                if (this.internalItem.line[index] == null) {
                    this.internalItem.line[index] = {longitude: null, latitude: null};
                }
                if (value != null && value !== "" && value !== 0) {
                    if (type === "longitude") {
                        this.internalItem.line[index].longitude = value;
                    }
                    if (type === "latitude") {
                        this.internalItem.line[index].latitude = value;
                    }
                }
            },
            moveBefore(index) {
                if (index > 0) {
                    const segment = this.internalItem.line[index];
                    this.internalItem.line.splice(index, 1);
                    this.internalItem.line.splice(index - 1, 0, segment);
                }
            },
            moveAfter(index) {
                if (index < this.internalItem.line.length - 1) {
                    const segment = this.internalItem.line[index];
                    this.internalItem.line.splice(index, 1);
                    this.internalItem.line.splice(index + 1, 0, segment);
                }
            },
            remove(index) {
                this.deleteCircle = [this.internalItem.line[index], index];
                this.editableLineArray.splice(index, 1);
                this.internalItem.line.splice(index, 1);
            },
            add() {
                let newPoint = null;
                if (this.internalItem.line.length > 0
                    && this.internalItem.line[0].latitude != null
                    && this.internalItem.line[0].longitude != null) {
                    const lastPoint = _.cloneDeep(this.internalItem.line[this.editableLineArray.length - 1]);
                    lastPoint.latitude += 0.001;
                    lastPoint.longitude += 0.001;
                    newPoint = lastPoint;
                } else if (this.field.length > 0) {
                    this.internalItem.line = [];
                    const pointFromField = this.field[0];
                    newPoint = _.cloneDeep(pointFromField);
                } else {
                    this.internalItem.line = [];
                    newPoint = _.cloneDeep(this.polandCenter);
                }
                this.internalItem.line.push(newPoint);
            },
            updateEditableLineArray(item) {
                item.line.forEach((l, i) => {
                    if (this.editableLineArray.length - 1 < i) {
                        this.editableLineArray.push({...l});
                    } else {
                        const editableElem = this.editableLineArray[i];
                        if (l.latitude !== editableElem.latitude) editableElem.latitude = l.latitude;
                        if (l.longitude !== editableElem.longitude) editableElem.longitude = l.longitude;
                    }
                });
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
            internalItem: {
                get() { return this.item; },
                set(value) { this.$emit("update:item", value); },
            },
        },

        watch: {
            internalItem: {
                handler(value) {
                    if (this.name === "transect") this.updateEditableLineArray(value);
                    this.$emit("update:item", value);
                },
                deep: true,
            },
            "internalItem.year"() {
                this.loadControlVersions();
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
@import "../assets/scss/dialogs";
</style>
