<template>
    <div class="flex justify-content-between flex-column">
        <div class="flex column-gap-3 justify-content-between">
            <div class="flex column-gap-3 flex-wrap w-11">
                <CustomCalendar name="creationDate" label="Data utworzenia" v-model="internalValue.creationDate"
                                class="w-2" selectionMode="range" />
                <CustomInputText name="ip" label="Ip" v-model="internalValue.ip" class="w-2" />
                <CustomSelectOne :items="statuses" itemValue="code" name="status" label="Status"
                                 v-model="internalValue.status" class="w-2" />
                <CustomInputText name="login" label="Login" v-model="internalValue.login" class="w-2" />
            </div>
            <CustomButton label="Wyczyść" @click="clear" class="field" />
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "LogLogsSearchCriteria",

        components: {
            CustomButton,
            CustomInputText,
            CustomCalendar,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
        },

        data() {
            return {
                statuses: [
                    {code: "SUCCESS", label: "Sukces"},
                    {code: "FAIL", label: "Porażka"},
                    {code: "BLOCKED", label: "Zablokowano"},
                    {code: "LOGOUT", label: "Wylogowano"},
                    {code: "REFRESH", label: "Odświeżanie"},
                ],
            };
        },

        emits: ["update:modelValue"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.creationDate = null;
                this.internalValue.ip = "";
                this.internalValue.status = null;
                this.internalValue.login = "";
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
